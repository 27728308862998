import { voidFunction } from '@avantia/client-and-server-utilities';
import { CSSProperties } from 'react';
import { getConfig } from '../../configuration';
import { ExternalLink } from './ExternalLink';
import './Footer.css';
import FooterDigiCert, { FooterDigiCertProps } from './FooterDigiCert';

const { customerPortalVersion } = getConfig();

export interface FooterProps {
  style?: CSSProperties;
  digiCert?: FooterDigiCertProps;
}

export function Footer({ style, digiCert }: FooterProps): JSX.Element {
  const spacer = <span className="spacer">|</span>;
  return (
    <div className="copyright-card" style={style || { marginTop: '-10px' }}>
      <FooterDigiCert {...(digiCert || {})} />
      <p>
        Homeprotect is a registered trademark and trading style of Avantia Insurance Ltd which is authorised and
        regulated by the Financial Conduct Authority (FS Register Number 304432). Home Insurance is underwritten by AXA
        Insurance and other reputable insurers.
      </p>
      <p className="copyright-section">
        <span>Copyright &copy; {new Date().getFullYear()} Avantia Insurance Ltd</span>
        {spacer}
        <span>
          <ExternalLink id="avantia-link" href="https://www.avantiagroup.co.uk/" content="www.avantiagroup.co.uk" />
        </span>
        {spacer}
        <span>{`v${customerPortalVersion}`}</span>
      </p>
      <div className="footer-links col-xs-12 text-center">
        <div className="row">
          <ExternalLink id="homeprotect-link" href="https://www.homeprotect.co.uk/" content="Homeprotect" />
          {spacer}
          <ExternalLink id="legal-link" href="https://www.homeprotect.co.uk/legal-terms-of-use" content="Legal" />
          {spacer}
          <ExternalLink
            id="privacy-link"
            href="https://www.homeprotect.co.uk/security-privacy"
            content="Privacy policy"
          />
          {spacer}
          <ExternalLink id="cookies-link" href="https://www.homeprotect.co.uk/cookie-policy" content="Cookies" />
          {spacer}
          <a
            href="/"
            id="change-cookie-prefs-link"
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
              const handler = (window as any).__tcfapi;
              if (handler) {
                handler('displayConsentUi', 2, voidFunction);
              }
            }}
            tabIndex={0}>
            Manage Privacy Settings
          </a>
        </div>
      </div>
    </div>
  );
}
