export function BootstrapScreenSize({ environment }: { environment: string }): JSX.Element | null {
  if (['dev', 'developer', 'docker-dev'].indexOf(environment) < 0) {
    return null;
  }

  const sizes = ['xs', 'sm', 'md', 'lg', 'xl'];
  const szMap = sizes.map((sz) => {
    const cls = sizes.map((s) => (sz === s ? `d-${s}-block` : `d-${s}-none`)).join(' ');
    return (
      <div
        key={sz}
        className={`${sz !== 'xs' ? 'd-none' : ''} ${cls}`}
        style={{ fontFamily: "'Courier New', monospace" }}>
        {sz}
      </div>
    );
  });
  return (
    <div
      id="boostrap-screen-size"
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        color: 'white',
        backgroundColor: 'green',
        fontSize: '20px',
        paddingLeft: '3px',
        paddingRight: '3px',
        zIndex: 999,
        fontWeight: 'bold'
      }}>
      {szMap}
    </div>
  );
}
