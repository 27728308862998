import { EmailDocumentsProps } from '../actions/documents/emailDocumentsAction';
import { ErrorModel, modelValidator, ProcessRulesOptions, ValidationRules } from './modelValidator';
import * as Rules from './validatorRules';

export function documentsLoginValidator(model: EmailDocumentsProps, options?: ProcessRulesOptions): ErrorModel {
  options = options || {};
  const warnings: ValidationRules = {};
  const errors: ValidationRules = {
    emailAddress: [
      (v) => Rules.RequiredRule(v, undefined, 'The email address is required'),
      Rules.createEmailAddressRule('The email address currently entered is not valid')
    ],
    postcode: [
      (v) => Rules.RequiredRule(v, undefined, 'The postcode of the insured property is required'),
      Rules.createPostcodeRule('The postcode of the insured property is not valid')
    ]
  };

  return modelValidator(model, { errors, warnings }, options);
}
