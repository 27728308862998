import { StandardDispatchFunction } from './actionTypes';
import { FetchOptions, fetchResolver } from './fetchResolver';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function bankInfoUpdateAction({
  postcode,
  policyNumber,
  accountHolderName,
  accountNumber,
  sortCode
}: BankInfoUpdateActionProps) {
  const body = {
    postcode,
    policyNumber,
    accountHolderName,
    accountNumber,
    sortCode
  };

  return (dispatch: StandardDispatchFunction): Promise<void> => {
    const url = '/api/bankInfo';
    const options: FetchOptions = {
      url,
      method: 'POST',
      data: body
    };
    return fetchResolver<any>(options, dispatch, (payload) => {
      dispatch({ type: 'BANK_INFO_UPDATED', payload });
    });
  };
}

interface BankInfoUpdateActionProps {
  postcode: string;
  policyNumber: string;
  accountHolderName: string;
  accountNumber: string;
  sortCode: string;
}
