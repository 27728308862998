import { getConfig } from '../configuration';
import { CurrencyFieldModel } from '../models/CurrencyFieldModel';
import { SortCodeFieldModel } from '../models/SortCodeFieldModel';
import { modelValidator } from '../validators/modelValidator';
import {
  BankDetailLoginFormState,
  BankDetailsState,
  BankInfoFormState,
  DocumentsState,
  EsigDetailLoginFormState,
  EsigDetailsState,
  GlobalState,
  PaymentFormState,
  PaymentState,
  UserInterfaceState
} from './stateTypes';

const { developerModeEnabled } = getConfig();

export function getInitialState(): Readonly<GlobalState> {
  const state: GlobalState = {
    userInterface: createEmptyUserInterfaceState(),
    documents: createEmptyDocumentsState(),
    bankDetailLoginForm: createEmptyBankDetailLoginFormState(),
    bankDetails: createEmptyBankDetailsState(),
    bankInfoForm: createEmptyBankInfoFormState(),
    esigDetailLoginForm: createEmptyEsigDetailLoginFormState(),
    esigDetails: createEmptyEsigDetailsState(),
    paymentForm: createEmptyPaymentFormState(),
    payment: createEmptyPaymentState()
  };
  return Object.freeze(state);
}

function createEmptyPaymentState(): PaymentState {
  const state: PaymentState = {
    overdueAmount: '', // this is the full amount outstanding
    feeAmount: 0,
    totalPaid: 0,
    outstandingBalance: 0,
    totalAmountPayable: 0,
    amount: 0,
    agreementReference: '',
    postcode: '',
    apiKey: '',
    paymentId: '',
    policyNumber: '',
    validationErrors: undefined,
    address: {},
    cardholderName: '',
    paymentToken: '',
    paymentAmount: '', // this is the amount associated with the token.
    paymentResultToken: '',
    paymentStatus: '',
    paymentMessage: '',
    paymentSuccess: false,
    isStripeControlConfigured: false
  };
  return Object.freeze(state);
}

function createEmptyPaymentFormState(): PaymentFormState {
  const state: PaymentFormState = {
    policyNumber: developerModeEnabled ? 'A01/0002799' : '',
    postcode: developerModeEnabled ? 'SW17 7HX' : '',
    cardholderName: '',
    amount: new CurrencyFieldModel({ name: 'amount', value: '' }) // this is the amount in the UI, which may be different from the paymentAmount (below).
  };
  return Object.freeze(state);
}

function createEmptyEsigDetailsState(): EsigDetailsState {
  const state: EsigDetailsState = {
    surname: developerModeEnabled ? 'Brown' : '',
    dobDay: developerModeEnabled ? '2' : '',
    dobMonth: developerModeEnabled ? '2' : '',
    dobYear: developerModeEnabled ? '1990' : '',
    dateOfBirth: developerModeEnabled ? '11/11/1990' : '',
    postcode: developerModeEnabled ? 'SW17 7HX' : '',
    agreementReferenceId: developerModeEnabled ? '' : '',
    esigSigned: developerModeEnabled ? false : false,
    esigAgreed: developerModeEnabled ? false : false,
    premfinaEsigID: developerModeEnabled ? '' : ''
  };
  return Object.freeze(state);
}

function createEmptyEsigDetailLoginFormState(): EsigDetailLoginFormState {
  const state: EsigDetailLoginFormState = {
    surname: developerModeEnabled ? 'Brown' : '',
    dobDay: developerModeEnabled ? '2' : '',
    dobMonth: developerModeEnabled ? '2' : '',
    dobYear: developerModeEnabled ? '1990' : '',
    dateOfBirth: developerModeEnabled ? '2/2/1990' : '',
    postcode: developerModeEnabled ? 'SW17 7HX' : '',
    agreementReferenceId: developerModeEnabled ? '' : '',
    esigSigned: developerModeEnabled ? false : false,
    esigAgreed: developerModeEnabled ? false : false,
    premfinaEsigID: developerModeEnabled ? '175' : ''
  };
  return Object.freeze(state);
}

function createEmptyDocumentsState(): DocumentsState {
  const state: DocumentsState = {
    emailAddress: '',
    postcode: '',
    documentDetails: undefined,
    documentSummary: undefined,
    savedQuotes: undefined,
    hasDataLoaded: false,
    actionMessage: '',
    actionMessageType: undefined,
    homelink: '',
    getSumaryUsingAuthLinkStatus: undefined,
    authEmailLinkStatus: undefined,
    linkActiveDuration: '',
    linkMaximumClicksText: '',
    outOfAuthAttempts: false,
    disableActionButtons: false,
    historyVisible: {},
    policyHasIncidentMap: {},
    activeTab: 'documents',
    showTabs: [],
    showNotificationsBadge: true,
    source: undefined
  };
  return Object.freeze(state);
}

function createEmptyBankInfoFormState(isDeveloper?: boolean): BankInfoFormState {
  const state: BankInfoFormState = {
    accountHolder: isDeveloper ? 'Jack Frost' : '',
    accountNumber: isDeveloper ? '49006169' : '',
    sortCode: new SortCodeFieldModel({ name: 'sortCode', value: isDeveloper ? '601335' : '' })
  };
  return Object.freeze(state);
}

function createEmptyBankDetailsState(): BankDetailsState {
  const state: BankDetailsState = {
    historyId: '',
    policyDetailsId: '',
    postcode: '',
    policyNumber: '',
    title: '',
    forename: '',
    surname: '',
    house: '',
    street: '',
    locality: '',
    city: '',
    county: '',
    country: '',
    freeText: '',
    address: {},
    accountHolder: '', // only holds info on update successful
    accountNumber: '', // only holds (masked) info on update successful
    sortCode: '', // only holds (masked) info on update successful
    validationErrors: undefined
  };
  return Object.freeze(state);
}

function createEmptyBankDetailLoginFormState(): BankDetailLoginFormState {
  const state: BankDetailLoginFormState = {
    policyNumber: developerModeEnabled ? 'A01/0002799' : '',
    postcode: developerModeEnabled ? 'SW17 7HX' : ''
  };
  return Object.freeze(state);
}

function createEmptyUserInterfaceState(oldState?: UserInterfaceState): UserInterfaceState {
  const state: UserInterfaceState = {
    fetchError: null,
    modelErrors: modelValidator({}, {}),
    ajaxCallsInProgress: 0,
    processingRequest: false,
    retrievePaymentTokenCount: 0,
    fieldWithFocus: '',
    user: (oldState ? oldState.user : null) || { name: '' }
  };
  return Object.freeze(state);
}
