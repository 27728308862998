import { FeatureTypes } from '../../components/documents/documentsPageCommon';
import { DispatchFunction } from '../actionTypes';
import { FormTypePayload } from './FormTypePayload';

export interface SelectFeatureTabPayload {
  tab: FeatureTypes;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function selectFeatureTabAction(payload: SelectFeatureTabPayload & FormTypePayload) {
  const { tab, formType } = payload;
  return (dispatch: DispatchFunction<SelectFeatureTabPayload>): void => {
    dispatch({ type: 'TAB_SELECTED', formType, payload: { tab } });
  };
}
