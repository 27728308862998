import { lodash } from '@avantia/lodash';
import { StandardDispatchFunction } from '../actionTypes';
import { FetchOptions, fetchResolver } from '../fetchResolver';

export interface PaymentCompletedActionParams {
  stripeResponse: any;
  success: boolean;
  amount: any;
  postcode: string;
  agreementReference: string;
  policyNumber: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function paymentCompletionAction(payload: PaymentCompletedActionParams) {
  const { stripeResponse, success, amount, postcode, agreementReference, policyNumber } = payload;
  let paymentToken: string;
  if (success === true) {
    paymentToken = stripeResponse.id;
  } else {
    if (lodash.isObject(stripeResponse.payment_intent)) {
      paymentToken = stripeResponse.payment_intent.id;
    } else {
      return (dispatch: StandardDispatchFunction): void => {
        dispatch({ type: 'FETCH_ERROR_OCCURRED', fetchError: stripeResponse, isStripe: true });
      };
    }
  }

  const body = Object.assign({}, stripeResponse);
  body.avantiaExtra = {
    paymentAmount: amount.value,
    postcode,
    policyNumber,
    agreementReference
  };

  return (dispatch): Promise<void> => {
    const url = `/api/completePayment/${paymentToken}/${success ? 'success' : 'failure'}`;
    const options: FetchOptions = {
      url,
      method: 'POST',
      data: body,
      headers: { 'Content-Type': 'application/json' },
      dispatchCallStarted: false
    };
    return fetchResolver(options, dispatch, (json) => {
      const payload = { stripeResponse, success, avantiaResponse: json };
      dispatch({ type: 'COMPLETE_PAYMENT', payload });
    });
  };
}
