import { DocumentContentRowProps, labels } from '../documentsPageCommon';

export function DocumentContentRow({
  labelKey,
  content,
  contentClass,
  labelClass,
  isMaskingEnabled
}: DocumentContentRowProps): JSX.Element {
  return (
    <div key={labelKey} className={`col-12 label-value pull-left `}>
      <label className={`col-md-5 col-lg-3 ${labelClass}`}>{labels[labelKey]}</label>
      <span className={`col-md-7 col-lg-9 ${contentClass || ''}`} data-dd-privacy={isMaskingEnabled ? 'mask' : 'allow'}>
        {content}
      </span>
    </div>
  );
}
