export function HeaderNavigation({ exitVisible }: { exitVisible: boolean }): JSX.Element {
  if (!exitVisible) {
    return <></>;
  }

  return (
    <div className="context-menu hidden-xs hidden-sm col-md-2">
      <div className="btn-group btn-group-justified" role="group">
        {exitVisible && (
          <div className="btn-group" role="group">
            <button className="btn-exit">
              <a href="/">
                <i></i>
                {/* <span label="Exit"></span> */}
                <span>Exit</span>
              </a>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
