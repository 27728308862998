import { AnalyticsPageProps, SendAnalyticsProps } from '../../components/documents/analytics';
import { redirectToUrl } from '../../components/documents/documentsPageCommon';
import { DispatchFunction } from '../actionTypes';
import { fetchResolver2 } from '../fetchResolver';
import { FormTypePayload } from './FormTypePayload';

export interface RedirectToClaimsPortalPayload extends FormTypePayload {
  emailAddress: string;
  postcode: string;
  claimsUrl: string;
  analyticsProps: AnalyticsPageProps;
}

export interface RedirectToClaimsPortalResponse extends RedirectToClaimsPortalServerResponse {
  claimsUrl: string;
}

interface RedirectToClaimsPortalServerResponse {
  key: string;
}

export function redirectToClaimsPortalAction(payload: RedirectToClaimsPortalPayload) {
  return (dispatch: DispatchFunction<RedirectToClaimsPortalResponse>): void => {
    const { emailAddress, postcode, claimsUrl, analyticsProps: props } = payload;
    const data = {
      emailAddress,
      postcode
    };

    const url = `/api/policy/redirectToClaimsPortal`;
    const analyticsProps: SendAnalyticsProps = {
      props,
      event: 'Start a Claim Click',
      detail: { source: 'Claims button' },
      checkRepeats: false
    };

    if (claimsUrl.indexOf('?') > -1) {
      fetchResolver2<RedirectToClaimsPortalServerResponse, RedirectToClaimsPortalResponse>(
        { url, data, method: 'POST' },
        dispatch,
        (response) => {
          const { key } = response;
          const url = `${claimsUrl}${key}`;
          redirectToUrl({ openInNewTab: true, url, analyticsProps });
        }
      );
    } else {
      redirectToUrl({ openInNewTab: true, url: claimsUrl, analyticsProps });
    }
  };
}
