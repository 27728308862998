import { logDebug } from '../../clientLogging';
import { StandardDispatchFunction } from '../actionTypes';
import { FetchOptions, fetchResolver } from '../fetchResolver';

export function paymentSentForProcessingAction({
  policyNumber,
  postcode,
  paymentId
}: PaymentSentForProcessingActionProps) {
  return (dispatch: StandardDispatchFunction): Promise<void> => {
    policyNumber = encodeURIComponent(policyNumber);
    postcode = encodeURIComponent(postcode);
    paymentId = encodeURIComponent(paymentId);
    const url = `/api/paymentSentForProcessing/${policyNumber}/${postcode}/${paymentId}`;
    const options: FetchOptions = {
      url,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: { userAgent: navigator ? navigator.userAgent : null },
      dispatchCallCompleted: false
    };
    logDebug(url);
    return fetchResolver(options, dispatch, 'PAYMENT_SENT_FOR_PROCESSING');
  };
}

interface PaymentSentForProcessingActionProps {
  policyNumber: string;
  postcode: string;
  paymentId: string;
}
