import React from 'react';

export interface ToggleButtonControlProps {
  id?: string;
  name: string;
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  active: boolean;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  toggleTextOn?: string;
  toggleTextOff?: string;
}

export function ToggleButtonControl({
  id,
  name,
  label,
  onClick,
  disabled,
  type,
  active,
  toggleTextOn = 'On',
  toggleTextOff = 'Off'
}: ToggleButtonControlProps): JSX.Element {
  const toggleText = active ? toggleTextOn : toggleTextOff;
  return (
    <>
      <div className="av-toggle-button">
        <div>
          <button
            id={name || id}
            name={name}
            onClick={onClick}
            disabled={disabled}
            type={type || 'button'}
            className={`btn${active ? ' active' : ''}`}>
            <span>
              <p style={{ fontWeight: 'bold' }}>{toggleText}</p>
            </span>
            <div className="av-toggle-button-handle"></div>
          </button>
          <label htmlFor="turn-on" className="toggle-label">
            {label}
          </label>
        </div>
      </div>
    </>
  );
}
