import { SavedQuoteItem } from '../../actions/documents/getDocumentSummaryUsingAuthLinkAction';
import * as fmt from '../../formatters';
import { AnalyticsPageProps } from './analytics';
import {
  DocumentCardProps,
  DocumentContentRowProps,
  FeatureTypes,
  fmtDateOpts,
  renderOpenSavedQuoteLink,
  SetActionMessageFunc,
  SourceTypes,
  ViewTypes
} from './documentsPageCommon';

export interface SavedQuoteCardProps {
  index: number;
  savedQuote: SavedQuoteItem;
  disableActionButtons: boolean;
  setActionMessage: SetActionMessageFunc;
  DocumentCard: (props: DocumentCardProps) => JSX.Element;
  DocumentContentRow: (props: DocumentContentRowProps) => JSX.Element;
  viewType: ViewTypes;
  featureType: FeatureTypes;
  analyticsProps: AnalyticsPageProps;
  source: SourceTypes;
}

export function SavedQuoteCard({
  savedQuote,
  disableActionButtons,
  index,
  setActionMessage,
  DocumentCard,
  DocumentContentRow,
  viewType,
  featureType,
  analyticsProps,
  source
}: SavedQuoteCardProps): JSX.Element {
  const { quoteReference, riskPostCode, coverType, dateSaved, quotePrice, quoteStatus } = savedQuote;
  const historyKey = 'policy-quote-' + quoteReference;
  const quotePriceFormatted = fmt.currency(quotePrice) as string;
  const quotePriceDisplay = quotePriceFormatted.split('.');
  let buttonIndex = 0;
  const cardElement = DocumentCard({
    id: historyKey,
    index,
    viewType,
    featureType,
    mainContent: [
      DocumentContentRow({
        labelKey: 'quotePrice',
        content: (
          <span>
            &pound;{quotePriceDisplay[0]}.<sup>{quotePriceDisplay[1]}</sup>
          </span>
        ),
        contentClass: 'quote-price',
        labelClass: 'quote-price-label'
      }),
      DocumentContentRow({
        labelKey: 'quoteReference',
        content: quoteReference
      }),
      DocumentContentRow({
        labelKey: 'quoteStatus',
        content: quoteStatus || 'Quote'
      }),
      DocumentContentRow({
        labelKey: 'dateSaved',
        content: fmt.date(dateSaved, fmtDateOpts)
      }),
      DocumentContentRow({
        labelKey: 'postcode',
        content: riskPostCode,
        isMaskingEnabled: true
      }),
      DocumentContentRow({
        labelKey: 'coverType',
        content: coverType
      })
    ],
    messageContent: null,
    detailsContent: null,
    buttonContent: [
      renderOpenSavedQuoteLink({
        props: analyticsProps,
        savedQuote,
        setActionMessage,
        disableActionButtons,
        index: buttonIndex++,
        source
      })
    ]
  });
  return cardElement;
}
