import { Collapse } from 'react-bootstrap';
import { FontIcon } from '../common/fontIcon';
import { DocumentDetailSectionProps } from './documentsPageCommon';

export function DocumentDetailSection({
  id,
  detailText,
  details,
  isDetailVisible,
  toggleEvent
}: DocumentDetailSectionProps): JSX.Element[] | null {
  if (!details || !details.length) {
    return null;
  }

  return [
    <Collapse key={`${id}-detail`} in={isDetailVisible}>
      <div className="row">
        <div className="col-12">
          <div className="container history" id={id}>
            {details}
          </div>
        </div>
      </div>
    </Collapse>,
    <div key={`${id}-show-hide`} className="row av-expandable">
      <div className="col-12" onClick={(e) => toggleEvent(e, !isDetailVisible)}>
        {isDetailVisible ? 'Hide' : 'Show'} {detailText}
        <FontIcon faIcon={isDetailVisible ? 'minus' : 'plus'} className="pull-right" style={{ marginTop: '4px' }} />
      </div>
    </div>
  ];
}
