import { CSSProperties } from 'react';
import { FontAwesomeIconTypes, IconTypes } from '../iconTypes';

export interface FontIconProps {
  icon?: IconTypes;
  faIcon?: FontAwesomeIconTypes;
  width?: number | undefined;
  style?: CSSProperties;
  className?: string;
}

export function FontIcon({ icon, faIcon, width, style, className }: FontIconProps): JSX.Element {
  const widthClass = width !== undefined && width !== 0 ? `${width}`.replace('.', '_') : 2;
  if (icon) {
    return (
      <i
        className={`aj aj-${icon} aj-fw aj-${widthClass}x ${className || ''}`}
        style={style || {}}
        aria-hidden="true"></i>
    );
  }

  return (
    <i
      className={`fa fa-${faIcon || 'download'} ${width ? `aj-${widthClass}x` : ''} ${className || ''}`}
      style={style || {}}
      aria-hidden="true"></i>
  );
}
