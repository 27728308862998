import { StandardAction } from '../actions/actionTypes';
import { getInitialState } from './initialState';
import { applyChanges, postcodeFieldValueFormatter, trimFieldValue } from './reducerLibrary';
import { EsigDetailLoginFormState } from './stateTypes';

const initialState = getInitialState().esigDetailLoginForm;

export function esigDetailLoginFormReducer(
  state: EsigDetailLoginFormState = initialState,
  action: StandardAction
): EsigDetailLoginFormState {
  switch (action.type) {
    case 'FIELD_CHANGED':
      return (() => {
        // eslint-disable-next-line prefer-const
        let { fieldName, value, eventType } = action.payload;
        const updated: Partial<EsigDetailLoginFormState> = {};
        if (fieldName === 'postcode') {
          value = postcodeFieldValueFormatter(value);
        }

        updated[fieldName] = trimFieldValue(value, eventType);
        if (fieldName.startsWith('dob')) {
          updated[fieldName] = value.trim();
          switch (fieldName) {
            case 'dobDay':
              updated['dateOfBirth'] = value.trim() + '/' + state.dobMonth + '/' + state.dobYear;
              break;
            case 'dobMonth':
              updated['dateOfBirth'] = state.dobDay + '/' + value.trim() + '/' + state.dobYear;
              break;
            case 'dobYear':
              updated['dateOfBirth'] = state.dobDay + '/' + state.dobMonth + '/' + value.trim();
              break;
            default:
              break;
          }
        }

        return applyChanges(state, updated);
      })();

    case 'LOGIN_DETAIL_SUCCESS':
      return (() => {
        const { premfinaEsigID } = action.payload;
        if (premfinaEsigID === 0) {
          return applyChanges(initialState, {});
        }

        return state;
      })();

    case 'ESIG_AGREED':
      return (() => {
        const { esigAgreed } = action.payload;
        return applyChanges(state, { esigAgreed });
      })();

    case 'CLEAR_ALL_FORM_DATA':
    case 'LOG_OFF_SUCCESS':
      return applyChanges(state, {
        surname: '',
        dateOfBirth: '',
        dobDay: '',
        dobMonth: '',
        dobYear: '',
        postcode: '',
        agreementReferenceId: '',
        esigSigned: false,
        esigAgreed: false,
        premfinaEsigID: ''
      });

    case 'ESIG_AGREEMENTREFID_SET':
      return (() => {
        const { agreementReferenceId } = action.payload;
        return applyChanges(state, { agreementReferenceId });
      })();

    default:
      break;
  }

  return state;
}
