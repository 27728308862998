import { Tab, Tabs } from 'react-bootstrap';

export function TabControl({
  id,
  activeTab,
  tabs,
  setActiveTab,
  showNotificationsBadge
}: TabControlParams): JSX.Element {
  const tabElements: JSX.Element[] = [];
  const notificationsBadgeElement = showNotificationsBadge ? (
    <>
      Notifications
      <div className="notifications-badge-wrapper">
        <span className="notifications-badge">1</span>
      </div>
    </>
  ) : (
    'Notifications'
  );
  for (const key in tabs) {
    const { element, name } = tabs[key];
    const tabElement = (
      <Tab key={key} eventKey={key} title={name === 'Notifications' ? notificationsBadgeElement : name}>
        {element}
      </Tab>
    );
    tabElements.push(tabElement);
    if (activeTab === undefined) {
      activeTab = key;
    }
  }

  return (
    <Tabs id={id} activeKey={activeTab} onSelect={(t) => setActiveTab(t as string, id)}>
      {tabElements}
    </Tabs>
  );
}

export type TabControlMap = { [key: string]: TabControlItem };

export type SetActiveTabFunc = (tabSelected: string, id: string) => void;

export interface TabControlParams {
  id: string;
  activeTab?: string;
  tabs: TabControlMap;
  setActiveTab: SetActiveTabFunc;
  showNotificationsBadge: boolean;
}

export interface TabControlItem {
  name: string | JSX.Element;
  element: JSX.Element | JSX.Element[];
}
