import { DispatchFunction, MessageTypes } from '../actionTypes';
import { FormTypePayload } from './FormTypePayload';

export interface SetActionMessagePayload {
  message: string;
  type: MessageTypes;
}

export function setActionMessageAction(payload: SetActionMessagePayload & FormTypePayload) {
  return (dispatch: DispatchFunction<SetActionMessagePayload>): void => {
    const { formType, message, type } = payload;
    dispatch({ type: 'DOCUMENTS_SET_ACTION_MESSAGE', formType, payload: { message, type } });
  };
}
