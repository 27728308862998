import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { fetchAuthenticatedUserAction } from '../actions/fetchAuthenticatedUserAction';
import { logWarning } from '../clientLogging';
import { getConfig } from '../configuration';
import { rootReducer } from '../reducers/index';
import { getInitialState } from '../reducers/initialState';

declare const window: any;

const middleware: Middleware[] = [thunkMiddleware];
const isIe11 = window.navigator.userAgent.indexOf('Trident') < 0;
const { developerModeEnabled } = getConfig();

let composeEnhancers = compose;

if (developerModeEnabled) {
  middleware.push(createLogger());

  if (!isIe11) {
    // add support for Redux dev tools
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || composeEnhancers;

    logWarning("Adding 'redux-immutable-state-invariant' middleware.");
    middleware.push(
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('redux-immutable-state-invariant').default({})
    );
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function configureStore() {
  const store = createStore(rootReducer, getInitialState() as any, composeEnhancers(applyMiddleware(...middleware)));
  store.dispatch(fetchAuthenticatedUserAction() as any);
  return store;
}
