import { lodash } from '@avantia/lodash';
import axios from 'axios';
import { logInfo } from '../clientLogging';
import { applyChanges } from '../reducers/reducerLibrary';
import { ActionTypes, DispatchFunction } from './actionTypes';

export interface FetchOptions {
  url: string;
  method?: 'GET' | 'POST';
  data?: Record<string, unknown>;
  headers?: { [name: string]: string };
  dispatchCallStarted?: boolean;
  dispatchCallCompleted?: boolean;
}

export interface FetchErrorAction {
  type: ActionTypes;
  fetchError?: string | Record<string, unknown>;
  message?: string;
  isStripe?: boolean;
  reason: any;
}

export function fetchResolver<ServerResponseT>(
  options: FetchOptions,
  dispatch: DispatchFunction<ServerResponseT>,
  successAction: ((payload: ServerResponseT) => void) | ActionTypes
): Promise<void> {
  return fetchResolver2<ServerResponseT, ServerResponseT>(options, dispatch, successAction, (m) => m);
}

export function fetchResolver2<ServerResponseT, DispatchDataT>(
  options: FetchOptions,
  dispatch: DispatchFunction<DispatchDataT>,
  successAction: ((payload: ServerResponseT) => void) | ActionTypes,
  map?: (resp: ServerResponseT) => DispatchDataT
): Promise<void> {
  if (options.dispatchCallStarted !== false) {
    dispatch({ type: 'AJAX_CALL_STARTED' });
  }

  if (options.data && (!options.headers || !options.headers['Content-Type'])) {
    options.headers = applyChanges(options.headers || {}, { 'Content-Type': 'application/json' });
  }

  options.method = options.method || 'GET';
  logInfo(JSON.stringify(options, null, 2));
  return axios(options)
    .then((response) => {
      const payload = response.data as ServerResponseT;

      if (options.dispatchCallCompleted !== false) {
        dispatch({ type: 'AJAX_CALL_COMPLETED' });
      }

      if (response.status >= 200 && response.status < 300) {
        if (successAction && lodash.isFunction(successAction)) {
          try {
            successAction(payload);
          } catch (reason) {
            dispatch({
              type: 'SCRIPT_ERROR_OCCURRED',
              fetchError: "We're having problems processing your request.",
              reason
            } as FetchErrorAction as any);
          }
        } else {
          if (!map) {
            throw new Error(`The map function was unexpectedly null.`);
          }

          dispatch({ type: successAction, payload: map(payload) });
        }
      } else {
        dispatch({
          type: 'FETCH_ERROR_OCCURRED',
          fetchError: "We're having problems connecting to our servers at the moment.",
          reason: response
        });
      }
    })
    .catch((reason) => {
      dispatch({
        type: 'FETCH_ERROR_OCCURRED',
        fetchError: "We're having problems connecting to our servers at the moment.",
        reason
      });
    });
}
