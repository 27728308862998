import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import '../../assets/css/app.css';
import '../../assets/css/customerPortal.scss';
import HeaderBrowser from './HeaderBrowser';
import { HeaderLogo } from './HeaderLogo';
import { HeaderNavigation } from './HeaderNavigation';

export interface StateProps {
  userName: string;
  homeLink: string;
  navigation?: JSX.Element | JSX.Element[];
  applyNoStyle?: boolean;
}

export class HeaderComponent extends Component<StateProps, any> {
  render(): JSX.Element {
    const { homeLink, userName, navigation, applyNoStyle } = this.props;
    return (
      <div id="header">
        <HeaderBrowser />
        <HeaderLogo homeLink={homeLink} userName={userName} buttons={navigation} applyNoStyle={applyNoStyle} />
        {!navigation && <HeaderNavigation exitVisible={false} />}
      </div>
    );
  }
}

function mapStateToProps(state): StateProps {
  const { userInterface } = state;
  const { user } = userInterface || {};
  return { homeLink: '/', userName: user ? user.name : undefined };
}

function mapDispatchToProps(): Record<string, never> {
  return {};
}

const Header = withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderComponent) as any);
export { Header };
