import { lodash } from '@avantia/lodash';
import { AddressModel } from '../models/models';

export function addressFormatter(address: AddressModel): string {
  let lines = [`${address.house} ${address.street}`, address.city, address.postcode, address.county, address.country];
  lines = lines.map((l) => lodash.trim(l || ''));
  lines = lodash.filter(lines, (l) => !!l);
  const result = lines.join(', ');
  return result;
}
