import { ErrorModel, ServerValidationModel } from '../../validators/modelValidator';

export interface ValidationErrorProps {
  validationErrors: ErrorModel | ServerValidationModel;
}

export default function ValidationErrorControl({ validationErrors }: ValidationErrorProps): JSX.Element | null {
  if (validationErrors && validationErrors.count > 0) {
    const errors = (validationErrors.errors as any).map((err: any, key: string) => {
      if (err.propertyName) {
        return (
          <div key={key}>
            <strong>{err.propertyName}</strong> {err.error}
          </div>
        );
      } else {
        return <div key={key}>{err.error}</div>;
      }
    });

    return <div className="alert alert-danger">{errors}</div>;
  }

  return null;
}
