import { ReactEventHandler, SyntheticEvent, useState } from 'react';
import { Modal } from 'react-bootstrap';

interface ShowModalControlProps {
  id?: string;
  onConfirmHandler?: ReactEventHandler;
  modalCopyText?: ModalCopyText;
}

interface ModalControlProps extends ShowModalControlProps {
  show: boolean;
}

export type ToggleModalFunction = (show: boolean) => void;
export interface ModalCopyText {
  title?: string;
  body: JSX.Element | string;
  cancelButtonText?: string;
  confirmButtonText?: string;
}

/**
 * Shows the modal and sets its state properties.
 * This assumes there is just one ModalControl instance in the DOM component in the project.
 **/
export let showModalControl: (props: ShowModalControlProps) => void;

export function ModalControl(): JSX.Element | null {
  const [state, setState] = useState<ModalControlProps>({ show: false });
  const setShow = (show: boolean) => setState((state) => ({ ...state, show }));
  const { show, id, onConfirmHandler } = state;

  const handleConfirm = (event: SyntheticEvent): void => {
    if (onConfirmHandler) {
      onConfirmHandler(event);
    }

    setShow(false);
  };

  const handleCancel = (): void => {
    setShow(false);
  };

  showModalControl = ({ onConfirmHandler, id, modalCopyText }: ShowModalControlProps): void => {
    setState({ show: true, onConfirmHandler, id, modalCopyText });
  };

  const cancelButtonId = `modal-cancel-${id}`;
  const { title, body, cancelButtonText, confirmButtonText } = state.modalCopyText || {};
  return (
    <Modal className={`cp-modal-dialog`} show={show} onHide={handleCancel}>
      {title !== undefined ? (
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      ) : (
        ''
      )}

      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <button
          id={cancelButtonId}
          className="btn btn-secondary animated"
          name={`hp-group-modal-close-${id}`}
          onClick={handleCancel}
          type="button">
          {cancelButtonText || 'Cancel'}
        </button>
        <button
          id={id}
          className={`btn btn-primary animated`}
          name={`hp-group-modal-confirm-${id}`}
          onClick={handleConfirm}
          type="button">
          {confirmButtonText || 'Confirm'}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
