/* eslint-disable prefer-const */
import { StandardAction } from '../actions/actionTypes';
import * as formatter from '../formatters/index';
import { getInitialState } from './initialState';
import { applyChanges } from './reducerLibrary';
import { PaymentState } from './stateTypes';

const initialState = getInitialState().payment;

export function paymentReducer(state: PaymentState = initialState, action: StandardAction): PaymentState {
  switch (action.type) {
    case 'RETRIEVED_PAYMENT_TOKEN':
      return (() => {
        let {
          postcode,
          agreementReference,
          amount,
          overdueAmount,
          feeAmount,
          totalPaid,
          outstandingBalance,
          totalAmountPayable,
          apiKey,
          id,
          paymentToken,
          policyNumber,
          address,
          errors,
          validationErrors
        } = action.payload;
        agreementReference = agreementReference || state.agreementReference;
        overdueAmount = !isNaN(overdueAmount) ? formatter.currency(overdueAmount) : overdueAmount;
        const newState: Partial<PaymentState> = {
          postcode,
          agreementReference,
          amount,
          paymentAmount: amount,
          overdueAmount,
          feeAmount,
          totalPaid,
          outstandingBalance,
          totalAmountPayable,
          apiKey,
          paymentId: id,
          paymentToken,
          policyNumber,
          address,
          validationErrors: validationErrors || errors,
          isStripeControlConfigured: false
        };
        return applyChanges(state, newState);
      })();

    case 'COMPLETE_PAYMENT':
      return (() => {
        const { stripeResponse, avantiaResponse, success } = action.payload;
        const { outstandingBalance } = avantiaResponse;
        let paymentResultToken, paymentStatus, paymentMessage;

        if (success === true) {
          paymentResultToken = stripeResponse.id;
          paymentStatus = stripeResponse.status;
        } else {
          paymentResultToken = stripeResponse.payment_intent.id;
          paymentStatus = stripeResponse.type;
          paymentMessage = stripeResponse.message;
        }

        return applyChanges(state, {
          paymentResultToken,
          paymentStatus,
          paymentMessage,
          paymentSuccess: success,
          outstandingBalance
        });
      })();

    case 'LOG_OFF_SUCCESS':
    case 'CLEAR_ALL_FORM_DATA':
      return applyChanges(state, { paymentToken: '', paymentResultToken: '' });

    case 'PAYMENT_RETRY_WITH_DIFFERENT_CARD':
      return applyChanges(state, {
        paymentResultToken: '',
        paymentStatus: '',
        paymentSuccess: false,
        isStripeControlConfigured: false
      });

    case 'STRIPE_CONTROL_IS_CONFIGURED':
      return applyChanges(state, { isStripeControlConfigured: true });

    case 'PAYMENT_MODEL_VALIDATION_ERROR':
    case 'FIELD_CHANGED':
      return applyChanges(state, { validationErrors: undefined });

    default:
      break;
  }

  return state;
}
