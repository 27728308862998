import { isTrue } from '@avantia/client-and-server-utilities';
import { ClientConfiguration } from './models/models';

const config: ClientConfiguration = {
  claimsPortalUrl: 'https://claim.homeprotect.co.uk/?customerDetailsKey=',
  customerPortalVersion: '1.0.577.134827',
  ddApplicationId: '6fe0b8dc-ff1b-458c-b654-4f7e3e7a739a',
  ddClientToken: 'pub83b64c16ce23cb838c4e71c7b35600f9',
  ddRumSessionRecordingEnabled: isTrue('true'),
  ddSampleRate: parseInt('100', 10),
  ddServiceName: 'customerportal',
  ddTrackInteractions: isTrue('true'),
  developerModeEnabled: isTrue('false'),
  environment: 'liv',
  experimentKey: 'cpExperimentEnabled',
  quantcastAccountId: 'p-m-s9SNEBDXx0J',
  quantcastConsentManagerEnabled: isTrue('false'),
  virtualPath: '/',
  assetsUrl: 'https://assets.homeprotect.co.uk/',
  logoPath: 'website/homeprotect_final_logo.svg'
};

export function getConfig(): ClientConfiguration {
  return Object.freeze(config);
}
