import { ReportHandler } from 'web-vitals';
import { logWarning } from './clientLogging';

const reportWebVitals = (onPerfEntry?: ReportHandler): void => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals')
      .then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
        getCLS(onPerfEntry);
        getFID(onPerfEntry);
        getFCP(onPerfEntry);
        getLCP(onPerfEntry);
        getTTFB(onPerfEntry);
      })
      .catch((reason) => {
        logWarning({ message: 'Web vitals error', reason });
      });
  }
};

export default reportWebVitals;
