import { ErrorModel, ModelToValidate, modelValidator, ProcessRulesOptions } from './modelValidator';
import * as Rules from './validatorRules';

export function loginPolicyPostcodeValidator(
  model: ModelToValidate,
  options?: ProcessRulesOptions | undefined
): ErrorModel {
  options = options || {};
  const warnings = {};
  const errors = {
    postcode: [Rules.RequiredRule, Rules.createPostcodeRule()],
    policyNumber: [Rules.RequiredRule, Rules.createPolicyNumberLengthRule()]
  };

  return modelValidator(model, { errors, warnings }, options);
}
