import { StandardDispatchFunction } from './actionTypes';

export function fieldFocussedOrBlurredAction(payload: unknown) {
  return function (dispatch: StandardDispatchFunction): void {
    const eventType = (payload as any).eventType;
    if (eventType === 'blur') {
      dispatch({ type: 'FIELD_BLURRED_ACTION', payload });
    } else if (eventType === 'focus') {
      dispatch({ type: 'FIELD_FOCUSSED_ACTION', payload });
    }
  };
}
