import { StandardAction } from '../actions/actionTypes';
import { getInitialState } from './initialState';
import { applyChanges, postcodeFieldValueFormatter, trimFieldValue } from './reducerLibrary';
import { BankDetailLoginFormState } from './stateTypes';

const initialState = getInitialState().bankDetailLoginForm;

export function bankDetailLoginFormReducer(
  state: BankDetailLoginFormState = initialState,
  action: StandardAction
): BankDetailLoginFormState {
  switch (action.type) {
    case 'FIELD_CHANGED':
      return (() => {
        // eslint-disable-next-line prefer-const
        let { fieldName, value, eventType } = action.payload;
        const updated = {};
        if (fieldName === 'postcode') {
          value = postcodeFieldValueFormatter(value);
        } else if (fieldName === 'policyNumber') {
          value = value.toUpperCase();
        }

        updated[fieldName] = trimFieldValue(value, eventType);
        return applyChanges(state, updated);
      })();

    case 'LOGIN_DETAIL_SUCCESS':
      return (() => {
        const { accountNumber } = action.payload;
        if (accountNumber) {
          return applyChanges(initialState, {});
        }

        return state;
      })();

    case 'CLEAR_ALL_FORM_DATA':
    case 'LOG_OFF_SUCCESS':
      return applyChanges(state, { policyNumber: '', postcode: '' });

    default:
      break;
  }

  return state;
}
