import React from 'react';
import { AnalyticsPageProps } from './analytics';
import { DocumentCard } from './desktop';
import {
  FeatureTypes,
  RedirectToClaimsPortalFunc,
  renderOpenClaimLink,
  SetActionMessageFunc,
  SourceTypes,
  ViewTypes
} from './documentsPageCommon';

export interface ClaimsCardProps {
  viewType: ViewTypes;
  featureType: FeatureTypes;
  disableActionButtons: boolean;
  setActionMessage: SetActionMessageFunc;
  analyticsProps: AnalyticsPageProps;
  source: SourceTypes;
  redirectToClaimsPortal: RedirectToClaimsPortalFunc;
}

export function ClaimsCard({
  viewType,
  featureType,
  disableActionButtons,
  setActionMessage,
  redirectToClaimsPortal,
  source
}: ClaimsCardProps): React.ReactElement {
  const makeAClaimButton = renderOpenClaimLink({
    disableActionButtons,
    redirectToClaimsPortal,
    setActionMessage,
    index: 0,
    source
  });
  const cardElement = DocumentCard({
    id: 'claims-card',
    index: 0,
    viewType,
    featureType,
    mainContent: [],
    detailsContent: [
      <>
        <label className="claim-sub-title">Before you claim</label>
        <p>
          Before you submit a claim, it&apos;s important to understand the level of cover you have and how your policy
          might be affected. You can find out more about your policy coverage by continuing below.
        </p>
        {makeAClaimButton}
      </>
    ],
    buttonContent: []
  });

  return cardElement;
}
