import { Footer } from '../common/Footer';

export function DocumentFooter(): JSX.Element {
  return (
    <Footer
      key="footer"
      style={{}}
      digiCert={{
        rowClassName: 'ml-0',
        colClassName: 'col-1',
        style: {},
        clickStyle: {}
      }}
    />
  );
}
