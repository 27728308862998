const PanelControl = ({ content }: PanelControlProps): JSX.Element => {
  return (
    <div className="panel panel-aj active-card">
      <div className="panel-heading" role="tab" id="Activeheading">
        {content}
      </div>
    </div>
  );
};

interface PanelControlProps {
  content: JSX.Element;
}

export { PanelControl };
