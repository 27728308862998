import queryString from 'query-string';
import { documentsLoginValidator } from '../../validators/documentsLoginValidator';
import { createErrorModel, ErrorModel, ServerValidationModel } from '../../validators/modelValidator';
import { DispatchFunction, FormTypes } from '../actionTypes';
import { fetchResolver2 } from '../fetchResolver';
import { ModelValidationErrorPayload } from '../modifyFieldAction';

export interface EmailDocumentsProps {
  emailAddress: string;
  postcode: string;
  policyNumber?: string;
  documentArchiveId?: number;
}

export interface EmailDocumentsPayload extends EmailDocumentsProps {
  formType: FormTypes;
  modelErrors: ErrorModel;
}

export interface EmailDocumentsServerResponse {
  policyNumber: string;
  errors: ServerValidationModel;
}

export interface EmailDocumentsResponsePayload {
  fromClick: boolean;
  policyNumber: string;
}

export interface FailedToEmailDocumentsResponsePayload extends ModelValidationErrorPayload {
  fromClick: boolean;
  policyNumber?: string;
}

export type EmailDispatchTypes = EmailDocumentsResponsePayload | FailedToEmailDocumentsResponsePayload;

export function emailDocumentsAction(payload: EmailDocumentsPayload) {
  return (dispatch: DispatchFunction<EmailDispatchTypes>): void => {
    const { emailAddress, postcode, policyNumber, documentArchiveId, formType } = payload;
    const query: EmailDocumentsProps = {
      emailAddress,
      postcode
    };

    if (policyNumber) {
      query.policyNumber = policyNumber;
      query.documentArchiveId = documentArchiveId;
    }

    dispatch({ type: 'DOCUMENTS_DISABLE_ACTION_BUTTONS', formType });

    const fromClick = !!query.policyNumber;
    const errors = documentsLoginValidator(query);
    if (errors.count > 0) {
      dispatch({
        type: 'DOCUMENTS_LOGIN_MODEL_VALIDATION_ERROR',
        payload: { errors, mergeErrors: true, fromClick }
      });
    } else {
      dispatch({ type: 'DOCUMENTS_LOGIN_MODEL_VALIDATION_OKAY', payload: { errors, mergeErrors: false, fromClick } }); // clear the errors  => (not used?)
    }

    if (emailAddress === '' && postcode === '') {
      dispatch({ type: 'CLEAR_ALL_FORM_DATA', formType });
      return;
    }

    setTimeout(() => sendEmail(query, dispatch, fromClick, formType), 1);
  };
}

function sendEmail(
  query: EmailDocumentsProps,
  dispatch: DispatchFunction<EmailDispatchTypes>,
  fromClick: boolean,
  formType: FormTypes
) {
  const queryText = queryString.stringify(query);
  const url = `/api/documents/email?${queryText}`;
  return fetchResolver2<EmailDocumentsServerResponse, EmailDispatchTypes>({ url }, dispatch, (response) => {
    const { errors } = response;
    if ((!errors || errors.isValid) && response.policyNumber) {
      const payloadData: EmailDocumentsResponsePayload = {
        policyNumber: response.policyNumber,
        fromClick
      };
      dispatch({ type: 'REQUEST_EMAIL_DOCUMENTS_SUCCESS', formType, payload: payloadData });
    } else {
      const payloadData: FailedToEmailDocumentsResponsePayload = {
        policyNumber: response.policyNumber,
        fromClick,
        errors: createErrorModel(response.errors),
        mergeErrors: false
      };
      dispatch({ type: 'REQUEST_EMAIL_DOCUMENTS_FAILED', formType, payload: payloadData });
    }
  });
}
