import * as Validators from '../validators/index';
import { ErrorModel } from '../validators/modelValidator';
import { StandardDispatchFunction } from './actionTypes';
import { ModelValidationErrorPayload } from './modifyFieldAction';

export function modifyEsigFieldAction(payload: ModifyEsigFieldActionProps) {
  return (dispatch: StandardDispatchFunction): void => {
    const { fieldName, value, eventType, validatorName, modelErrors } = payload;
    const validator = Validators[validatorName];
    if (
      eventType === 'change' &&
      fieldName.startsWith('dob') &&
      payload['dobDay'] !== '' &&
      payload['dobMonth'] !== '' &&
      payload['dobYear'] !== ''
    ) {
      let mod = {};
      switch (fieldName) {
        case 'dobDay':
          mod = { dateOfBirth: value.trim() + '/' + payload['dobMonth'] + '/' + payload['dobYear'] };
          break;
        case 'dobMonth':
          mod = { dateOfBirth: payload['dobDay'] + '/' + value.trim() + '/' + payload['dobYear'] };
          break;
        case 'dobYear':
          mod = { dateOfBirth: payload['dobDay'] + '/' + payload['dobMonth'] + '/' + value.trim() };
          break;
        default:
          break;
      }

      const opt = { fieldsToValidate: ['dateOfBirth'] };
      let errors = validator(mod, opt);
      dispatch({
        type: 'ESIG_MODEL_VALIDATION_ERROR',
        payload: { errors, mergeErrors: true } as ModelValidationErrorPayload
      });
      const model = {};
      model[fieldName] = value;
      const options = { fieldsToValidate: [fieldName] };
      errors = validator(model, options);
      dispatch({
        type: 'ESIG_MODEL_VALIDATION_ERROR',
        payload: { errors, mergeErrors: true } as ModelValidationErrorPayload
      });
    } else if (eventType === 'blur' || modelErrors.getError(fieldName) || modelErrors.getWarning(fieldName)) {
      const model = {};
      model[fieldName] = value;
      const options = { fieldsToValidate: [fieldName] };

      if (!validator) {
        throw new Error(`The validator "${validatorName}" is not defined. You need to create one.`);
      }

      const errors = validator(model, options);
      dispatch({
        type: 'ESIG_MODEL_VALIDATION_ERROR',
        payload: { errors, mergeErrors: true } as ModelValidationErrorPayload
      });

      if (fieldName.startsWith('dob')) {
        let mod = {};
        switch (fieldName) {
          case 'dobDay':
            mod = { dateOfBirth: value.trim() + '/' + payload['dobMonth'] + '/' + payload['dobYear'] };
            break;
          case 'dobMonth':
            mod = { dateOfBirth: payload['dobDay'] + '/' + value.trim() + '/' + payload['dobYear'] };
            break;
          case 'dobYear':
            mod = { dateOfBirth: payload['dobDay'] + '/' + payload['dobMonth'] + '/' + value.trim() };
            break;
          default:
            break;
        }

        const opt = { fieldsToValidate: ['dateOfBirth'] };
        const errors = validator(mod, opt);
        dispatch({
          type: 'ESIG_MODEL_VALIDATION_ERROR',
          payload: { errors, mergeErrors: true } as ModelValidationErrorPayload
        });
      }
    }

    dispatch({ type: 'FIELD_CHANGED', payload });
  };
}

interface ModifyEsigFieldActionProps {
  fieldName: string;
  value: any;
  eventType: string;
  validatorName: string;
  modelErrors: ErrorModel;
}
