import * as format from '../formatters/index';
import { ErrorModel, ModelToValidate, modelValidator, ProcessRulesOptions } from './modelValidator';
import * as Rules from './validatorRules';

export function loginAmountPolicyPostcodeValidator(
  model: ModelToValidate,
  options: ProcessRulesOptions & AddAmountValidationProps
): ErrorModel {
  const warnings = {};
  const errors = {
    postcode: [Rules.RequiredRule, Rules.createPostcodeRule()],
    policyNumber: [Rules.RequiredRule, Rules.createPolicyNumberLengthRule()]
  };

  addAmountValidation(options, warnings, errors);

  return modelValidator(model, { errors, warnings }, options);
}

export function addAmountValidation(
  options: AddAmountValidationProps,
  warnings: ValidationModel,
  errors: ValidationModel
): void {
  options = options || {};
  const { overdueAmount, totalPaid, outstandingBalance } = options;
  let minimumAmount: string | number | undefined;

  if (overdueAmount !== undefined && totalPaid !== undefined && outstandingBalance !== undefined) {
    minimumAmount = Math.min(overdueAmount - totalPaid, outstandingBalance);
  }

  minimumAmount = format.currency(minimumAmount);
  warnings.amount = [
    Rules.createRangeRule(
      undefined,
      options.outstandingBalance,
      'You have entered an amount that is more than the oustanding balance.'
    )
  ];

  errors.amount = [Rules.RequiredRule, Rules.NumericRule, Rules.createRangeRule(minimumAmount, '100,000.00')];
}

export interface AddAmountValidationProps {
  overdueAmount?: number;
  totalPaid?: number;
  outstandingBalance: number;
  validateAmount?: number;
  feeAmount?: number;
  totalAmountPayable?: number;
}

export type ValidationModel = Record<string, Rules.ValidationRule[]>;
