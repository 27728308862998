import * as Validators from '../validators/index';
import { ErrorModel, ValidatorFunction } from '../validators/modelValidator';
import { ActionTypes, FormTypes, StandardDispatchFunction } from './actionTypes';

export interface ModifyFieldPayload {
  fieldName: string;
  formType: FormTypes;
  value: string;
  eventType: string;
  validatorName: string;
  modelErrors: ErrorModel;
}

export interface ModelValidationErrorPayload {
  errors: ErrorModel;
  mergeErrors: boolean;
}

export function modifyFieldAction(payload: ModifyFieldPayload) {
  return (dispatch: StandardDispatchFunction): void => {
    const { fieldName, value, eventType, validatorName, modelErrors, formType } = payload;
    if (eventType === 'blur' || modelErrors.getError(fieldName) || modelErrors.getWarning(fieldName)) {
      const validatorKey = `${validatorName}Validator`;
      const validator: ValidatorFunction = Validators[validatorKey];
      if (!validator) {
        throw new Error(`Unable to locate a Validator called "${validatorKey}".`);
      }

      const model = {};
      model[fieldName] = value;
      const options = { fieldsToValidate: [fieldName] };
      const errors: ErrorModel = validator(model, options);
      const actionPrefix = validatorName.replace(/([A-Z]+)/g, '_$1').toUpperCase();
      let type: ActionTypes = 'UNDEFINED_ACTION_TYPE';
      if (actionPrefix) {
        type = `${actionPrefix}_MODEL_VALIDATION_ERROR` as ActionTypes;
      }

      const payload: ModelValidationErrorPayload = { errors, mergeErrors: true };
      dispatch({ type, formType, payload });
    }

    dispatch({ type: 'FIELD_CHANGED', formType, payload });
  };
}
