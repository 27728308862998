import { BaseSyntheticEvent } from 'react';
import { FetchError } from '../../actions/actionTypes';
import { ErrorModel, getFirstError, ServerValidationModel } from '../../validators/modelValidator';
import { ErrorSection } from '../common/ErrorSection';
import { ButtonControl } from '../controls/ButtonControl';
import { IconFieldRowControl } from '../controls/IconFieldRowControl';
import { PanelControl } from '../controls/PanelControl';
import { SpinnerControl } from '../controls/SpinnerControl';
import { TextInputControl } from '../controls/TextInputControl';
import '../defaulters/CustomerPayDetails.css';
import { DayMonthYearEditor } from './dayMonthYearEditor';

export interface EsigLoginDetailsProps {
  surname: string;
  dobDay: string;
  dobMonth: string;
  dobYear: string;
  dateOfBirth: string;
  postcode: string;
  modifyFieldChanged: (event: BaseSyntheticEvent) => void;
  login: (event: BaseSyntheticEvent) => void;
  processingRequest: boolean;
  validationErrors: ErrorModel | ServerValidationModel;
  fetchError: FetchError;
  modelErrors: ErrorModel;
}

export function EsigLoginDetails({
  surname,
  dobDay,
  dobMonth,
  dobYear,
  dateOfBirth,
  postcode,
  modifyFieldChanged,
  login,
  processingRequest,
  validationErrors,
  fetchError,
  modelErrors
}: EsigLoginDetailsProps): JSX.Element {
  const surnameError = modelErrors.getError('surname', 'Surname') || '';
  const dateOfBirthError = modelErrors.getError('dateOfBirth', 'Date of birth') || '';
  const dobDayError = modelErrors.getError('dobDay', 'Date of birth day') || '';
  const dobMonthError = modelErrors.getError('dobMonth', 'Date of birth month') || '';
  const dobYearError = modelErrors.getError('dobYear', 'Date of birth year') || '';
  const postcodeError = modelErrors.getError('postcode', 'Insured property postcode') || '';
  const agreementReferenceIdError = modelErrors.getError('agreementReferenceId', 'Agreement reference') || '';
  const validationErrorMessage = validationErrors ? getFirstError(validationErrors) : '';
  const fetchErrorMessage = fetchError && fetchError.message ? fetchError.message : '';
  const loadingText = 'Please wait while we log you in';
  const dayMonthYear = ['dobDay', 'dobMonth', 'dobYear', 'dataOfBirth'];
  const editorfields = { dobDay: dobDay, dobMonth: dobMonth, dobYear: dobYear, dateOfBirth: dateOfBirth };
  const buttonDisabled = processingRequest; // || modelErrors.count > 0 || surname.length === 0 || dateOfBirth.length === 0 || postcode.length === 0;
  const bodyElement = (
    <div>
      <PanelControl
        content={
          <div>
            <IconFieldRowControl
              width={5}
              content={
                <div>
                  <div>
                    <TextInputControl
                      name="surname"
                      label="Surname (of the policy holder)"
                      placeholder="e.g. Brown"
                      ariaLabel="Surname (of policy holder)"
                      value={surname}
                      modelErrors={modelErrors}
                      onChange={modifyFieldChanged}
                    />
                  </div>
                  <div>
                    <DayMonthYearEditor
                      name={dateOfBirth}
                      fields={dayMonthYear}
                      editorfields={editorfields}
                      label="Date of birth (of the policy holder)"
                      modelErrors={modelErrors}
                      onChange={modifyFieldChanged}
                    />
                  </div>
                  <div hidden={true}>
                    <TextInputControl
                      name="dateOfBirth"
                      label="Date of birth"
                      placeholder="e.g. 23/12/2010"
                      ariaLabel="Date of birth (of policy holder)"
                      value={dateOfBirth}
                      modelErrors={modelErrors}
                      onChange={modifyFieldChanged}
                    />
                  </div>
                  <div>
                    <TextInputControl
                      className="radiusRound-form-control"
                      name="postcode"
                      label="Postcode (of the insured property) "
                      placeholder="e.g. KT1 1PH"
                      value={postcode}
                      ariaLabel="Postcode (of the insured property)"
                      modelErrors={modelErrors}
                      onChange={modifyFieldChanged}
                    />
                  </div>
                </div>
              }
            />

            <div
              className="row div-block"
              style={{
                marginBottom: '10px',
                paddingLeft: '20px',
                paddingRight: '20px'
              }}>
              <ButtonControl name="Login" label="Log in" onClick={login} disabled={buttonDisabled} buttonClass="" />
            </div>
            <SpinnerControl
              visible={processingRequest}
              positionLeftInt={49}
              positionTopInt={90}
              spinnerText={loadingText}
              typeOfSpinner="Ring"
            />
            <ErrorSection
              processingRequest={processingRequest}
              errors={[
                surnameError,
                dateOfBirthError,
                dobDayError,
                dobMonthError,
                dobYearError,
                postcodeError,
                agreementReferenceIdError,
                fetchErrorMessage,
                validationErrorMessage
              ]}
            />
          </div>
        }
      />
    </div>
  );
  return <div>{bodyElement}</div>;
}
