import { StandardDispatchFunction } from './actionTypes';
import { FetchOptions, fetchResolver } from './fetchResolver';

export function contactPreferenceAction(payload: Record<string, unknown>) {
  return (dispatch: StandardDispatchFunction): Promise<void> => {
    const url = '/api/updateContactPreference';
    const options: FetchOptions = {
      url,
      method: 'POST',
      data: payload,
      headers: { 'Content-Type': 'application/json' }
    };
    return fetchResolver(options, dispatch, (payload) => {
      dispatch({ type: 'CONTACT_PREFERENCE_UPDATED', payload });
    });
  };
}
