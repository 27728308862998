import { Component } from 'react';
import { ButtonToolbar, FormControl, FormGroup } from 'react-bootstrap';
import { FontIcon } from '../common/fontIcon';
import InlineEditor from './InlineEditor';

class TextFieldEditor extends Component<TextFieldEditorProps, TextFieldEditorState> {
  private inputBox: any;
  private inlineEditor: any;

  constructor(props: TextFieldEditorProps) {
    super(props);
    this.state = {
      value: props.value || '',
      validateState: null,
      validState: true
    };
    this.onEntered = this.onEntered.bind(this);
    this.onExit = this.onExit.bind(this);
    this.onExited = this.onExited.bind(this);
    this.onClose = this.onClose.bind(this);
    this.clickUpdate = this.clickUpdate.bind(this);
    this.validateInput = this.validateInput.bind(this);
  }

  validateInput(value: string): void {
    const { dataType } = this.props;
    const { validState } = this.state;

    this.setState({
      value: value
    });

    if (dataType === 'email') {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) || value === '') {
        this.setState({
          validateState: null
        });
      } else {
        this.setState({
          validateState: validState ? 'success' : null
        });
      }
    } else {
      if (value !== '') {
        this.setState({
          validateState: validState ? 'success' : null
        });
      } else {
        this.setState({
          validateState: null
        });
      }
    }
  }

  clickUpdate(): void {
    if (this.state.validateState === 'success' && this.state.value !== '') {
      this.props.onSuccess && this.props.onSuccess(this.state.value);
      this.setState({
        value: this.state.value
      });
      this.onClose();
    }
  }

  onEntered(): void {
    this.inputBox.focus && this.inputBox.focus();
  }

  onExited(): void {
    return;
  }

  onExit(): void {
    this.setState({
      validateState: null,
      value: ''
    });
  }

  onClose(): void {
    this.inlineEditor.overlay.setState({
      show: false
    });
    // this.props.onClose && this.props.onClose(this.inputBox.value);
    this.onExit();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps: TextFieldEditorProps): void {
    if (this.props === nextProps) {
      return;
    }

    if (nextProps.value !== this.state.value) {
      this.setState({
        value: nextProps.value || '',
        validateState: null
      });
    }
  }

  render(): JSX.Element {
    const { dataType, children, value, title } = this.props;
    const { validateState: stateValidate } = this.state;
    return (
      <InlineEditor
        ref={(ref) => (this.inlineEditor = ref)}
        onEntered={this.onEntered}
        dataType={dataType}
        title={title}
        value={value}>
        <FormGroup className="control-group" validationState={stateValidate}>
          <div>
            <div className="editable-input">
              <FormControl
                placeholder=""
                type={dataType}
                onChange={(e) => {
                  const value = (e.target as any).value;
                  this.validateInput(value);
                }}
                className="input-lg"
                inputRef={(ref) => {
                  this.inputBox = ref;
                }}
              />
            </div>
            <ButtonToolbar className="editable-buttons">
              <button onClick={this.clickUpdate} className="editable-submit btn-lg">
                <FontIcon icon="emoticon-invalid" />
              </button>
              <button onClick={this.onClose} className="editable-cancel btn-lg">
                <FontIcon icon="close" width={1} className="aj-inverse" />
              </button>
            </ButtonToolbar>
          </div>
        </FormGroup>
        {children as JSX.Element}
      </InlineEditor>
    );
  }
}

interface TextFieldEditorProps {
  children?: JSX.Element | JSX.Element[];
  dataType: 'email' | 'text';
  value: string;
  title: string;
  onSuccess: (value: string) => void;
}

interface TextFieldEditorState {
  value: string;
  validateState: 'success' | 'error' | null;
  validState: boolean;
}

export default TextFieldEditor;
