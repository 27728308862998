import { DocumentSummaryItem, SavedQuoteItem } from '../../actions/documents/getDocumentSummaryUsingAuthLinkAction';
import { AuthEmailLinkStatusTypes, GetSumaryUsingAuthLinkStatusTypes } from '../../reducers/documentsReducer';
import { DocumentDetailState } from '../../reducers/stateTypes';

export interface ShowComponent {
  summaryScreen: boolean;
  loginScreen: boolean;
  noDataScreen: boolean;
  authLinkHasBeenSentMessage: boolean;
  showLoadingSpinner: boolean;
  actionMessage: boolean;
  unableToLogin: boolean;
  authInProgressMessage: boolean;
}

export interface DetermineScreenProps {
  hasDataLoaded: boolean;
  documentSummary: DocumentSummaryItem[] | undefined;
  savedQuotes: SavedQuoteItem[] | undefined;
  authEmailLinkStatus: AuthEmailLinkStatusTypes;
  disableActionButtons: boolean;
  actionMessage: string | undefined;
  documentDetails: DocumentDetailState | undefined;
  sendAuthEmailStatus: GetSumaryUsingAuthLinkStatusTypes;
  outOfAuthAttempts: boolean;
  errors: string[];
  showAllScreens?: boolean;
}

export function determineScreens({
  documentSummary,
  savedQuotes,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  hasDataLoaded,
  authEmailLinkStatus,
  disableActionButtons,
  actionMessage,
  documentDetails,
  sendAuthEmailStatus,
  outOfAuthAttempts,
  errors,
  showAllScreens
}: DetermineScreenProps): ShowComponent {
  if (showAllScreens) {
    return allScreens();
  }

  const hasDocuments = !!documentSummary && documentSummary.length > 0;
  const hasSavedQuotes = !!savedQuotes && savedQuotes.length > 0;
  const showSummaryScreen: boolean = hasDocuments || hasSavedQuotes;
  const showAuthLinkHasBeenSent: boolean = authEmailLinkStatus === 'sent';
  const showLoadingSpinner: boolean = disableActionButtons;
  const showAlertActionMessage = !!actionMessage;
  const mayShowLoginScreen: boolean =
    !(documentDetails || showSummaryScreen) &&
    sendAuthEmailStatus !== 'in-progress' &&
    (!authEmailLinkStatus || authEmailLinkStatus === 'failed');
  const showLoginScreen: boolean = mayShowLoginScreen && !outOfAuthAttempts;
  const showUnableToLogin: boolean = !showLoginScreen && !showSummaryScreen;
  if (errors.length === 0 && sendAuthEmailStatus === 'failed') {
    errors.push(
      'The link you have used has expired or is invalid. Please try again or re-enter your details to get another email.'
    );
  }

  const hasErrors = errors.length > 0;
  const showAlertAuthInProgress: boolean =
    (authEmailLinkStatus === 'in-progress' || sendAuthEmailStatus === 'in-progress') && !hasErrors;

  return {
    summaryScreen: showSummaryScreen,
    noDataScreen: false, // hasDataLoaded && !showSummaryScreen,
    authLinkHasBeenSentMessage: showAuthLinkHasBeenSent,
    showLoadingSpinner,
    actionMessage: showAlertActionMessage,
    loginScreen: showLoginScreen,
    unableToLogin: showUnableToLogin,
    authInProgressMessage: showAlertAuthInProgress
  };
}

// For development only
function allScreens(): ShowComponent {
  return {
    summaryScreen: true,
    noDataScreen: true,
    authLinkHasBeenSentMessage: true,
    showLoadingSpinner: true,
    actionMessage: true,
    loginScreen: true,
    unableToLogin: true,
    authInProgressMessage: true
  };
}
