import { NotificationCardProps } from '../documentsPageCommon';

export function NotificationCard({
  id,
  mainContent,
  detailsContent,
  index,
  viewType,
  featureType
}: NotificationCardProps): JSX.Element {
  return (
    <div
      key={`doc-card-${viewType}-${index}`}
      className={`card container ${featureType}-${viewType} ${viewType}-view w-100`}>
      <div className="row">
        <div className="col-12">
          <div className="container detail w-100" id={id}>
            <div className="row">
              <div className="col-12">{mainContent}</div>
            </div>
          </div>
        </div>
      </div>
      {detailsContent}
    </div>
  );
}
