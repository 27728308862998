import { StandardAction } from '../actions/actionTypes';
import { getInitialState } from './initialState';
import { applyChanges, trimFieldValue } from './reducerLibrary';
import { EsigDetailsState } from './stateTypes';

const initialState = getInitialState().esigDetailLoginForm;

export function esigDetailsReducer(state: EsigDetailsState = initialState, action: StandardAction): EsigDetailsState {
  switch (action.type) {
    case 'FIELD_CHANGED':
      return (() => {
        const { fieldName, value, eventType } = action.payload;
        const updated = {};
        updated[fieldName] = trimFieldValue(value, eventType);
        return applyChanges(state, updated);
      })();

    case 'LOGIN_DETAIL_SUCCESS':
      return (() => {
        const { premfinaEsigID } = action.payload;
        const updated = {};
        updated['premfinaEsigID'] = premfinaEsigID;
        return applyChanges(state, updated);
      })();

    case 'LOG_OFF_SUCCESS':
    case 'CLEAR_ALL_FORM_DATA':
      return applyChanges(state, {
        surname: '',
        dateOfBirth: '',
        dobDay: '',
        dobMonth: '',
        dobYear: '',
        postcode: '',
        agreementReferenceId: '',
        esigSigned: false,
        esigAgreed: false,
        premfinaEsigID: ''
      });

    case 'ESIG_AGREED':
      return (() => {
        const { esigAgreed } = action.payload;
        return applyChanges(state, { esigAgreed });
      })();

    case 'ESIG_CONFIRMED':
      return (() => {
        const { success } = action.payload;
        if (success) {
          return applyChanges(state, { esigSigned: true });
        }

        return state;
      })();
    default:
      break;
  }

  return state;
}
