import { SpinnerControl as Spinner } from '../controls/SpinnerControl';

export interface SpinnerProps {
  message: string;
  widthClass?: string;
}

export function SpinnerControl({ message, widthClass }: SpinnerProps): JSX.Element {
  return (
    <div id="auth-in-progress-screen" className={`col-12 ${widthClass}`}>
      <div className="col-12 alert alert-info">{message}</div>
      <Spinner
        visible={true}
        typeOfSpinner="Ring"
        sizeOfSpinner={70}
        style={{ marginBottom: '2em' }}
        containerClassName="d-flex justify-content-center"
        containerStyle={{ padding: 0, margin: 0 }}
      />
    </div>
  );
}
