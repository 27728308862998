import jQuery from 'jquery';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as Actions from '../../actions/index';
import './HomePage.css';
import { HomePageSection } from './homePageSection';
import { PageComponent } from './PageComponent';
import { WelcomeMessage } from './WelcomeMessage';
import { wrapInMain } from './wrapInMain';

class HomePageComponent extends PageComponent<any, any> {
  componentDidMount() {
    this.props.clearForms();

    // when transitioning between 'documents' and 'main' site, we use different stylesheets so the page
    // needs to be reloaded to ensure that it appears correctly.
    let logos = jQuery('.logo').toArray();
    let logoCount = logos.map((logo) => jQuery(logo).is(':visible')).filter((v) => v === true).length;

    if (logoCount !== 1) {
      logos = jQuery('.logo-updated').toArray();
      logoCount = logos.map((logo) => jQuery(logo).is(':visible')).filter((v) => v === true).length;
      if (logoCount !== 1) {
        document.location.reload();
      }
    }
  }

  render() {
    const WelcomHomePageMessage = <span></span>;
    return wrapInMain(
      <div>
        <WelcomeMessage title="Homeprotect Policy Administration" subtitle={WelcomHomePageMessage} />

        <HomePageSection
          id="creditagreement-sign"
          icon="sign-dotted"
          title="Sign your credit agreement"
          description="Need to sign your credit agreement? If so, please log in to use our secure signing facility."
          navLinkTo="/esigregistration"
        />

        <HomePageSection
          id="make-payment"
          icon="card"
          title="Make a payment"
          description="Is your policy in arrears? Have we asked you to make a payment? If so, please log in to use our secure payment facility."
          navLinkTo="/makeapayment"
        />

        {/*
        <HomePageSection
          id="update-details"
          icon="bank"
          title="Change your bank details"
          description="Want to change your bank details? Log in to use our secure policy administration service."
          navLinkTo="/updatedetails" /> 
        */}

        <HomePageSection
          id="trustpilot-review"
          icon="reviews"
          title="Review us on Trustpilot"
          description="Tell the world what you think about Homeprotect!"
          linkUrl="https://www.homeprotect.co.uk/portal-review"
        />

        <HomePageSection
          id="facebook-follow"
          icon="facebook"
          title="Follow us on Facebook"
          description="Get updates about Homeprotect on Facebook"
          linkUrl="https://www.homeprotect.co.uk/portal-social"
        />

        <HomePageSection
          id="marketing-preference"
          icon="correspondence"
          title="Marketing preferences"
          description="Choose how you want to receive marketing communications from Homeprotect."
          navLinkTo="/ContactPreference"
        />

        <HomePageSection
          id="contact-us"
          icon="contact-us"
          title="Contact us"
          description="Need to speak to us about something? Call us or send us a message."
          linkUrl="https://www.homeprotect.co.uk/contact-us"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { userInterface } = state;
  return { userInterface };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearForms: (req) => dispatch(Actions.clearFormsAction(req))
  };
};

const HomePage = withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePageComponent) as any);
export { HomePage };
