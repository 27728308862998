import { ReactElement } from 'react';
import * as format from '../../formatters/index';
import { ExternalLink } from '../common/ExternalLink';
import { FontIcon } from '../common/fontIcon';
import { ButtonControl } from '../controls/ButtonControl';
import { IconFieldRowControl } from '../controls/IconFieldRowControl';
import { PanelControl } from '../controls/PanelControl';
import { ReadOnlyTextControl } from '../controls/ReadOnlyTextControl';

export interface ConfirmedPayDetailParams {
  paymentSuccess: boolean;
  formattedAmount: string | undefined;
  outstandingBalance: number;
  paymentResultToken: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  retryWithDifferentCard: Function;
}

export function ConfirmedPayDetail({
  paymentSuccess,
  formattedAmount,
  outstandingBalance,
  paymentResultToken,
  retryWithDifferentCard
}: ConfirmedPayDetailParams): JSX.Element {
  const pound = String.fromCharCode(163);
  const bodyElements: ReactElement[] = [];
  let keyNo = 1;
  const key = () => `element${keyNo++}`; // react needs this.
  if (paymentSuccess) {
    bodyElements.push(
      <div key={key()} className="alert alert-success" style={{ textAlign: 'center' }}>
        Your payment was successful!
      </div>
    );
    bodyElements.push(
      <ReadOnlyTextControl
        id="amount"
        key={key()}
        label="Amount paid"
        value={formattedAmount}
        prefixLabel={pound}
        isSlim={true}
      />
    );
  } else {
    bodyElements.push(
      <div key={key()} className="alert alert-danger" style={{ textAlign: 'center' }}>
        Your payment failed
      </div>
    );
    bodyElements.push(
      <ReadOnlyTextControl
        id="amount"
        key={key()}
        label="Amount attempted"
        value={formattedAmount}
        prefixLabel={pound}
        isSlim={true}
      />
    );
  }

  bodyElements.push(
    <ReadOnlyTextControl
      id="outstandingBalance"
      key={key()}
      label="Balance now"
      value={format.currency(outstandingBalance)}
      prefixLabel={pound}
      isSlim={true}
    />
  );
  bodyElements.push(
    <ReadOnlyTextControl id="paymentReference" key={key()} label="Reference" value={paymentResultToken} isSlim={true} />
  );

  if (!paymentSuccess) {
    bodyElements.push(
      <ButtonControl
        key={key()}
        name="retry-with-different-card"
        label="Retry with another card"
        onClick={retryWithDifferentCard as any}
      />
    );
  }

  return (
    <div>
      <PanelControl content={<IconFieldRowControl width={5} content={<div>{bodyElements}</div>} />} />

      <div className="panel panel-aj active-card ">
        <div className="panel-heading" role="tab" id="headingOne">
          <article className="media">
            <div className="media-left">
              <FontIcon icon="reviews" />
            </div>
            <div className="media-body">
              <h3>Rate your experience today</h3>
              <div className="media-content">
                <p>Tell us what you think about your experience today with our one question survey.</p>
              </div>
            </div>
            <section className="media-right">
              <ExternalLink
                id="rate-us-link"
                href="https://www.homeprotect.co.uk/rate-us"
                style={{ textDecoration: 'none', borderBottom: 'none' }}
                content={
                  <button
                    className="btn-mandatory"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    aria-expanded="false"
                    aria-controls="collapseOne">
                    <i></i>
                    <span>Go</span>
                  </button>
                }
              />
            </section>
          </article>
        </div>
      </div>
    </div>
  );
}
