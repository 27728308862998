import { lodash } from '@avantia/lodash';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export interface DateFormatterOptions {
  longYear?: boolean;
}

export function dateFormatter(date: Date, options?: DateFormatterOptions): string {
  const yearNum = date.getFullYear();
  if (isNaN(yearNum)) {
    return 'Invalid Date';
  }

  let year = String(yearNum);
  const month = monthNames[date.getMonth()].substring(0, 3);
  const day = lodash.padStart('' + date.getDate(), 2, '0');
  if (!options || options.longYear !== true) {
    year = year.substr(2);
  }

  return `${day} ${month} ${year}`;
}
