import * as fmt from '../../../formatters';
import {
  fmtDateOpts,
  renderDownloadPolicyDocumentLinks,
  renderEmailLink,
  RenderHistoryRowProps,
  typeCodeToDescription
} from '../documentsPageCommon';

export function HistoryRow({
  document,
  index,
  setActionMessage,
  emailDocuments,
  disableActionButtons,
  source
}: RenderHistoryRowProps): JSX.Element {
  const { policyNumber, documentArchiveId } = document;
  let buttonIndex = 0;
  return (
    <div className="row" key={`hist-${index}`}>
      <div className="col-12 card container history">
        <div className="row">
          <span className="description">{typeCodeToDescription(document.typeCode)}</span>
          <span className="period">
            {fmt.date(document.documentSentDate, fmtDateOpts)} &ndash; {fmt.date(document.endDate, fmtDateOpts)}
          </span>
        </div>
        <div className="row button-row">
          <div className={`col-12 buttons detail-buttons code-${document.typeCode}`}>
            {renderDownloadPolicyDocumentLinks({
              document,
              setActionMessage,
              disableActionButtons,
              buttonType: 'download',
              index: buttonIndex++,
              source
            })}
            {emailDocuments &&
              renderEmailLink({
                emailDocuments,
                disableActionButtons,
                policyNumber,
                documentArchiveId,
                buttonType: 'email',
                index: buttonIndex++,
                source
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
