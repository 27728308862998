export class FieldModel {
  name: string;
  rawValue: string | undefined;
  value: any;
  formattedValue: string | undefined;
  formatter: (text: string) => string;
  parser: (text: string) => any;
  isValid: (text: string) => boolean;

  constructor({ name, value, formatter, parser, isValid }: FieldModelProps) {
    this.name = name;
    this.formatter = formatter;
    this.parser = parser;
    this.isValid = isValid;
    this.setValue(value);
  }

  setValue(value: unknown): void {
    const newValue = value === '' ? value : this.parser(value as any);
    this.rawValue = value as any;
    if (this.isValid(newValue)) {
      this.value = newValue;
      this.formattedValue = this.formatter(newValue);
    } else {
      this.value = value;
      this.formattedValue = value as any;
    }
  }
}

export interface NameValueFieldModelProps {
  name: string;
  value: any;
}

export interface FieldModelProps extends NameValueFieldModelProps {
  formatter: (text: string) => string;
  parser: (text: string) => any;
  isValid: (text: string) => boolean;
}
