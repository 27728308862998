import jQuery from 'jquery';
import { Component } from 'react';

export interface PageComponentProps {
  userInterface: any;
}

export class PageComponent<PropsT extends PageComponentProps, StateT> extends Component<PropsT, StateT> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidUpdate(prevProps: PropsT, prevState: StateT): void {
    const { fieldWithFocus } = this.props.userInterface;
    if (fieldWithFocus) {
      setTimeout(() => {
        const field = jQuery(`#${fieldWithFocus}`)[0];
        if (field) {
          field.focus();
        }
      }, 500);
    }
  }
}
