import { BaseSyntheticEvent } from 'react';
import { ErrorModel } from '../../validators/modelValidator';
import { ButtonControl } from '../controls/ButtonControl';
import { FormStyleTypes } from '../styleLibrary';
import { FeatureTypes } from './documentsPageCommon';
import { Header } from './Header';
import './LoginScreen.scss';
import { TextInputControl } from './TextInputControl';

export interface LoginScreenProps {
  emailAddress: string;
  postcode: string;
  modelErrors: ErrorModel;
  actionMessageWrapper: JSX.Element | undefined;
  formStyle: FormStyleTypes;
  processingRequest: boolean;
  onChange: (event: BaseSyntheticEvent) => void;
  getAuthLinkEmail: (event: BaseSyntheticEvent) => void;
  feature: FeatureTypes;
}

interface LoginScreenCopy {
  header: string | JSX.Element;
  body: string | JSX.Element;
  subheader?: string | JSX.Element;
}

const defaultCopy = {
  header: 'Please log in',
  body: (
    <>
      For this reason we ask that you provide us with the email address and postcode{' '}
      <strong>associated with the property that you would like to insure</strong> and we will email you a secure,
      one-time access link to your information.
    </>
  ),
  subheader: 'Your security is of utmost importance to us'
};

const loginCopyFeatureMap: { [key in FeatureTypes]?: LoginScreenCopy } = {
  documents: defaultCopy,
  claims: {
    header: 'Log in to make a claim',
    body: (
      <>
        Enter your email and the postcode for the <strong>address you’re looking to make a claim against.</strong> We’ll
        email you a secure access link for you to check your cover and start making a claim.
      </>
    )
  }
};

export function LoginScreen({
  emailAddress,
  modelErrors,
  actionMessageWrapper,
  onChange,
  formStyle,
  postcode,
  processingRequest,
  getAuthLinkEmail,
  feature
}: LoginScreenProps): JSX.Element[] {
  const copy = loginCopyFeatureMap[feature] || defaultCopy;
  const { header, subheader, body } = copy;
  return [
    Header(undefined),
    <div key="login-container" className="login-container container">
      {actionMessageWrapper}
      <div className="row">
        <h1>{header}</h1>
      </div>
      <div className="row">
        <div className="col col-12">
          <div className="container card">
            {subheader && (
              <div className="row">
                <h2>{subheader}</h2>
              </div>
            )}
            <div className="row">
              <p>{body}</p>
            </div>
            <div className="row input-controls">
              <TextInputControl
                label="Email address"
                name="emailAddress"
                value={emailAddress}
                modelErrors={modelErrors}
                onChange={onChange}
                placeholder="name@email.com"
                formStyle={formStyle}
                boxClass="col col-12 col-sm-8 col-md-6 col-lg-4"
                inputClass="w-100"
                autoComplete={true}
              />
              <TextInputControl
                label="Postcode"
                name="postcode"
                value={postcode}
                modelErrors={modelErrors}
                onChange={onChange}
                placeholder="e.g. EC4Y 8EN"
                formStyle={formStyle}
                boxClass="col col-12 col-sm-4 col-md-3 col-lg-2"
                inputClass="w-100"
                autoComplete={true}
              />
            </div>
            <div className="row input-controls">
              <div className="col col-lg-4">
                <ButtonControl
                  name="get-access-link"
                  label="Email my access link"
                  onClick={getAuthLinkEmail}
                  disabled={processingRequest}
                  formStyle={formStyle}
                  buttonClass="animated btn btn-primary block pulse w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ];
}
