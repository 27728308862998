import { addAmountValidation, AddAmountValidationProps } from './loginAmountPolicyPostcodeValidator';
import { ErrorModel, ModelToValidate, modelValidator, ProcessRulesOptions } from './modelValidator';
import * as Rules from './validatorRules';

export function paymentDetailsValidator(
  model: ModelToValidate & AddAmountValidationProps,
  options?: ProcessRulesOptions | undefined
): ErrorModel {
  options = options || {};
  const warnings = {};
  const errors = {
    cardholderName: [Rules.RequiredRule, Rules.createAccountHolderLengthRule()]
  };

  addAmountValidation(options as AddAmountValidationProps, warnings, errors);

  return modelValidator(model, { errors, warnings }, options);
}
