import { StandardDispatchFunction } from './actionTypes';
import { FetchOptions, fetchResolver } from './fetchResolver';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function esigLoginAction({ surname, dateOfBirth, postcode, agreementReferenceId }: EsigLoginActionProps) {
  const body = { surname, dateOfBirth, postcode, agreementReferenceId };

  return (dispatch: StandardDispatchFunction): Promise<void> => {
    const options: FetchOptions = {
      url: '/api/esigDetail',
      method: 'POST',
      data: body,
      headers: { 'Content-Type': 'application/json' }
    };

    return fetchResolver(options, dispatch, (payload) => {
      if ((payload.validationErrors || []).length === 0 && payload.premfinaEsigID !== 0) {
        dispatch({ type: 'LOGIN_DETAIL_SUCCESS', payload });
      } else {
        dispatch({ type: 'ESIG_LOGIN_ERROR', payload });
      }
    });
  };
}

export interface EsigLoginActionProps {
  surname: string;
  dateOfBirth: string;
  postcode: string;
  agreementReferenceId: string;
}
