import cookies from 'js-cookie';

export function applyChanges<ItemT>(existing: ItemT, changes: Partial<ItemT>): ItemT {
  return Object.assign({}, existing, changes);
}

export function traverseObject<ExpectedResultT = never>(
  item: Record<string, unknown>,
  path: string
): ExpectedResultT | undefined {
  let result = item as any;
  for (const part of path.split('.')) {
    result = result[part];
    if (result === undefined) {
      return undefined;
    }
  }

  return result;
}

export function postcodeFieldValueFormatter(postcode: string): string {
  postcode = postcode.toUpperCase();
  while (postcode.indexOf('  ') >= 0) {
    postcode = postcode.replace('  ', ' ');
  }

  return postcode;
}

export function trimFieldValue(fieldValue: string | number | null | undefined, eventType: string): string {
  let value = `${fieldValue}`;
  if (eventType === 'blur') {
    value = value.trim();
  }

  return value;
}

export function getCookieBoolean(key: string): boolean | undefined {
  const cookieValue: string | undefined = cookies.get(key);
  switch (cookieValue) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return undefined;
  }
}
