import { DispatchFunction } from '../actionTypes';
import { FormTypePayload } from './FormTypePayload';

export interface ToggleHistoryPayload {
  policyNumber: string;
  makeVisible: boolean;
}

export function toggleHistoryAction(payload: ToggleHistoryPayload & FormTypePayload) {
  return (dispatch: DispatchFunction<ToggleHistoryPayload>): Promise<void> => {
    const { formType, policyNumber, makeVisible } = payload;
    return dispatch({ type: 'TOGGLE_POLICY_HISTORY', formType, payload: { policyNumber, makeVisible } });
  };
}
