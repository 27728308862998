import { CSSProperties } from 'react';
import './Footer.css';

export interface FooterDigiCertProps {
  colClassName?: string;
  rowClassName?: string;
  style?: CSSProperties;
  clickStyle?: CSSProperties;
}

export default function FooterDigiCert({
  rowClassName,
  colClassName,
  style,
  clickStyle
}: FooterDigiCertProps): JSX.Element {
  return (
    <div className={rowClassName + ' row'} style={style || { marginBottom: '5px' }}>
      <div className={colClassName || 'col-xs-12 text-center'}>
        <div id="DigiCertClickID_uhsiDmtF" data-language="en" style={clickStyle || { marginTop: '0.5em' }}></div>
      </div>
    </div>
  );
}
