import { voidFunction } from '@avantia/client-and-server-utilities';
import fetch from 'cross-fetch';
import { logInfo } from '../clientLogging';
import { getConfig } from '../configuration';
import { StandardDispatchFunction } from './actionTypes';

const { virtualPath } = getConfig();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function fetchAuthenticatedUserAction() {
  let customerPortalVirtualPath = virtualPath;
  if (customerPortalVirtualPath === '/') {
    customerPortalVirtualPath = '';
  }

  return (dispatch: StandardDispatchFunction): Promise<void> => {
    const url = `${customerPortalVirtualPath}/api/AuthenticatedUser`;
    const promise = fetch(url)
      .then(
        (response) => response.json(),
        (error) => logInfo({ message: 'An error occurred.', error })
      )
      .then((json) => {
        if (json) {
          dispatch({ type: 'RECEIVE_AUTHENTICATED_USER', payload: json });
        }
      });

    promise.catch(voidFunction).catch(voidFunction); // catch x2 for code quality

    return promise;
  };
}
