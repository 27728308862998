import { StandardAction } from '../actions/actionTypes';
import { CurrencyFieldModel } from '../models/CurrencyFieldModel';
import { getInitialState } from './initialState';
import { applyChanges, postcodeFieldValueFormatter, trimFieldValue } from './reducerLibrary';
import { PaymentFormState } from './stateTypes';

const initialState = getInitialState().paymentForm;

export function paymentFormReducer(state: PaymentFormState = initialState, action: StandardAction): PaymentFormState {
  switch (action.type) {
    case 'FIELD_CHANGED':
      return (() => {
        // eslint-disable-next-line prefer-const
        let { fieldName, value, eventType } = action.payload;
        const updated = {};
        value = trimFieldValue(value, eventType);
        if (fieldName === 'postcode') {
          value = postcodeFieldValueFormatter(value);
        } else if (fieldName === 'policyNumber') {
          value = value.toUpperCase();
        } else if (fieldName === 'amount') {
          value = new CurrencyFieldModel({ name: 'amount', value });
        }

        updated[fieldName] = value;

        return applyChanges(state, updated);
      })();

    case 'RETRIEVED_PAYMENT_TOKEN':
      return (() => {
        const { amount, paymentToken, title, forename, surname } = action.payload;
        if (paymentToken) {
          return {
            policyNumber: '',
            postcode: '',
            amount: new CurrencyFieldModel({ name: 'amount', value: amount }),
            cardholderName: `${title} ${forename} ${surname}`.trim().replace('  ', ' ')
          } as PaymentFormState;
        }

        return state;
      })();

    case 'CLEAR_ALL_FORM_DATA':
    case 'LOG_OFF_SUCCESS':
      return {
        policyNumber: '',
        postcode: '',
        amount: new CurrencyFieldModel({ name: 'amount', value: 0 })
      } as PaymentFormState;

    default:
      break;
  }

  return state;
}
