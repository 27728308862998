/* eslint-disable @typescript-eslint/ban-types */
import { RingLoader } from 'react-spinners';
import { FetchError } from '../../actions/actionTypes';
import * as formatter from '../../formatters/index';
import { AddressModel } from '../../models/models';
import { ErrorModel, ServerValidationModel } from '../../validators/modelValidator';
import { ErrorSection } from '../common/ErrorSection';
import { getErrorsAsArray } from '../componentLibrary';
import { ButtonControl } from '../controls/ButtonControl';
import { IconFieldRowControl } from '../controls/IconFieldRowControl';
import { ReadOnlyTextControl } from '../controls/ReadOnlyTextControl';
import { TextInputControl } from '../controls/TextInputControl';
import '../defaulters/CustomerPayDetails.css';

export interface CustomerDetailsBankParams {
  accountHolder: string;
  accountNumber: string;
  sortCode: any;
  fieldBlurred: Function;
  fieldFocussed: Function;
  address: AddressModel;
  modifyFieldChanged: Function;
  bankInfoUpdate: Function;
  processingRequest: boolean;
  validationErrors: ErrorModel | ServerValidationModel;
  fetchError: FetchError;
  modelErrors: ErrorModel;
  fieldWithFocus: string;
}

export function CustomerDetailsBank({
  accountHolder,
  accountNumber,
  sortCode,
  fieldBlurred,
  fieldFocussed,
  address,
  modifyFieldChanged,
  bankInfoUpdate,
  processingRequest,
  validationErrors,
  fetchError,
  modelErrors,
  fieldWithFocus
}: CustomerDetailsBankParams): JSX.Element {
  const accountHolderName = 'accountHolder',
    accountNumberName = 'accountNumber',
    sortCodeName = 'sortCode',
    accountHolderLabel = 'Account holder name',
    accountNumberLabel = 'Account number',
    sortCodeLabel = 'Sort code',
    accountHolderError = modelErrors.getError(accountHolderName, accountHolderLabel),
    accountNumberError = modelErrors.getError(accountNumberName, accountNumberLabel),
    sortCodeError = modelErrors.getError(sortCodeName, sortCodeLabel);
  const addressText = formatter.address(address);
  let validationErrorMessage = getErrorsAsArray(validationErrors, undefined)[0] || '';
  const fetchErrorMessage = fetchError && fetchError.message ? fetchError.message : '';
  if (validationErrorMessage && validationErrorMessage.indexOf('CPS010') >= 0) {
    validationErrorMessage = 'Sorry, bank validation has failed. Please check your details and try again.';
  }

  const loadingText = 'Please wait while we update your bank details';
  const sortCodeField = sortCode;
  const panelContent = (
    <div>
      <IconFieldRowControl
        icon="about-you"
        width={5}
        content={
          <TextInputControl
            name={accountHolderName}
            label={accountHolderLabel}
            placeholder="e.g. Gordon Brown"
            ariaLabel={accountHolderLabel}
            value={accountHolder}
            modelErrors={modelErrors}
            onChange={modifyFieldChanged as any}
          />
        }
      />
      <IconFieldRowControl
        icon="bank"
        width={5}
        content={
          <div>
            <TextInputControl
              name={accountNumberName}
              label={accountNumberLabel}
              placeholder="eg: 12345678"
              value={accountNumber}
              ariaLabel={accountNumberLabel}
              modelErrors={modelErrors}
              onChange={modifyFieldChanged as any}
            />

            <TextInputControl
              name={sortCodeName}
              label={sortCodeLabel}
              placeholder="eg: 123456"
              value={fieldWithFocus === sortCodeName ? sortCodeField.rawValue : sortCodeField.formattedValue}
              ariaLabel={sortCodeLabel}
              modelErrors={modelErrors}
              onFocus={fieldFocussed}
              onBlur={fieldBlurred}
              onChange={modifyFieldChanged as any}
            />
          </div>
        }
      />
    </div>
  );

  const buttonsAndMessages = (
    <div>
      <div className="row div-block" style={{ marginBottom: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
        <ButtonControl
          name="Update"
          label="Update details"
          onClick={bankInfoUpdate as any}
          disabled={processingRequest || modelErrors.count > 0 || !(accountHolder && accountNumber && sortCode)}
        />
      </div>
      <div className="sweet-loading">
        <RingLoader
          cssOverride={{ position: 'fixed', top: '50%', left: '50%' } as any}
          size={55}
          color={'#337ab7'}
          loading={processingRequest}
        />
        {processingRequest && <div style={{ textAlign: 'center' }}>{loadingText}</div>}
      </div>
      <ErrorSection
        processingRequest={processingRequest}
        errors={[
          accountHolderError || '',
          accountNumberError || '',
          sortCodeError || '',
          fetchErrorMessage,
          validationErrorMessage
        ]}
      />
    </div>
  );

  return (
    <div className="panel panel-aj active-card">
      <div className="panel-heading" role="tab" id="Activeheading">
        <ReadOnlyTextControl
          id="addressText"
          label="Insured property"
          value={addressText}
          icon="home"
          isMaskingEnabled={true}
        />
      </div>
      <div className="row div-block" style={{ marginBottom: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
        {panelContent}
      </div>
      {buttonsAndMessages}
    </div>
  );
}
