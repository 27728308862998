import { DocumentDetailSection } from './DocumentDetailSection';
import {
  createAutoRenewalRow,
  EmailDocumentsFunc,
  PolicyDetailsCardCommonProps,
  PolicyDetailsRows,
  renderDownloadPolicyDocumentLinks,
  renderEmailLink,
  typeCodeToDescription
} from './documentsPageCommon';

export interface DocumentSummaryCardProps extends PolicyDetailsCardCommonProps {
  emailDocuments: EmailDocumentsFunc;
  policyDetailsRows: PolicyDetailsRows;
}

export function DocumentSummaryCard({
  document,
  policyDetailsRows,
  disableActionButtons,
  index,
  historyVisible,
  viewType,
  featureType,
  source,
  setActionMessage,
  setActiveTab,
  emailDocuments,
  updateAutoRenewalStatus,
  DocumentCard,
  DocumentContentRow,
  HistoryRow,
  toggleHistory
}: DocumentSummaryCardProps): JSX.Element {
  const { policyNumber, cardNumber, accountNumber, documentArchiveId } = document;
  const historyKey = 'policy-history-' + policyNumber;
  const historyRows = (document.childItems || []).map((item, index) =>
    HistoryRow({
      document: item,
      index,
      setActionMessage,
      emailDocuments,
      disableActionButtons,
      source
    })
  );
  const detailsContent = DocumentDetailSection({
    id: historyKey,
    detailText: 'my Policy History',
    details: historyRows,
    toggleEvent: (e, show) => toggleHistory(e, policyNumber, show),
    isDetailVisible: historyVisible[policyNumber]
  });

  const { policyNumberRow, policyTermRow, postcodeRow, warningCard } = policyDetailsRows;
  let buttonIndex = 0;

  const mainContent = [policyNumberRow];
  if (!warningCard) {
    const policyStatusRow = DocumentContentRow({
      labelKey: 'policyStatus',
      content: typeCodeToDescription(document.typeCode)
    });
    mainContent.push(policyStatusRow);
  }

  mainContent.push(policyTermRow, postcodeRow);

  // It's expected that you do not have both cardNumber and accountNumber. If you do, we will show cardNumber.
  if (cardNumber && !accountNumber) {
    mainContent.push(
      DocumentContentRow({
        labelKey: 'cardNumber',
        content: cardNumber,
        isMaskingEnabled: true
      })
    );
  }

  if (accountNumber && !cardNumber) {
    mainContent.push(
      DocumentContentRow({
        labelKey: 'accountNumber',
        content: accountNumber,
        isMaskingEnabled: true
      })
    );
  }

  if (accountNumber && cardNumber) {
    mainContent.push(
      DocumentContentRow({
        labelKey: 'cardNumber',
        content: cardNumber,
        isMaskingEnabled: true
      })
    );
  }

  const autoRenewalRow = createAutoRenewalRow({
    document,
    disableActionButtons,
    showToggle: false,
    updateAutoRenewalStatus,
    setActiveTab,
    DocumentContentRow
  });

  if (autoRenewalRow) {
    mainContent.push(autoRenewalRow);
  }

  const cardElement = DocumentCard({
    id: historyKey,
    index,
    viewType,
    featureType,
    mainContent,
    messageContent: warningCard,
    detailsContent,
    buttonContent: [
      renderDownloadPolicyDocumentLinks({
        document,
        setActionMessage,
        disableActionButtons,
        index: buttonIndex++,
        source
      }),
      renderEmailLink({
        emailDocuments,
        disableActionButtons,
        policyNumber,
        documentArchiveId,
        index: buttonIndex++,
        source
      })
    ]
  });

  return cardElement;
}
