import { lodash } from '@avantia/lodash';
import * as parser from '../parsers/index';

const surnameExample = ', e.g. Brown';
const postcodeExample = ', e.g. KT1 1PH';
const policyNumberExample = ', e.g. P01/0002799';

export type ValidationRule = (value: unknown, name: string) => string;

function RequiredRule(v: unknown, name: string | undefined, message?: string): string {
  return isEmptyValue(v)
    ? message ||
        `The ${name} field is required${
          name === 'dateOfBirth'
            ? ', e.g. 23/12/1999'
            : name === 'surname'
            ? surnameExample
            : name === 'postcode'
            ? postcodeExample
            : name === 'policyNumber'
            ? policyNumberExample
            : name === 'dobDay'
            ? ', e.g a value between 1 and 31'
            : name === 'dobMonth'
            ? ', e.g a value between 1 and 12'
            : `. --${name}`
        }`
    : '';
}

function NumericRule(v: unknown, name: string): string {
  if (isEmptyValue(v)) {
    return '';
  }

  return isNaN(parser.currency(v as any)) ? `The ${name} field must contain a number.` : '';
}

function createLengthRule(min: number, max?: number | undefined): ValidationRule {
  max = max === undefined ? 1000 : max;
  return (v, name) => {
    if (v === null || v === undefined || v === '') {
      return '';
    }

    const strv = lodash.trim(`${v}`);
    if (strv.length < min || strv.length > (max as number)) {
      let message: string;
      if (min === max) {
        message = `The ${name} field must be ${min} characters in length${
          name === 'surname' ? surnameExample : name === 'policyNumber' ? policyNumberExample : '.'
        }`;
      } else if (strv.length < min) {
        message = `The ${name} field must be at least ${min} characters in length${
          name === 'surname' ? surnameExample : name === 'policyNumber' ? policyNumberExample : '.'
        }`;
      } else {
        message = `The ${name} field cannot be more than ${max} characters in length${
          name === 'surname' ? surnameExample : name === 'policyNumber' ? policyNumberExample : '.'
        }`;
      }

      return message;
    }

    return '';
  };
}

function createRangeRule(
  min: string | number | undefined,
  max: string | number | undefined,
  messageOverride?: string
): ValidationRule {
  const minNo = parser.currency(min);
  const maxNo = parser.currency(max);
  return (v, name) => {
    if (isEmptyValue(v)) {
      return '';
    }

    const numv = parser.currency(v as any);
    if (min !== null && min !== undefined && numv < minNo) {
      return messageOverride || `The ${name} field cannot be under ${min}.`;
    } else if (max !== null && max !== undefined && numv > maxNo) {
      return messageOverride || `The ${name} field cannot be over ${max}.`;
    }

    return '';
  };
}

function createRegexRule(pattern: string | RegExp, messageOverride?: string): ValidationRule {
  const re = new RegExp(pattern);
  return (v, name) => {
    if (isEmptyValue(v)) {
      return '';
    }

    return re.test(v as any)
      ? ''
      : replaceNameInMessage(messageOverride, name) || `The ${name} field does not match the pattern "${pattern}"`;
  };
}

function createPostcodeRule(message?: string): ValidationRule {
  // Refer: https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/488478/Bulk_Data_Transfer_-_additional_validation_valid_from_12_November_2015.pdf
  return createRegexRule(
    /^([Gg][Ii][Rr] ?0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) ?[0-9][A-Za-z]{2})$/i,
    message || 'The {name} is not valid, e.g. KT1 1PH'
  );
}

function createAccountHolderLengthRule(): ValidationRule {
  return createLengthRule(4, 100);
}

function createAgreementReferenceLengthRule(): ValidationRule {
  return createLengthRule(9, 9);
}

function createPolicyNumberLengthRule(): ValidationRule {
  return createLengthRule(4, 20);
}

function isEmptyValue(v: any): boolean {
  return v === null || v === undefined || v === '';
}

function replaceNameInMessage(message: string | undefined, name: string): string | undefined {
  return message && message.replace ? message.replace('{name}', name) : undefined;
}

function createDateOfBirthRule(): ValidationRule {
  return createRegexRule(
    /^((((([1-9])|(0[1-9])|(1\d)|(2[0-8]))(\/)(([1-9])|(0[1-9])|(1[0-2])))|((31\/(((0[13578])|([13578]))|(1[02])))|((29|30)(\/)(((0[1,3-9])|([1,3-9]))|(1[0-2])))))(\/)((20[0-9][0-9]))|(((([1-9])|(0[1-9])|(1\d)|(2[0-8]))\/(([1-9])|(0[1-9])|(1[0-2])))|((31\/(((0[13578])|([13578]))|(1[02])))|((29|30)\/(((0[1,3-9])|([1,3-9]))|(1[0-2])))))\/((19[0-9][0-9]))|(29\/(02|2)\/20(([02468][048])|([13579][26])))|(29\/(02|2)\/19(([02468][048])|([13579][26]))))$/,
    'The {name} is not valid, e.g. 23/12/1999.'
  );
}

function createEmailAddressRule(message?: string): ValidationRule {
  // see https://emailregex.com/
  const emailRegex =
    // eslint-disable-next-line no-control-regex
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
  return createRegexRule(emailRegex, message || 'The {name} is not a valid email address.');
}

export {
  RequiredRule,
  NumericRule,
  createLengthRule,
  createRangeRule,
  createRegexRule,
  createPostcodeRule,
  createAccountHolderLengthRule,
  createAgreementReferenceLengthRule,
  createPolicyNumberLengthRule,
  createDateOfBirthRule,
  createEmailAddressRule
};
