import { NavLink } from 'react-router-dom';
import HPQImage from '../../assets/images/HomeProtectQuestion.png';
import { wrapInMain } from './wrapInMain';

function ErrorPage(): JSX.Element {
  return wrapInMain(
    <div className="panel panel-aj active-card">
      <div className="panel-heading" role="tab" id="Activeheading">
        <article className={`media `}>
          <div className="media-left">
            <img className="img-fluid" aria-label="Homeprotect" src={HPQImage} alt="Homeprotect" height="250px" />
          </div>
          <div className="media-body">
            <div className="media-content">
              <h3 style={{ marginLeft: '10px', marginBottom: '5px' }}>Sorry, we can’t find this page.</h3>

              <p style={{ marginLeft: '10px', marginTop: '20px' }}>
                The page you are looking for doesn&apos;t exist. To return to our Homepage, please click
                <NavLink to="/" style={{ borderBottom: 'none' }}>
                  here
                </NavLink>
              </p>
            </div>
          </div>
        </article>
        <div className="row div-block" style={{ marginBottom: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
          {/* <NavLink to='/' style={{ borderBottom: 'none' }}> */}
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
