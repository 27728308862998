import { BaseSyntheticEvent } from 'react';
import { FetchError } from '../../actions/actionTypes';
import { ErrorModel, getFirstError, ServerValidationModel } from '../../validators/modelValidator';
import { ErrorSection } from '../common/ErrorSection';
import { ButtonControl } from '../controls/ButtonControl';
import { IconFieldRowControl } from '../controls/IconFieldRowControl';
import { PanelControl } from '../controls/PanelControl';
import { SpinnerControl } from '../controls/SpinnerControl';
import '../defaulters/CustomerPayDetails.css';

export interface EsigSignCreditAgreementProps {
  esigAgreed: boolean;
  esigChecked: (event: BaseSyntheticEvent) => void;
  confirmEsig: (event: BaseSyntheticEvent) => void;
  processingRequest: boolean;
  validationErrors: ErrorModel | ServerValidationModel;
  fetchError: FetchError;
  modelErrors: ErrorModel;
}

export function EsigSignCreditAgreement({
  esigAgreed,
  esigChecked,
  confirmEsig,
  processingRequest,
  validationErrors,
  fetchError
}: EsigSignCreditAgreementProps): JSX.Element {
  const validationErrorMessage = validationErrors ? getFirstError(validationErrors) : '';
  const fetchErrorMessage = fetchError && fetchError.message ? fetchError.message : '';
  const loadingText = 'Please wait while we update your details';
  const bodyElement = (
    <div>
      <PanelControl
        content={
          <div>
            <IconFieldRowControl
              width={5}
              content={
                <div>
                  <div>
                    <label style={{ paddingLeft: '30px' }}>
                      <input
                        style={{ marginLeft: '-35px', width: '30px', height: '30px' }}
                        type="checkbox"
                        name="esigSign"
                        value="true"
                        onChange={esigChecked}
                        checked={esigAgreed}
                      />
                      <p style={{ fontSize: '1em', marginTop: '-35px' }}>
                        I, the policyholder, have read and understood the terms of the Homeprotect Credit Agreement
                        (sent by email) and confirm acceptance via this e-sign.
                      </p>
                    </label>
                  </div>
                </div>
              }
            />

            <div className="row div-block" style={{ marginBottom: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
              <ButtonControl
                name="Confirm Esig"
                label="Confirm acceptance"
                onClick={confirmEsig}
                disabled={processingRequest || esigAgreed === false}
              />
            </div>
            <SpinnerControl
              visible={processingRequest}
              positionLeftInt={47}
              positionTopInt={65}
              spinnerText={loadingText}
              typeOfSpinner="Ring"
            />
            <ErrorSection processingRequest={processingRequest} errors={[fetchErrorMessage, validationErrorMessage]} />
          </div>
        }
      />
    </div>
  );
  return <div>{bodyElement}</div>;
}
