import { IconTypes } from '../iconTypes';
import { FontIcon } from './fontIcon';

export interface WelcomeMessageProps {
  icon?: IconTypes;
  subtitle: string | JSX.Element;
  title: string | JSX.Element;
  iconWidth?: number;
}

export function WelcomeMessage({ title, subtitle, icon, iconWidth }: WelcomeMessageProps): JSX.Element {
  subtitle = subtitle || 'Oh dear! It looks like this page needs a message';
  title = title || 'Customer portal';
  return (
    <div id="welcome-message">
      <article className="welcome-card">
        <h1>
          {icon && <FontIcon icon={icon} width={iconWidth} className="icon-middle" />}
          {title}
        </h1>
        <div>
          <p>
            <span className="sessioncamhidetext">{subtitle}</span>
          </p>
        </div>
      </article>
    </div>
  );
}
