import { AnalyticsPageProps } from './analytics';
import { NotificationCard } from './desktop/NotificationCard';
import {
  DocumentCardProps,
  DocumentContentRowProps,
  FeatureTypes,
  SourceTypes,
  ViewTypes
} from './documentsPageCommon';

export interface NotificationsCardProps {
  DocumentCard: (props: DocumentCardProps) => JSX.Element;
  DocumentContentRow: (props: DocumentContentRowProps) => JSX.Element;
  viewType: ViewTypes;
  featureType: FeatureTypes;
  analyticsProps: AnalyticsPageProps;
  source: SourceTypes;
}

export function NotificationsCard({ viewType, featureType }: NotificationsCardProps): JSX.Element {
  const cardElement = NotificationCard({
    id: 'notification-1',
    index: 0,
    viewType,
    featureType,
    mainContent: [
      <>
        <label>Notice for customers who purchased a new policy before 28th April 2021:</label>
        <div>
          We’ve corrected an error in the Statement of Fact document (our summary of information you provided when
          applying for your Homeprotect policy). Previously the questions asked about any flooding and
          subsidence/landslip/heave incidents which had occurred within the last 25 years, whereas it should have read
          10 years. <br /> <br /> This has now been corrected and any historical incidents more than 10 years old will
          be removed from your Statement of Fact at your next renewal. Please rest assured that these historical
          incidents have no impact on your policy or on any claim you may make. We’re sorry for any confusion our error
          may have caused. There is no need for you to do anything.
        </div>
      </>
    ],
    detailsContent: null,
    buttonContent: []
  });
  return cardElement;
}
