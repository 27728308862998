import { SMap } from '@avantia/client-and-server-utilities';
import { CSSProperties } from 'react';

export type ControlNames = 'TextInputControl' | 'ButtonControl' | 'ErrorSection';

export type FormStyleTypes = 'Documents' | 'Standard';

const styleMap: StyleMap = {
  Documents: {
    TextInputControl: {
      style: {},
      className: 'col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 block'
    },
    ButtonControl: {
      style: {},
      className: 'animated btn btn-primary col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 block pulse'
    },
    ErrorSection: {
      style: {}
    }
  },
  Standard: {
    TextInputControl: {
      style: { height: '50px', fontSize: '1em' }
    },
    ButtonControl: {
      style: {},
      className:
        'animated btn btn-primary hp-btn-continue btn-lg col-xs-12 col-sm-4 col-sm-offset-4 col-md-4 col-md-offset-4 block pulse'
    },
    ErrorSection: {
      style: { paddingLeft: '20px', paddingRight: '20px' }
    }
  }
};

export function getControlClassName(formStyleType: FormStyleTypes | undefined, controlName: ControlNames): string {
  const formItem = styleMap[formStyleType || 'Standard'][controlName];
  const stdItem = styleMap['Standard'][controlName];
  return (formItem && formItem.className !== undefined ? formItem.className : stdItem.className) || '';
}

export function getControlStyle(
  formStyleType: FormStyleTypes | undefined,
  controlName: ControlNames,
  hasError: boolean
): CSSProperties {
  const formItem = styleMap[formStyleType || 'Standard'][controlName];
  const stdItem = styleMap['Standard'][controlName];
  let style: CSSProperties = formItem ? formItem.style : stdItem.style;
  if (hasError) {
    style = Object.assign({}, style, formItem.errorStyle || stdItem.errorStyle);
  }

  return style;
}

type StyleMap = { [formStyleType: string]: StyleFormMap };

type StyleFormMap = SMap<StyleFormItem>;

type StyleFormItem = { style: CSSProperties; errorStyle?: CSSProperties; className?: string };
