import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as Actions from '../../actions/index';
import { PageComponent } from '../common/PageComponent';
import { WelcomeMessage } from '../common/WelcomeMessage';
import { wrapInMain } from '../common/wrapInMain';
import { IconTypes } from '../iconTypes';
import { CustomerDetailsBank } from './CustomerDetailsBank';
import { CustomerDetailsBankUpdated } from './CustomerDetailsBankUpdated';
import { LoginDetailsBank } from './LoginDetailsBank';

class CustomerBankDetailsPage extends PageComponent<any, any> {
  constructor(props, context) {
    super(props, context);
    this.modifyFieldChanged = this.modifyFieldChanged.bind(this);
    this.login = this.login.bind(this);
    this.bankInfoUpdate = this.bankInfoUpdate.bind(this);
    this.retryWithDifferentBankDetails = this.retryWithDifferentBankDetails.bind(this);
    this.fieldFocussedOrBlurred = this.fieldFocussedOrBlurred.bind(this);
  }

  modifyFieldChanged(event, validatorName) {
    event.preventDefault();
    const { postcode, policyNumber, accountHolder, accountNumber, sortCode, userInterface } = this.props;
    const { name, value } = event.target;
    const { modelErrors } = userInterface;
    this.props.modifyField({
      fieldName: name,
      value,
      eventType: event.type,
      validatorName,
      postcode,
      policyNumber,
      accountHolder,
      accountNumber,
      sortCode,
      modelErrors
    });
  }

  login(event) {
    event.preventDefault();
    const { postcode, policyNumber } = this.props.bankDetailLoginForm;
    this.props.bankDetailLogin({ postcode, policyNumber });
  }

  bankInfoUpdate(event) {
    event.preventDefault();
    const { postcode, policyNumber } = this.props;
    const { accountHolder, accountNumber, sortCode } = this.props.bankInfoForm;
    const sortCodeValue = sortCode.isValid() ? sortCode.value : sortCode.rawValue;
    this.props.bankInfoUpdate({
      postcode,
      policyNumber,
      accountHolderName: accountHolder,
      accountNumber,
      sortCode: sortCodeValue
    });
  }

  retryWithDifferentBankDetails(event) {
    event.preventDefault();
    this.props.retryWithDifferentBankDetails();
  }

  fieldFocussedOrBlurred(event) {
    event.preventDefault();
    const { name } = event.target;
    this.props.fieldFocussedOrBlurred({ fieldName: name, eventType: event.type });
  }

  render() {
    const {
      policyNumber,
      forename,
      accountNumber, // populated and masked only when updated successfully
      validationErrors,
      bankDetailLoginForm,
      bankInfoForm,
      address,
      userInterface
    } = this.props;
    const { fetchError, modelErrors, fieldWithFocus, processingRequest } = userInterface;
    const welcomeProps: { title: string; icon: IconTypes; iconWidth: number } = {
      title: 'Change bank details',
      icon: 'bank',
      iconWidth: 1.5
    };
    const welcomeMessages = {
      notLoggedIn: 'Please log in to change your bank details.',
      notYetUpdatedBankDetails: `Hello ${forename}. Please enter your new bank details below.`,
      bankDetailsUpdated: `Thank you ${forename}! We have updated your bank details and we will use these for your next Direct Debit payment.`
    };

    const notLoggedIn = !policyNumber;
    const notYetUpdatedBankDetails = policyNumber && !accountNumber;
    const bankDetailsUpdated = policyNumber && accountNumber;

    return wrapInMain(
      <div>
        {notLoggedIn && (
          <div className="col-lg-12">
            <WelcomeMessage subtitle={welcomeMessages.notLoggedIn} {...welcomeProps} />
            <LoginDetailsBank
              {...{
                policyNumber: bankDetailLoginForm.policyNumber,
                postcode: bankDetailLoginForm.postcode,
                fieldWithFocus,
                modifyFieldChanged: (e) => this.modifyFieldChanged(e, 'loginPolicyPostcodeValidator'),
                login: this.login,
                processingRequest: processingRequest,
                fetchError,
                validationErrors,
                modelErrors
              }}
            />
          </div>
        )}

        {notYetUpdatedBankDetails && (
          <div className="col-lg-12">
            <WelcomeMessage subtitle={welcomeMessages.notYetUpdatedBankDetails} {...welcomeProps} />
            <CustomerDetailsBank
              {...{
                accountHolder: bankInfoForm.accountHolder,
                accountNumber: bankInfoForm.accountNumber,
                sortCode: bankInfoForm.sortCode,
                address,
                fieldWithFocus,
                modifyFieldChanged: (e) => this.modifyFieldChanged(e, 'bankInfoValidator'),
                fieldBlurred: this.fieldFocussedOrBlurred,
                fieldFocussed: this.fieldFocussedOrBlurred,
                bankInfoUpdate: this.bankInfoUpdate,
                processingRequest: processingRequest,
                fetchError,
                validationErrors,
                modelErrors
              }}
            />
          </div>
        )}
        {bankDetailsUpdated && (
          <div className="col-lg-12">
            <WelcomeMessage subtitle={welcomeMessages.bankDetailsUpdated} {...welcomeProps} />
            <CustomerDetailsBankUpdated />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return Object.assign({}, state.bankDetails, {
    bankDetailLoginForm: state.bankDetailLoginForm,
    bankInfoForm: state.bankInfoForm,
    userInterface: state.userInterface
  });
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchErrorOccurred: (err) => dispatch(Actions.fetchErrorOccurredAction(err)),
    modifyField: (req) => dispatch(Actions.modifyPaymentFieldAction(req)),
    fieldFocussedOrBlurred: (req) => dispatch(Actions.fieldFocussedOrBlurredAction(req)),
    bankDetailLogin: (req) => dispatch(Actions.bankDetailLoginAction(req)),
    bankInfoUpdate: (req) => dispatch(Actions.bankInfoUpdateAction(req))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerBankDetailsPage as any));
