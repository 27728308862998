import { FontIcon } from '../common/fontIcon';
import { createAutoRenewalRow, PolicyDetailsCardCommonProps, PolicyDetailsRows } from './documentsPageCommon';

export interface AdministrationCardProps extends PolicyDetailsCardCommonProps {
  policyDetailsRows: PolicyDetailsRows;
}

export function AdministrationCard({
  document,
  policyDetailsRows,
  disableActionButtons,
  index,
  updateAutoRenewalStatus,
  setActiveTab,
  DocumentCard,
  DocumentContentRow,
  viewType,
  featureType
}: AdministrationCardProps): JSX.Element {
  const { policyNumber, stopRenewal } = document;
  const historyKey = 'policy-history-' + policyNumber;
  const { policyNumberRow, policyTermRow, postcodeRow, warningCard } = policyDetailsRows;

  const mainContent = [policyNumberRow, policyTermRow, postcodeRow];

  const autoRenewalRow = createAutoRenewalRow({
    document,
    disableActionButtons,
    updateAutoRenewalStatus,
    setActiveTab,
    DocumentContentRow,
    showToggle: true
  });

  if (autoRenewalRow) {
    mainContent.push(autoRenewalRow);
  }

  const renewalDetailsCard = warningCard || createTimeToRenewCard(stopRenewal);
  const cardElement = DocumentCard({
    id: historyKey,
    index,
    viewType,
    featureType,
    mainContent,
    detailsContent: null,
    messageContent: renewalDetailsCard,
    buttonContent: []
  });

  function createTimeToRenewCard(stopRenewal: boolean): JSX.Element {
    const checkIcon = <FontIcon faIcon="check" />;
    const warningIcon = <FontIcon faIcon="exclamation-triangle" />;
    const warningOrCheckIcon = stopRenewal ? warningIcon : checkIcon;
    let renewalDetailsListText: string[];
    if (stopRenewal) {
      renewalDetailsListText = [
        "Cover will end at the renewal date and you'll no longer be protected",
        'Your policy will not renew automatically unless you get in touch with us'
      ];
    } else {
      renewalDetailsListText = [
        "You'll remain protected for the next policy year",
        "Your policy will renew automatically if you're happy with the price and cover - no need to give us a call",
        "We'll write to you around 30 days before renewal and you'll have an additional 14-day cooling off period to make changes to your policy"
      ];
    }

    const renewalDetailsList = (
      <ul>
        {renewalDetailsListText.map((text, i) => (
          <li key={i}>
            {warningOrCheckIcon}
            <p>{text}</p>
          </li>
        ))}
      </ul>
    );
    return (
      <div id="renew-policy-information-card">
        <span>When it is time to renew:</span>
        {renewalDetailsList}
      </div>
    );
  }

  return cardElement;
}
