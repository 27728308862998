import * as Validators from '../../validators/index';
import { ErrorModel } from '../../validators/modelValidator';
import { StandardDispatchFunction } from '../actionTypes';
import { ModelValidationErrorPayload } from '../modifyFieldAction';

export function modifyPaymentFieldAction(payload: ModifyPaymentFieldActionProps) {
  return (dispatch: StandardDispatchFunction): void => {
    const {
      fieldName,
      value,
      eventType,
      validatorName,
      overdueAmount,
      feeAmount,
      totalPaid,
      totalAmountPayable,
      outstandingBalance,
      modelErrors
    } = payload;
    if (eventType === 'blur' || modelErrors.getError(fieldName) || modelErrors.getWarning(fieldName)) {
      const validator = Validators[validatorName];
      const model = {};
      model[fieldName] = value;
      const options: Record<string, boolean | number | string[]> = { fieldsToValidate: [fieldName] };
      if (fieldName === 'amount') {
        options.overdueAmount = overdueAmount;
        options.feeAmount = feeAmount;
        options.totalPaid = totalPaid;
        options.totalAmountPayable = totalAmountPayable;
        options.outstandingBalance = outstandingBalance;
        options.validateAmount = true;
      }

      if (!validator) {
        throw new Error(`The validator "${validatorName}" is not defined. You need to create one.`);
      }

      const errors = validator(model, options);
      dispatch({
        type: 'PAYMENT_MODEL_VALIDATION_ERROR',
        payload: { errors, mergeErrors: true } as ModelValidationErrorPayload
      });
    }

    dispatch({ type: 'FIELD_CHANGED', payload });
  };
}

export interface ModifyPaymentFieldActionProps {
  fieldName: string;
  value: string | number;
  eventType: string;
  validatorName: string;
  overdueAmount: number;
  feeAmount: number;
  totalPaid: number;
  totalAmountPayable: number;
  outstandingBalance: number;
  modelErrors: ErrorModel;
}
