import { createErrorDetail, ModifyMessagesResponse } from '../common/ErrorSection';
import { FeatureTypes } from './documentsPageCommon';

export interface ErrorSectionProps {
  errors: string[];
  processingRequest: boolean;
  className: string;
  feature: FeatureTypes;
}

export function ErrorSection({ errors, processingRequest, className, feature }: ErrorSectionProps): JSX.Element | null {
  const errorResult = createErrorDetail({
    errors,
    processingRequest,
    modifyMessages: (code) => modifyMessages({ code, feature })
  });
  if (!errorResult) {
    return null;
  }

  const { errors: errorItems, isNegated } = errorResult;
  const errorList = errorItems;
  return (
    <div id="error-section" className={`alert alert-${isNegated ? 'success' : 'danger'} ${className}`}>
      {errorList}
    </div>
  );
}

interface ModifyMessagesProps {
  code: string;
  feature: FeatureTypes;
}

function modifyMessages({ code, feature }: ModifyMessagesProps): ModifyMessagesResponse {
  switch (code) {
    case 'CPS016':
      if (feature === 'claims') {
        return 'We can’t find your policy details. If you can’t log in, call us on 0330 660 0660 to make a claim.';
      }

      return 'Sorry, the details you provided cannot be found.';
    case 'CPS006':
    case 'CPS017':
      return 'Sorry, this link is no longer valid. Please request a new secure link, by entering your details above.';
    case 'CPS018':
      return 'Sorry, there was a problem updating your renewal status. Please call us on 0330 660 1000 so that we can look in to this for you.';
  }

  return undefined;
}
