import { lodash } from '@avantia/lodash';
import { ValidationRule } from './validatorRules';

export interface ProcessRulesOptions {
  fieldsToValidate?: string[];
}

export interface ServerValidationModel {
  count: number;
  isValid: boolean;
  errors: { message: string; propertyName?: string }[];
}

export type ErrorMap = { [name: string]: string | number };

export type ValidationRules = { [name: string]: ValidationRule[] };

export type ModelToValidate = { [name: string]: any };

export interface RulesModel {
  errors: ValidationRules;
  warnings: ValidationRules;
}

export type GetErrorFunction = (name: string, label?: string) => string | undefined;

export type ValidatorFunction = (model: any, options?: ProcessRulesOptions) => ErrorModel;

export interface ErrorModel {
  errors: ErrorMap;
  warnings: ErrorMap;
  count: number;
  getError: GetErrorFunction;
  getWarning: GetErrorFunction;
  getFirstError: () => string | undefined;
}

export interface ModelWithValidation {
  errors: ErrorModel | ServerValidationModel;
  validationErrors: ErrorModel | ServerValidationModel;
}

export function modelValidator(
  model: ModelToValidate,
  rules: RulesModel | ValidationRules | ValidationRule[],
  options?: ProcessRulesOptions
): ErrorModel {
  let errors: ErrorMap, warnings: ErrorMap;
  options = options || {};
  const ruleModel = rules as RulesModel;
  if (ruleModel && ruleModel.errors) {
    errors = processRules(model, ruleModel.errors, options);
    warnings = processRules(model, ruleModel.warnings || {}, options);
    iterateErrors(errors, (e, msg) => {
      // if we have error and warning for the same field, clear the warning
      if (msg && warnings[e]) {
        warnings.count = (warnings.count as number) - 1;
        warnings[e] = '';
      }
    });
  } else {
    errors = processRules(model, rules as ValidationRules | ValidationRule[], options);
    warnings = { count: 0 };
  }

  return createErrorModel(errors, warnings);
}

function getMessage(this: ErrorMap, name: string, label?: string): string {
  const message = this[name];
  if (!message) {
    return '';
  }

  const replaceLabel = ` '${label || name}' `;
  return ('' + message).replace(` ${name} `, replaceLabel).replace(' {name} ', replaceLabel);
}

function processRules(
  model: ModelToValidate,
  rules: ValidationRules | ValidationRule[],
  options: ProcessRulesOptions
): ErrorMap {
  const errors: ErrorMap = {};
  let count = 0;
  const fieldsToValidate = options.fieldsToValidate;
  if (lodash.isArray(fieldsToValidate) && fieldsToValidate.length > 0) {
    for (const propName of fieldsToValidate) {
      count += processRule(model, propName, rules, errors);
    }
  } else {
    for (const propName in rules) {
      count += processRule(model, propName, rules, errors);
    }
  }

  errors.count = count;
  return errors;
}

function processRule(
  model: ModelToValidate,
  propName: string,
  rules: ValidationRules | ValidationRule[],
  errors: ErrorMap
): number {
  let count = 0;
  const modelValue = model[propName];
  const propRules: ValidationRule[] = rules[propName];
  if (lodash.isObject(propRules)) {
    for (const prop in propRules) {
      const propRule: ValidationRule = propRules[prop];
      const message = propRule(modelValue, propName);
      if (!errors[propName]) {
        errors[propName] = message;
        if (message) {
          count++;
          break;
        }
      }
    }
  }

  return count;
}

export function createErrorModel(
  errors: ErrorMap | ServerValidationModel | undefined,
  warnings?: ErrorMap
): ErrorModel {
  warnings = warnings || {};
  let errorMap: ErrorMap | undefined;
  if (errors && (errors as ServerValidationModel).isValid === false) {
    errorMap = convertServerValidationToErrorMap(errors as ServerValidationModel);
  } else {
    errorMap = errors as ErrorMap | undefined;
  }

  errorMap = errorMap || { count: 0 };
  const count: number = (errorMap.count as number) || 0;
  const result: Partial<ErrorModel> = { errors: errorMap, warnings, count };
  result.getError = getMessage.bind(errorMap);
  result.getWarning = getMessage.bind(warnings);
  result.toString = modelToString.bind(result as ErrorModel);
  const errorModel = result as ErrorModel;
  errorModel.getFirstError = getFirstErrorFromModel.bind(errorModel);
  return errorModel;
}

function convertServerValidationToErrorMap(validation: ServerValidationModel): ErrorMap {
  const map: ErrorMap = {};
  let count = 0;
  validation.errors.forEach(({ propertyName, message }) => {
    const key = propertyName || '';
    if (map[key] === undefined) {
      count++;
    }

    map[key] = message;
  });
  map.count = count;
  return map;
}

export function getFirstError(model: ErrorModel | ServerValidationModel): string {
  if (model) {
    const errors = model.errors;
    if ((model as any).getFirstError) {
      return (model as ErrorModel).getFirstError() || '';
    } else if (lodash.isArray(errors)) {
      for (const error of errors) {
        return error.message;
      }
    } else if (errors) {
      lodash.forOwn(errors, (err, key) => {
        if (key !== 'count') {
          return err;
        }
      });
    }
  }

  return '';
}

function getFirstErrorFromModel(this: ErrorModel): string | undefined {
  let error: string | undefined = undefined;
  iterateErrors(this.errors, (name, message) => {
    if (message) {
      error = message;
      return false;
    }
  });
  return error;
}

function modelToString(this: ErrorModel): string {
  const { errors, warnings } = this;
  let res = `Errors: ${errors.count}\n`;
  iterateErrors(errors, (e, msg) => {
    res += `  ${e} => ${msg}\n`;
  });

  res += `Warnings: ${warnings.count}\n`;
  iterateErrors(warnings, (e, msg) => {
    res += `  ${e} => ${msg}\n`;
  });

  return lodash.trim(res);
}

function iterateErrors(errors: ErrorMap, action: (name: string, error: string) => boolean | void): void {
  for (const e in errors) {
    if (e !== 'count') {
      if (action(e, errors[e] as string) === false) {
        break;
      }
    }
  }
}

export function combineErrorModels(a: ErrorModel, b?: ErrorModel): ErrorModel {
  if (!b) {
    return a;
  }

  const combine = (x: ErrorMap, y: ErrorMap) => {
    let count = 0;
    const items = Object.assign({}, x, y);
    delete items.count;
    lodash.forOwn(items, (msg) => {
      if (msg) {
        count++;
      }
    });

    items.count = count;
    return items;
  };

  const errors = combine(a.errors, b.errors);
  const warnings = combine(a.warnings, b.warnings);

  return createErrorModel(errors, warnings);
}
