import { configureDataDogRum } from '@avantia/third-party-monitoring/configureDataDogRum';
import { configureQuantcastConsentManager } from '@avantia/third-party-monitoring/configureQuantcastConsentManager';
import { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { logInfo } from './clientLogging';
import CustomerBankDetailsPage from './components/bankDetails/CustomerBankDetailsPage';
import ErrorPage from './components/common/ErrorPage';
import { Header } from './components/common/Header';
import { HomePage } from './components/common/HomePage';
import ContactPreference from './components/ContactPreference/ContactPreference';
import CustomerPaymentPage from './components/defaulters/CustomerPaymentPage';
import { DocumentsPage } from './components/documents/DocumentsPage';
import EsigDetailsPage from './components/esigCreditagreement/esigDetailsPage';
import { getConfig } from './configuration';

declare const process: any;

const {
  developerModeEnabled,
  environment,
  ddClientToken,
  ddApplicationId,
  ddRumSessionRecordingEnabled,
  customerPortalVersion,
  ddTrackInteractions,
  ddSampleRate,
  ddServiceName,
  quantcastAccountId,
  quantcastConsentManagerEnabled
} = getConfig();

export default class App extends Component<any, any> {
  render(): JSX.Element {
    configureDataDogRum({
      ddApplicationId,
      ddClientToken,
      ddDefaultPrivacyLevel: 'mask-user-input',
      ddRumEnabled: !developerModeEnabled,
      ddRumSessionRecordingEnabled,
      ddSampleRate,
      ddServiceName,
      ddTrackInteractions,
      environment,
      releaseVersion: customerPortalVersion,
      logInfo
    });

    configureQuantcastConsentManager({
      quantcastAccountId,
      quantcastConsentManagerEnabled,
      logInfo
    });

    const docsPath = '/documents(/results)?';
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <div className="App">
          <div className="agg">
            <Switch>
              <Route path="/" component={Header} />
            </Switch>
            <Switch>
              <Route exact path="/" component={HomePage}></Route>
              <Route path="/makeapayment" component={CustomerPaymentPage}></Route>
              <Route path="/updatedetails" component={CustomerBankDetailsPage}></Route>
              <Route path="/contactPreference" component={ContactPreference}></Route>
              <Route path="/esigregistration" component={EsigDetailsPage}></Route>
              <Route path={docsPath} component={DocumentsPage} />
              <Route component={ErrorPage}></Route>
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}
