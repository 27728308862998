import { ServerErrorModel } from '../../server/models';
import { DispatchFunction } from './actionTypes';
import { FormTypePayload } from './documents/FormTypePayload';
import { fetchResolver } from './fetchResolver';

export type LogoffServerResponse = ServerErrorModel;

export function logOffAction(payload: FormTypePayload) {
  return (dispatch: DispatchFunction<LogoffServerResponse>): Promise<void> => {
    const { formType } = payload;
    return fetchResolver<LogoffServerResponse>({ url: '/api/logoff', method: 'POST' }, dispatch, (response) => {
      dispatch({
        type: response && response.success ? 'LOG_OFF_SUCCESS' : 'LOG_OFF_FAILED',
        formType,
        payload: response
      });
    });
  };
}
