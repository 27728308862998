import { CSSProperties } from 'react';
import * as Spinners from 'react-spinners';

export type SpinnerTypes =
  | 'Bar'
  | 'Beat'
  | 'Bounce'
  | 'Circle'
  | 'Clip'
  | 'ClimbingBox'
  | 'Dot'
  | 'Fade'
  | 'Grid'
  | 'Hash'
  | 'Moon'
  | 'Pacman'
  | 'Propagate'
  | 'Pulse'
  | 'Ring'
  | 'Rise'
  | 'Rotate'
  | 'Scale'
  | 'Sync';

export interface SpinnerControlProps {
  visible: boolean;
  positionTopInt?: number;
  positionLeftInt?: number;
  spinnerText?: string;
  typeOfSpinner: SpinnerTypes;
  sizeOfSpinner?: number;
  colorOfSpinner?: string;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  containerClassName?: string;
}

export function SpinnerControl({
  visible,
  positionTopInt,
  positionLeftInt,
  spinnerText,
  typeOfSpinner,
  sizeOfSpinner,
  colorOfSpinner,
  style,
  containerStyle,
  containerClassName
}: SpinnerControlProps): JSX.Element {
  const Spinner = Spinners[typeOfSpinner + 'Loader'];
  return (
    <div className={containerClassName} style={containerStyle || { textAlign: 'center', marginTop: '20px' }}>
      <Spinner
        css={
          style || {
            position: 'fixed',
            top: positionTopInt ? `${positionTopInt}%` : '50%',
            left: `${positionLeftInt}%`
          }
        }
        sizeUnit={'px'}
        size={sizeOfSpinner || 55}
        color={colorOfSpinner || '#337ab7'}
        loading={visible}
      />
      {visible && spinnerText && (
        <div style={{ textAlign: 'center' }}>
          <p>{spinnerText}</p>
        </div>
      )}
    </div>
  );
}
