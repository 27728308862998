import { HeaderComponent } from '../common/Header';
import { LogOffFunc } from './documentsPageCommon';

export function Header(logOff: LogOffFunc | undefined): JSX.Element {
  const logoffElement = (
    <div id="logOff" className="container">
      <div className="row">
        <div className="col-12">
          <span className="btn btn-link pull-right" onClick={logOff}>
            Log out
          </span>
        </div>
      </div>
    </div>
  );
  return (
    <HeaderComponent
      key="header"
      homeLink="/documents"
      userName=""
      navigation={logOff ? logoffElement : undefined}
      applyNoStyle={true}
    />
  );
}
