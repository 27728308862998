import { createErrorModel, ErrorModel } from '../../validators/modelValidator';
import { DispatchFunction, FormTypes } from '../actionTypes';
import { fetchResolver2 } from '../fetchResolver';
import { ModelValidationErrorPayload } from '../modifyFieldAction';
import { FormTypePayload } from './FormTypePayload';
import {
  DocumentSummaryItem,
  FailedToGetDocumentSummaryResponsePayload,
  GetDocumentSummaryServerResponse,
  processDocumentSummaryResponse
} from './getDocumentSummaryUsingAuthLinkAction';

export interface UpdateAutoRenewalStatusPayload extends UpdateAutoRenewalStatusActionProps {
  formType: FormTypes;
  modelErrors: ErrorModel;
}

export interface UpdateAutoRenewalStatusActionProps {
  isAutoRenewal: boolean;
  document: DocumentSummaryItem;
}

export interface UpdateAutoRenewalStatusServerResponse extends GetDocumentSummaryServerResponse {
  autoRenewalUpdated: boolean;
  success: boolean;
}

export interface UpdateAutoRenewalStatusResponsePayload {
  policyDetailsId: string;
}

export interface FailedToUpdateAutoRenewalStatusResponsePayload extends ModelValidationErrorPayload {
  policyDetailsId: string;
}

export type UpdateAutoRenewalStatusDispatchTypes =
  | GetDocumentSummaryServerResponse
  | FailedToUpdateAutoRenewalStatusResponsePayload
  | FailedToGetDocumentSummaryResponsePayload;

export function updateAutoRenewalStatusAction(payload: UpdateAutoRenewalStatusPayload & FormTypePayload) {
  return (dispatch: DispatchFunction<UpdateAutoRenewalStatusDispatchTypes>): void => {
    const { isAutoRenewal, formType, document } = payload;
    const {
      policyDetailsId,
      historyId,
      emailAddress,
      title,
      forename,
      surname,
      effectiveDate,
      endDate,
      phoneNumber,
      altPhoneNumber,
      riskPostCode,
      riskAddress,
      policyNumber
    } = document;
    const data = {
      policyDetailsId,
      historyId,
      isAutoRenewal,
      emailAddress,
      title,
      forename,
      surname,
      effectiveDate,
      endDate,
      phoneNumber,
      altPhoneNumber,
      riskPostCode,
      riskAddress,
      policyNumber
    };

    const url = `/api/documents/updateAutoRenewalStatus`;
    dispatch({ type: 'UPDATE_RENEWAL_STATUS_IN_PROGRESS', formType });
    fetchResolver2<UpdateAutoRenewalStatusServerResponse, UpdateAutoRenewalStatusDispatchTypes>(
      { url, data, method: 'POST' },
      dispatch,
      (response) => {
        const { autoRenewalUpdated } = response;
        if (autoRenewalUpdated === false) {
          const payloadData: FailedToUpdateAutoRenewalStatusResponsePayload = {
            errors: createErrorModel(response.errors),
            mergeErrors: false,
            policyDetailsId
          };
          dispatch({
            type: 'UPDATE_RENEWAL_STATUS_FAILED',
            formType,
            payload: payloadData
          });
        } else {
          processDocumentSummaryResponse({ response, formType, dispatch });
        }
      }
    );
  };
}
