import { NavLink } from 'react-router-dom';
import { IconTypes } from '../iconTypes';
import { ExternalLink } from './ExternalLink';
import { FontIcon } from './fontIcon';

export interface HomePageSection {
  id: string;
  title: string;
  description: string;
  icon: IconTypes;
  linkUrl?: string;
  navLinkTo?: string;
}

export function HomePageSection({ id, title, description, icon, linkUrl, navLinkTo }: HomePageSection): JSX.Element {
  let linkContent: JSX.Element;

  const activecard = (
    <div id={id} className="panel panel-aj active-card homepage-card">
      <div className="panel-heading" role="tab" id="headingOne">
        <article className="media">
          <div className="media-left">
            <FontIcon icon={icon} />
          </div>
          <div className="media-body">
            <h3>{title}</h3>
            <div className="media-content">
              <p>{description}</p>
            </div>
          </div>
        </article>
      </div>
    </div>
  );

  if (navLinkTo) {
    linkContent = (
      <NavLink to={navLinkTo} style={{ textDecoration: 'none', borderBottom: 'none' }}>
        {activecard}
      </NavLink>
    );
  } else {
    linkContent = (
      <ExternalLink
        id="homepage-link"
        href={linkUrl as string}
        style={{ textDecoration: 'none', borderBottom: 'none' }}
        content={activecard}
      />
    );
  }

  return linkContent;
}
