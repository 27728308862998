import { CSSProperties } from 'react';
import { NavLink } from 'react-router-dom';
import { getConfig } from '../../configuration';

const { assetsUrl, logoPath } = getConfig();
const logoSrc = assetsUrl + logoPath;

export interface HeaderLogoProps {
  userName: string | undefined;
  homeLink: string;
  headerStyle?: CSSProperties;
  buttons?: JSX.Element | JSX.Element[];
  applyNoStyle?: boolean;
}

export function HeaderLogo({ userName, homeLink, headerStyle, buttons, applyNoStyle }: HeaderLogoProps): JSX.Element {
  const noStyle = applyNoStyle ? {} : undefined;
  return (
    <header style={noStyle || headerStyle || { marginBottom: '20px' }}>
      <section className="header row">
        <div className="pull-left col-6">
          <NavLink to={homeLink} style={noStyle || { borderBottom: 'none' }}>
            <img aria-label="Homeprotect" src={logoSrc} className="logo-updated" alt="Homeprotect" width="200" />
          </NavLink>
          {userName && (
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Logged in as <strong>{userName}</strong>
            </span>
          )}
        </div>
        {buttons && <section className="pull-right col-6">{buttons}</section>}
      </section>
    </header>
  );
}
