import { combineReducers } from 'redux';
import { bankDetailLoginFormReducer } from './bankDetailLoginFormReducer';
import { bankDetailsReducer } from './bankDetailsReducer';
import { bankInfoFormReducer } from './bankInfoFormReducer';
import { documentsReducer } from './documentsReducer';
import { esigDetailLoginFormReducer } from './esigDetailLoginFormReducer';
import { esigDetailsReducer } from './esigDetailsReducer';
import { paymentFormReducer } from './paymentFormReducer';
import { paymentReducer } from './paymentReducer';
import { userInterfaceReducer } from './userInterfaceReducer';

const rootReducer = combineReducers({
  bankDetails: bankDetailsReducer,
  bankDetailLoginForm: bankDetailLoginFormReducer,
  payment: paymentReducer,
  paymentForm: paymentFormReducer,
  userInterface: userInterfaceReducer,
  bankInfoForm: bankInfoFormReducer,
  esigDetails: esigDetailsReducer,
  esigDetailLoginForm: esigDetailLoginFormReducer,
  documents: documentsReducer
});

export { rootReducer };
