import { lodash } from '@avantia/lodash';
import { StandardAction } from '../actions/actionTypes';
import { ModelWithValidation } from '../validators/modelValidator';
import { getInitialState } from './initialState';
import { applyChanges } from './reducerLibrary';
import { BankDetailsState } from './stateTypes';

const initialState = getInitialState().bankDetails;

export function bankDetailsReducer(state: BankDetailsState = initialState, action: StandardAction): BankDetailsState {
  switch (action.type) {
    case 'LOGIN_DETAIL_FAILURE':
      return (() => {
        const { errors, validationErrors } = action.payload as ModelWithValidation;
        return applyChanges(state, { validationErrors: validationErrors || errors });
      })();

    case 'LOGIN_DETAIL_SUCCESS':
      return (() => {
        const payload = action.payload;
        const { house, street, county, city, postcode, country } = payload;
        const address = { house, street, county, city, postcode, country };
        const data: Partial<BankDetailsState> = { address };
        lodash.forOwn(initialState, (_, key) => {
          if (payload[key] !== undefined) {
            data[key] = payload[key];
          }
        });
        return applyChanges(state, data);
      })();

    case 'CLEAR_ALL_FORM_DATA':
    case 'LOG_OFF_SUCCESS':
      return applyChanges(state, {
        postcode: '',
        policyNumber: '',
        accountHolder: '',
        accountNumber: '',
        sortCode: '',
        address: {},
        validationErrors: undefined
      });

    case 'PAYMENT_MODEL_VALIDATION_ERROR':
    case 'FIELD_CHANGED':
      return applyChanges(state, { validationErrors: undefined });

    case 'BANK_INFO_UPDATED':
      return (() => {
        const { accountNumber, sortCode, accountHolderName, validationErrors, errors } = action.payload;
        if (accountNumber && sortCode) {
          return applyChanges(state, {
            accountNumber,
            sortCode,
            accountHolder: accountHolderName,
            validationErrors: undefined
          });
        }

        return applyChanges(state, { validationErrors: errors || validationErrors });
      })();

    default:
      break;
  }

  return state;
}
