import { ErrorModel, ModelToValidate, modelValidator, ProcessRulesOptions } from './modelValidator';
import * as Rules from './validatorRules';

function bankInfoValidator(model: ModelToValidate, options: ProcessRulesOptions | undefined): ErrorModel {
  options = options || {};
  const warnings = {};
  const errors = {
    accountHolder: [Rules.RequiredRule, Rules.createAccountHolderLengthRule()],
    accountNumber: [Rules.RequiredRule, Rules.NumericRule, Rules.createLengthRule(8, 8)],
    sortCode: [Rules.RequiredRule, Rules.createRegexRule(/^\d{2}-?\d{2}-?\d{2}$/, 'The {name} is not valid')]
  };

  return modelValidator(model, { errors, warnings }, options);
}

export { bankInfoValidator };
