import { HpEither } from '@avantia/logging-and-errors';
import { FetchError } from '../actions/actionTypes';
import { ErrorMap, ErrorModel, ServerValidationModel } from '../validators/modelValidator';

export function getErrorsAsArray(
  modelErrors: ErrorModel | ServerValidationModel,
  fetchError: HpEither<FetchError, string> | undefined
): string[] {
  const errors: string[] = [];
  const errorModel = modelErrors as ErrorModel;
  const validation = modelErrors as ServerValidationModel;
  if (errorModel && validation.isValid === undefined && errorModel.count > 0) {
    const errs: ErrorMap = errorModel.errors;
    for (const name in errs) {
      if (name !== 'count') {
        errors.push(errs[name] as string);
      }
    }
  }

  errors.push(validation && validation.isValid === false ? validation.errors[0].message || '' : '');
  if (fetchError) {
    let message: string = (fetchError as FetchError).message;
    if (!message) {
      message = fetchError as string;
    }

    errors.push(message);
  }

  return errors.filter((e) => !!e);
}
