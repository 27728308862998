import { StandardDispatchFunction } from './actionTypes';
import { FetchOptions, fetchResolver } from './fetchResolver';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function esigConfirmedAction({ premfinaEsigID }: EsigConfirmedActionProps) {
  const body = { premfinaEsigID };
  return (dispatch: StandardDispatchFunction): Promise<void> => {
    const options: FetchOptions = {
      url: '/api/esigConfirmed',
      method: 'POST',
      data: body,
      headers: { 'Content-Type': 'application/json' }
    };
    return fetchResolver(options, dispatch, (payload) => {
      dispatch({ type: 'ESIG_CONFIRMED', payload });
    });
  };
}

export interface EsigConfirmedActionProps {
  premfinaEsigID: string;
}
