import { FontIcon } from '../common/fontIcon';
import { IconTypes } from '../iconTypes';

export interface IconFieldRowControlParams {
  icon?: IconTypes;
  content: any;
  width: number | undefined;
  className?: string;
}

const IconFieldRowControl = ({ icon, content, width, className }: IconFieldRowControlParams): JSX.Element => {
  return (
    <article className={`media ${className}`}>
      <div className="media-left">{icon && <FontIcon icon={icon} width={width} />}</div>
      <div className="media-body">
        <div className="media-content">{content}</div>
      </div>
    </article>
  );
};

export { IconFieldRowControl };
