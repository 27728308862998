import { ReactElement } from 'react';
import { IconTypes } from '../iconTypes';
import { FontIcon } from './fontIcon';

export interface PanelCardProps {
  header: ReactElement | string;
  content: ReactElement;
  leftIcon: IconTypes;
  additionalHeaderClass?: string;
}

export function PanelCard({ header, content, leftIcon, additionalHeaderClass }: PanelCardProps): JSX.Element {
  return (
    <div className={`panel-aj active-card  panel panel-default ${additionalHeaderClass}`}>
      <div className="panel-heading">
        <div className="panel-title media">
          <div className="icons-panel media-left">
            <FontIcon icon={leftIcon} width={3} />
          </div>
          <div className="media-body">
            <h2 className="media-heading">{header}</h2>
            <div className="media-content" style={{ marginTop: '10px' }}>
              {content}
            </div>
          </div>
          <div className="edit-panel media-right" />
        </div>
      </div>
      <div className="panel-collapse collapse" aria-hidden="true" />
    </div>
  );
}
