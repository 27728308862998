import queryString from 'query-string';
import { loginPolicyPostcodeValidator } from '../validators/loginPolicyPostcodeValidator';
import { ModelWithValidation } from '../validators/modelValidator';
import { ActionTypes, StandardDispatchFunction } from './actionTypes';
import { fetchResolver } from './fetchResolver';

export interface ResponseBankDetailLoginPayload extends ModelWithValidation {
  policyNumber: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function bankDetailLoginAction({ policyNumber, postcode }: BankDetailLoginActionProps) {
  return (dispatch: StandardDispatchFunction): Promise<void> => {
    const query = {
      policyNumber,
      postcode
    };
    const errors = loginPolicyPostcodeValidator(query);
    if (errors.count > 0) {
      /*return*/ dispatch({ type: 'LOGIN_MODEL_VALIDATION_ERROR' as ActionTypes, payload: { errors } });
    } else {
      dispatch({ type: 'LOGIN_MODEL_VALIDATION_ERROR' as ActionTypes, payload: { errors } }); // clear the errors
    }

    const queryText = queryString.stringify(query);
    const url = `/api/bankdetail?${queryText}`;
    return fetchResolver<ResponseBankDetailLoginPayload>({ url }, dispatch, (payload) => {
      const { validationErrors } = payload;
      if ((!validationErrors || validationErrors.count === 0) && payload.policyNumber) {
        dispatch({ type: 'LOGIN_DETAIL_SUCCESS', payload });
      } else {
        dispatch({ type: 'LOGIN_DETAIL_FAILURE', payload });
      }
    });
  };
}

export interface BankDetailLoginActionProps {
  policyNumber: string;
  postcode: string;
}
