import TagManager, { DataLayerArgs } from 'react-gtm-module';
import { logInfo } from '../../clientLogging';
import { getConfig } from '../../configuration';
import { SourceTypes } from './documentsPageCommon';

const { environment } = getConfig();
const debugMode = ['developer', 'dev'].indexOf(environment) >= 0;
let prevAnalyticJson: string;
let timerId: NodeJS.Timeout;

export type GtmEventTypes =
  | 'Summary Screen'
  | 'Login Screen'
  | 'No Data Screen'
  | 'Auth Link Emailed Screen'
  | 'Auth Link Landing Screen'
  | 'Email Documents Click'
  | 'Download Documents Click'
  | 'Open Quote Click'
  | 'Start a Claim Click'
  | 'Auto Renewal Click'
  | 'Log Off Click';

export interface AnalyticsPageProps {
  emailAddress?: string;
  postcode?: string;
  source?: SourceTypes;
}

export interface SendAnalyticsProps {
  props: AnalyticsPageProps;
  event: GtmEventTypes;
  checkRepeats?: boolean; // if this is false, the previous page wont be added after this event.
  detail?: any;
  delay?: number;
  onlyWhen?: (props: DataLayerProps) => boolean;
}

export interface DataLayerProps {
  event: GtmEventTypes;
  pagePath: string;
  detail?: any;
  pageTitle?: string;
  emailAddress?: string;
  postcode?: string;
}

export function sendAnalytics({ props, event, checkRepeats, detail, delay, onlyWhen }: SendAnalyticsProps): void {
  const sender = () => {
    const { emailAddress, postcode } = props;
    const pagePath = document.location.href;
    const dataLayer: DataLayerProps = {
      event,
      pagePath
    };

    if (detail) {
      dataLayer.detail = detail;
    }

    if (emailAddress && postcode) {
      dataLayer.emailAddress = emailAddress;
      dataLayer.postcode = postcode;
    }

    const heading = document.getElementsByTagName('h1')[0];
    if (heading) {
      dataLayer.pageTitle = heading.innerText;
    }

    const args: DataLayerArgs = {
      dataLayer
    };

    if (onlyWhen && onlyWhen(dataLayer) !== true) {
      return;
    }

    const forLogging = JSON.stringify({ type: 'analytics', ...args }, null, 2);
    if (checkRepeats !== false) {
      checkRepeats = true;
    }

    if (!checkRepeats || prevAnalyticJson !== forLogging) {
      if (checkRepeats) {
        prevAnalyticJson = forLogging;
      }

      if (debugMode) {
        logInfo(forLogging);
      }

      TagManager.dataLayer(args);
    }
  };

  if (timerId) {
    clearTimeout(timerId);
  }

  if (delay) {
    timerId = setTimeout(sender, delay);
  } else {
    sender();
  }
}
