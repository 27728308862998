import jQuery from 'jquery';
import 'react-app-polyfill/ie11';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { logInfo } from './clientLogging';
import { getConfig } from './configuration';
import reportWebVitals from './reportWebVitals';
import { configureStore } from './store/configureStore';

declare const window: any;
window.jQuery = window.jQuery || jQuery;
window.$ = window.jQuery;

logInfo(JSON.stringify(getConfig() as any, null, 2));

const store = configureStore();
const container = document.getElementById('root') as HTMLElement;
const root = ReactDOMClient.createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
