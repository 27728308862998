import { CSSProperties } from 'react';
import { IconTypes } from '../iconTypes';
import { IconFieldRowControl } from './IconFieldRowControl';

export function ReadOnlyTextControl({
  id,
  className,
  label,
  value,
  prefixLabel,
  icon,
  width,
  isSlim,
  readOnlyParagraphStyle,
  isMaskingEnabled
}: ReadOnlyTextControlProps): JSX.Element {
  icon = icon || 'service-docs';

  const content = (
    <div className={className}>
      <h2 className="input-label">{label}</h2>
      <p id={id} style={readOnlyParagraphStyle} data-dd-privacy={isMaskingEnabled ? 'mask' : 'allow'}>
        {prefixLabel}
        {value}
      </p>
    </div>
  );

  if (isSlim) {
    return content;
  }

  return <IconFieldRowControl icon={icon} width={width} content={content} className={'icon-middle'} />;
}

export interface ReadOnlyTextControlProps {
  id: string;
  className?: string;
  readOnlyParagraphStyle?: CSSProperties;
  label: string;
  value: any;
  prefixLabel?: string;
  icon?: IconTypes;
  width?: number;
  isSlim?: boolean;
  isMaskingEnabled?: boolean;
}
