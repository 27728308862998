import { CSSProperties } from 'react';
import { FormStyleTypes, getControlClassName } from '../styleLibrary';

export function ButtonControl({
  id,
  name,
  label,
  onClick,
  disabled,
  type,
  style,
  formStyle,
  buttonClass
}: ButtonControlProps): JSX.Element {
  const className = buttonClass || getControlClassName(formStyle, 'ButtonControl');
  return (
    <button
      id={name || id}
      name={name}
      onClick={onClick as any}
      disabled={disabled}
      type={type || 'button'}
      style={style}
      className={className}>
      {label}
    </button>
  );
}

export interface ButtonControlProps {
  id?: string;
  name: string;
  label: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClick: Function;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  style?: CSSProperties;
  formStyle?: FormStyleTypes;
  buttonClass?: string;
}
