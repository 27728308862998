/* eslint-disable @typescript-eslint/ban-types */
import { CSSProperties } from 'react';
import { FormControl } from 'react-bootstrap';
import { ErrorModel } from '../../validators/modelValidator';
import { FormStyleTypes, getControlClassName, getControlStyle } from '../styleLibrary';

export interface TextInputControlParams {
  label: string;
  name: string;
  value: string;
  type?: string;
  onChange: Function;
  onFocus?: Function;
  onBlur?: Function;
  prefixLabel?: any;
  placeholder?: string;
  button?: any;
  ariaLabel?: string;
  modelErrors: ErrorModel;
  fieldWithFocus?: string;
  width?: number;
  style?: CSSProperties;
  errorStyle?: CSSProperties;
  formStyle?: FormStyleTypes;
  boxClass?: string;
  inputClass?: string;
  autoComplete?: boolean;
}

export function TextInputControl({
  label,
  name,
  value,
  type,
  onChange,
  onFocus,
  onBlur,
  prefixLabel,
  placeholder,
  button,
  ariaLabel,
  modelErrors,
  fieldWithFocus,
  formStyle,
  boxClass,
  inputClass,
  autoComplete
}: TextInputControlParams): JSX.Element {
  const hasError = !!modelErrors.getError(name, label);
  const autoFocus = name === fieldWithFocus;
  const style = getControlStyle(formStyle, 'TextInputControl', hasError);
  const className = inputClass || getControlClassName(formStyle, 'TextInputControl');
  return (
    <div className={boxClass === undefined ? 'w-100' : boxClass}>
      <div className="">
        <label>{label}</label>
        <div className="input-group-prepend">
          {prefixLabel && <span className="input-group-text">{prefixLabel}</span>}
          <FormControl
            id={name}
            name={name}
            type={type || 'text'}
            value={value}
            onChange={onChange as any}
            onBlur={
              ((e) => {
                onChange(e);
                if (onBlur && onBlur !== onChange) {
                  onBlur(e);
                }
              }) as any
            }
            onFocus={onFocus as any}
            autoComplete={autoComplete !== true ? 'off' : 'on'}
            placeholder={placeholder}
            aria-label={ariaLabel}
            style={style}
            className={className}
            autoFocus={autoFocus}
          />
          {button}
        </div>
      </div>
      <div
        style={{
          paddingTop: '.5em',
          boxSizing: 'content-box'
        }}></div>
    </div>
  );
}
