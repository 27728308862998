import { lodash } from '@avantia/lodash';
import { AuthTypes, FeatureTypes, SourceTypes } from '../../components/documents/documentsPageCommon';
import { createErrorModel, ErrorModel, ServerValidationModel } from '../../validators/modelValidator';
import { ActionTypes, DispatchFunction, FormTypes } from '../actionTypes';
import { fetchResolver2 } from '../fetchResolver';
import { ModelValidationErrorPayload } from '../modifyFieldAction';

export interface GetDocumentSummaryPayload {
  formType: FormTypes;
  emailAddress: string;
  postcode: string;
  fromSession: string;
  modelErrors: ErrorModel;
}

export type DocumentTypeCodes = 'NB' | 'MTA' | 'RNW' | 'CN';

export interface DocumentSummaryItem {
  title: string;
  forename: string;
  surname: string;
  policyNumber: string;
  documentArchiveId: number;
  policyDetailsId: string;
  historyId: number;
  documentTemplateId: number;
  documentName: string;
  documentSentDate: Date;
  policyInceptionDate: Date;
  effectiveDate: Date;
  dateOfBirth: Date;
  policyStatusId: string;
  riskPostCode: string;
  riskAddress: string;
  productName: string;
  policyBookletUri: string;
  displaySortOrder: number;
  useForSingleEntry: boolean;
  emailAddress: string;
  phoneNumber: string;
  altPhoneNumber: string;
  stopRenewal: boolean;
  allowPolicyMaintenanceBool: boolean;
  cardNumber: string;
  accountNumber: string;
  renewalDisplayWarning: string;
  endDate: Date;
  typeCode: DocumentTypeCodes;
  childItems?: DocumentSummaryItem[];
}

export interface SavedQuoteItem {
  agreementId: string;
  title: string;
  surname: string;
  forename: string;
  affiliateId: number;
  affiliateDisplayName: string;
  affiliateBranchCode: string;
  quoteReference: string;
  policyNumber: string;
  dateSaved: Date;
  quoteStartDate: Date;
  quotePrice: number;
  quoteStatus: string;
  coverType: string;
  riskPostCode: string;
  quoteLink: string;
}

export interface GetDocumentSummaryServerResponse {
  emailAddress: string;
  postcode: string;
  tab: FeatureTypes;
  source: SourceTypes;
  showClaims: boolean;
  documents: DocumentSummaryItem[];
  savedQuotes: SavedQuoteItem[];
  documentCount: number;
  savedQuoteCount: number;
  errors: ServerValidationModel;
}

export type GetDocumentSummaryResponsePayload = GetDocumentSummaryServerResponse;

export type FailedToGetDocumentSummaryResponsePayload = ModelValidationErrorPayload;

export type DispatchSummaryFunction = DispatchFunction<
  GetDocumentSummaryResponsePayload | FailedToGetDocumentSummaryResponsePayload
>;

export interface GetDocumentSummaryUsingAuthLinkPayload {
  formType: FormTypes;
  authToken: string;
  authType: AuthTypes;
  tab: FeatureTypes;
  source: SourceTypes;
}

export function getDocumentSummaryUsingAuthLinkAction(payload: GetDocumentSummaryUsingAuthLinkPayload) {
  return (dispatch: DispatchSummaryFunction): void => {
    const { authToken, formType, tab, source, authType } = payload;
    if (authToken) {
      let tabSource = lodash.trim(`/${tab}/${source}`, '/');
      if (tabSource) {
        tabSource = `/${tabSource}`;
      }

      dispatch({ type: 'GET_DOCUMENTS_USING_AUTH_LINK_IN_PROGRESS', formType });
      const authTypeUrlParam = authType === 'pass-back' ? 'passbacklink' : 'authlink';
      const url = `/api/documents/${authTypeUrlParam}/${encodeURIComponent(authToken)}${tabSource}`;
      getDocumentSummaryUsingAuthLink(url, formType, dispatch);
    } else {
      getDocumentSummaryUsingAuthLink(
        '/api/documents/sessionauthlink',
        formType,
        dispatch,
        'REQUEST_DOCUMENT_SUMMARY_FROM_SESSION_FAILED'
      );
    }
  };
}

export function getDocumentSummaryUsingAuthLink(
  url: string,
  formType: FormTypes,
  dispatch: DispatchSummaryFunction,
  failedType?: ActionTypes
): Promise<void> {
  return fetchResolver2<GetDocumentSummaryServerResponse, FailedToGetDocumentSummaryResponsePayload>(
    { url },
    dispatch,
    (response) => {
      processDocumentSummaryResponse({ response, formType, dispatch, failedType });
    }
  );
}

export interface ProcessDocumentSummaryResponseProps {
  response: GetDocumentSummaryServerResponse;
  formType: FormTypes;
  dispatch: DispatchSummaryFunction;
  failedType?: ActionTypes;
}

export function processDocumentSummaryResponse({
  response,
  formType,
  dispatch,
  failedType
}: ProcessDocumentSummaryResponseProps): Promise<void> | void {
  const { errors } = response;
  if (!errors || errors.isValid) {
    const payloadData: GetDocumentSummaryResponsePayload = response;
    if ((payloadData as any).success === false) {
      return;
    } else {
      dispatch({ type: 'REQUEST_DOCUMENT_SUMMARY_SUCCESS', formType, payload: payloadData });
    }
  } else {
    const payloadData: FailedToGetDocumentSummaryResponsePayload = {
      errors: createErrorModel(response.errors),
      mergeErrors: false
    };
    dispatch({ type: failedType || 'REQUEST_DOCUMENT_SUMMARY_FAILED', formType, payload: payloadData });
  }
}
