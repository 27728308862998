import { FetchError } from '../../actions/actionTypes';

const SystemErrorControl = ({ fetchError }: SystemErrorControlProps): JSX.Element | null => {
  if (!fetchError || !fetchError.message || fetchError.isStripe === true) {
    return null;
  }

  return (
    <div>
      <div>
        <p>&nbsp;</p>
        <p>{fetchError.message}</p>
      </div>
      <div
        style={{
          paddingTop: '.5em',
          boxSizing: 'content-box'
        }}></div>
    </div>
  );
};

interface SystemErrorControlProps {
  fetchError: FetchError;
}

export default SystemErrorControl;
