import { DocumentCardProps } from '../documentsPageCommon';

export function DocumentCard({
  id,
  mainContent,
  buttonContent,
  detailsContent,
  index,
  viewType,
  featureType,
  messageContent: renewalDetailsCard
}: DocumentCardProps): JSX.Element {
  return (
    <div
      key={`doc-card-${viewType}-${index}`}
      className={`card container ${featureType}-${viewType} ${viewType}-view w-100`}>
      <div className="row">
        <div className="col-12">
          <div className="container detail w-100" id={id}>
            <div className="row">
              <div className="col-8 col-md-7 col-lg-8">{mainContent}</div>
              <div className="col-4 col-md-5 col-lg-4 buttons">
                <div className="w-100">{buttonContent}</div>
              </div>
              {renewalDetailsCard && <div className="col-12">{renewalDetailsCard}</div>}
            </div>
          </div>
        </div>
      </div>
      {detailsContent}
    </div>
  );
}
