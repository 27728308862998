import { sortCode as sortCodeParser } from '../parsers/index';

export function sortCodeFormatter(sortCode: string, allowMasked: boolean): string {
  let parsedSortCode = sortCodeParser(sortCode);
  if (allowMasked && !parsedSortCode && sortCode.substr(0, 2) === '**') {
    parsedSortCode = sortCode;
  }

  if (parsedSortCode) {
    sortCode = parsedSortCode.substr(0, 2) + '-' + parsedSortCode.substr(2, 2) + '-' + parsedSortCode.substr(4, 2);
  }

  return sortCode;
}
