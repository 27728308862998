import { StandardAction } from '../actions/actionTypes';
import { FetchErrorAction } from '../actions/fetchResolver';
import { ModelValidationErrorPayload } from '../actions/modifyFieldAction';
import { combineErrorModels, createErrorModel } from '../validators/modelValidator';
import { getInitialState } from './initialState';
import { applyChanges } from './reducerLibrary';
import { UserInterfaceState } from './stateTypes';

const minMsToShowError = 10 * 1000;
const initialState = getInitialState().userInterface;

export function userInterfaceReducer(
  state: UserInterfaceState = initialState,
  action: StandardAction
): UserInterfaceState {
  switch (action.type) {
    case 'FETCH_ERROR_OCCURRED':
    case 'SCRIPT_ERROR_OCCURRED': {
      // eslint-disable-next-line prefer-const
      let { fetchError, isStripe, message } = action as FetchErrorAction;
      message =
        message ||
        (fetchError && (fetchError as any).message) ||
        (fetchError ? fetchError : undefined) ||
        'An unexpected error has occurred.';
      return applyChanges(state, {
        fetchError: { error: fetchError, isStripe: isStripe || false, message, timestamp: new Date().getTime() },
        ajaxCallsInProgress: 0,
        processingRequest: false
      });
    }

    case 'AJAX_CALL_STARTED':
      return (() => {
        const ajaxCallsInProgress = state.ajaxCallsInProgress + 1;
        return applyChanges(state, { ajaxCallsInProgress, processingRequest: ajaxCallsInProgress > 0 });
      })();

    case 'AJAX_CALL_COMPLETED':
      return (() => {
        let fetchError = state.fetchError as any;
        let ajaxCallsInProgress = state.ajaxCallsInProgress - 1;
        if (ajaxCallsInProgress < 0) {
          // 0 only possible in the case of a fetch error.
          ajaxCallsInProgress = 0;
        }

        if (fetchError && fetchError.timestamp + minMsToShowError < new Date().getTime()) {
          fetchError = null;
        }

        return applyChanges(state, {
          ajaxCallsInProgress,
          processingRequest: ajaxCallsInProgress > 0,
          fetchError
        });
      })();

    case 'REQUEST_EMAIL_DOCUMENTS_FAILED':
    case 'REQUEST_DOCUMENT_SUMMARY_FAILED':
    case 'PAYMENT_MODEL_VALIDATION_ERROR':
    case 'ESIG_MODEL_VALIDATION_ERROR':
    case 'DOCUMENTS_SET_ACTION_MESSAGE':
    case 'DOCUMENTS_LOGIN_MODEL_VALIDATION_ERROR':
    case 'EMAIL_AUTH_LINK_IN_PROGRESS':
    case 'GET_DOCUMENTS_USING_AUTH_LINK_IN_PROGRESS':
    case 'UPDATE_RENEWAL_STATUS_FAILED':
    case 'EMAIL_AUTH_LINK_FAILED':
    case 'LOG_OFF_FAILED':
      return (() => {
        // This call is invoked to clear as well as set errors.
        // eslint-disable-next-line prefer-const
        let { errors, mergeErrors } = (action.payload || {}) as ModelValidationErrorPayload;
        const { modelErrors } = state;
        if (mergeErrors === true) {
          errors = combineErrorModels(modelErrors, errors);
        }

        return applyChanges(state, {
          modelErrors: errors || createErrorModel(undefined),
          fetchError: undefined
        });
      })();

    case 'REQUEST_EMAIL_DOCUMENTS_SUCCESS':
    case 'REQUEST_DOCUMENT_SUMMARY_SUCCESS':
      return applyChanges(state, {
        modelErrors: createErrorModel(undefined),
        fetchError: undefined,
        fieldWithFocus: undefined
      });

    case 'FIELD_FOCUSSED_ACTION':
      return applyChanges(state, { fieldWithFocus: action.payload.fieldName });

    case 'FIELD_BLURRED_ACTION':
      return applyChanges(state, { fieldWithFocus: undefined });

    case 'CLEAR_ALL_FORM_DATA':
      return applyChanges(initialState, { user: state.user });

    case 'RETRIEVED_PAYMENT_TOKEN':
    case 'VOID_UPDATE_AMOUNT_ACTION': {
      const { retrievePaymentTokenCount } = state;
      return applyChanges(state, {
        fieldWithFocus: retrievePaymentTokenCount === 0 ? '' : 'cardholderName',
        retrievePaymentTokenCount: retrievePaymentTokenCount + 1
      });
    }

    case 'CLEAR_FIELD_FOCUS':
      return applyChanges(state, { fieldWithFocus: '' });

    case 'RECEIVE_AUTHENTICATED_USER': {
      const { success, user } = action.payload;
      if (success) {
        return applyChanges(state, { user });
      }

      break;
    }

    case 'ESIG_LOGIN_ERROR': {
      const { fetchError, isStripe } = action as FetchErrorAction;
      const { errors } = action.payload;
      let message = '';
      errors.forEach((err: string) => {
        message += err;
      });

      return applyChanges(state, {
        fetchError: { error: fetchError, isStripe: isStripe || false, message, timestamp: new Date().getTime() },
        ajaxCallsInProgress: 0,
        processingRequest: false
      });
    }

    case 'LOG_OFF_SUCCESS':
      return initialState;

    default:
      break;
  }

  return state;
}

export default userInterfaceReducer;
