import { createUri, parseUri } from '@avantia/client-and-server-utilities';
import { default as cookies } from 'js-cookie';
import queryString from 'query-string';
import React, { BaseSyntheticEvent, CSSProperties } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MessageTypes } from '../../actions/actionTypes';
import { EmailDocumentsPayload } from '../../actions/documents/emailDocumentsAction';
import { FormTypePayload } from '../../actions/documents/FormTypePayload';
import {
  DocumentSummaryItem,
  DocumentTypeCodes,
  GetDocumentSummaryUsingAuthLinkPayload,
  SavedQuoteItem
} from '../../actions/documents/getDocumentSummaryUsingAuthLinkAction';
import { RedirectToClaimsPortalPayload } from '../../actions/documents/redirectToClaimsPortalAction';
import { SelectFeatureTabPayload } from '../../actions/documents/selectFeatureTabAction';
import { AuthLinkEmailPayload } from '../../actions/documents/sendAuthLinkEmailAction';
import { SetActionMessagePayload } from '../../actions/documents/setActionMessageAction';
import { ToggleClaimsPayload } from '../../actions/documents/toggleClaimsAction';
import { ToggleHistoryPayload } from '../../actions/documents/toggleHistoryAction';
import { UpdateAutoRenewalStatusPayload } from '../../actions/documents/updateAutoRenewalStatusAction';
import { ModifyFieldPayload } from '../../actions/modifyFieldAction';
import { getConfig } from '../../configuration';
import * as fmt from '../../formatters';
import { DateFormatterOptions } from '../../formatters/dateFormatter';
import { HistoryVisibleType, PolicyHasIncidentMap } from '../../reducers/documentsReducer';
import { DocumentsState, UserInterfaceState } from '../../reducers/stateTypes';
import { ExternalLink } from '../common/ExternalLink';
import { FontIcon } from '../common/fontIcon';
import HeaderBrowser from '../common/HeaderBrowser';
import { SvgIcon } from '../common/SvgIcon';
import { showModalControl } from '../controls/ModalControl';
import { SetActiveTabFunc } from '../controls/TabControl';
import { ToggleButtonControl } from '../controls/ToggleButtonControl';
import { AdministrationCard } from './AdministrationCard';
import { AnalyticsPageProps, sendAnalytics, SendAnalyticsProps } from './analytics';
import { ClaimsCard } from './ClaimsCard';
import { DocumentSummaryCard, DocumentSummaryCardProps } from './DocumentSummaryCard';
import { NotificationsCard } from './NotificationsCard';
import { SavedQuoteCard } from './SavedQuoteCard';

export const fmtDateOpts: DateFormatterOptions = { longYear: false };

const { environment, assetsUrl, logoPath } = getConfig();
const mainButtonDivClass = 'col-xs-12 col-sm-12 col-md-12 pl-0 pr-0 pr-md-0';
const mainButtonElementClass = 'btn btn-action col-md-12';
const historyButtonElementClass = 'w-100';
const logoSrc = assetsUrl + logoPath;

export const labels = {
  policyNumber: 'Policy reference',
  policyTerm: 'Start & end date',
  policyStatus: 'Status',
  postcode: 'Postcode',
  quotePrice: 'Quote for the year',
  quoteReference: 'Quote reference',
  quoteStatus: 'Status',
  dateSaved: 'Date saved',
  coverType: 'Cover type',
  policyAutoRenewal: 'Policy auto-renewal',
  hasIncidentOccurred: 'Has incident occurred?',
  addOns: 'Policy Add-ons',
  cardNumber: 'Card details',
  accountNumber: 'Payment account'
};

export type LabelKeys =
  | 'policyNumber'
  | 'policyTerm'
  | 'policyStatus'
  | 'postcode'
  | 'quotePrice'
  | 'quoteReference'
  | 'quoteStatus'
  | 'dateSaved'
  | 'coverType'
  | 'notification'
  | 'policyAutoRenewal'
  | 'hasIncidentOccurred'
  | 'addOns'
  | 'cardNumber'
  | 'accountNumber'
  | 'renewalDisplayWarning';

export type LogOffFunc = (event: BaseSyntheticEvent) => void;
export type ToggleHistoryFunc = (event: BaseSyntheticEvent, policyNumber: string, makeVisible: boolean) => void;
export type RedirectToClaimsPortalFunc = (event: BaseSyntheticEvent) => void;
export type SetActionMessageFunc = (event: BaseSyntheticEvent, message: string, type: MessageTypes) => void;
export type EmailDocumentsFunc = (
  event: BaseSyntheticEvent,
  policyNumber: string | undefined,
  documentArchiveId: number | undefined
) => void;
export type UpdateAutoRenewalStatusFunc = (props: UpdateAutoRenewalStatusProps) => void;
export interface UpdateAutoRenewalStatusProps {
  event: BaseSyntheticEvent;
  isAutoRenewal: boolean;
  document: DocumentSummaryItem;
}

export type ViewTypes = 'mobile' | 'desktop';
export type FeatureTypes = 'quotes' | 'documents' | 'notifications' | 'administration' | 'claims';
export type SourceTypes = 'qab' | 'bws' | 'cpw' | undefined; // Quote & Buy; Brochureware Site; Customer Portal Website

export type DocumentsPageProps = DocumentsPageStateProps & DispatchProps & RouteComponentProps<any>;

export interface DispatchProps {
  fetchErrorOccurred: (err: any) => void;
  modifyField: (req: ModifyFieldPayload) => void;
  emailDocuments: (req: EmailDocumentsPayload) => void;
  redirectToClaimsPortal: (req: RedirectToClaimsPortalPayload) => void;
  updateAutoRenewalStatus: (req: UpdateAutoRenewalStatusPayload) => void;
  sendAuthLinkEmail: (req: AuthLinkEmailPayload) => void;
  getDocumentSummaryUsingAuthLink: (req: GetDocumentSummaryUsingAuthLinkPayload) => void;
  setActionMessage: (req: SetActionMessagePayload & FormTypePayload) => void;
  toggleHistory: (req: ToggleHistoryPayload & FormTypePayload) => void;
  toggleClaims: (req: ToggleClaimsPayload & FormTypePayload) => void;
  logOff: (req: FormTypePayload) => void;
  selectFeatureTab: (req: SelectFeatureTabPayload & FormTypePayload) => void;
}

export interface DocumentsPageStateProps extends DocumentsState {
  userInterface: UserInterfaceState;
}

export interface PolicyDetailsCardCommonProps {
  document: DocumentSummaryItem;
  index: number;
  viewType: ViewTypes;
  featureType: FeatureTypes;
  historyVisible: HistoryVisibleType;
  disableActionButtons: boolean;
  DocumentContentRow: (props: DocumentContentRowProps) => JSX.Element;
  DocumentCard: (props: DocumentCardProps) => JSX.Element;
  toggleHistory: ToggleHistoryFunc;
  HistoryRow: (props: RenderHistoryRowProps) => JSX.Element;
  setActiveTab: SetActiveTabFunc;
  setActionMessage: SetActionMessageFunc;
  updateAutoRenewalStatus: UpdateAutoRenewalStatusFunc;
  source: SourceTypes;
  emailDocuments?: EmailDocumentsFunc | undefined;
}

export interface DocumentCardProps {
  id: string;
  index: number;
  mainContent: JSX.Element[];
  buttonContent: JSX.Element[];
  detailsContent: JSX.Element[] | null;
  viewType: ViewTypes;
  featureType: FeatureTypes;
  messageContent?: JSX.Element | null;
}

export interface NotificationCardProps {
  id: string;
  index: number;
  mainContent: JSX.Element[];
  buttonContent: JSX.Element[];
  detailsContent: JSX.Element[] | null;
  viewType: ViewTypes;
  featureType: FeatureTypes;
}

export interface ClaimsCardProps {
  id: string;
  index: number;
  mainContent: JSX.Element[];
  buttonContent: JSX.Element[];
  detailsContent: JSX.Element[] | null;
  viewType: ViewTypes;
  featureType: FeatureTypes;
}

export interface DocumentDetailSectionProps {
  id: string;
  detailText: string;
  details: JSX.Element[];
  isDetailVisible: boolean;
  toggleEvent: (e: BaseSyntheticEvent, showDetail: boolean) => void;
}

export interface RenderHistoryRowProps {
  document: DocumentSummaryItem;
  index: number;
  setActionMessage: SetActionMessageFunc;
  disableActionButtons: boolean;
  source: SourceTypes;
  emailDocuments?: EmailDocumentsFunc | undefined;
}

export type CommonButtonTypes = 'download' | 'email' | 'saved-quote' | 'start-claim';

export interface CommonButtonProps {
  disableActionButtons: boolean;
  buttonType?: CommonButtonTypes | undefined;
  index: number;
  source: SourceTypes;
}

export interface RenderDownloadPolicyDocumentsLinkProps extends CommonButtonProps {
  document: DocumentSummaryItem;
  setActionMessage: SetActionMessageFunc;
}

export interface DocumentContentRowProps {
  labelKey: LabelKeys;
  content: string | JSX.Element;
  contentClass?: string;
  labelClass?: string;
  isMaskingEnabled?: boolean;
}

export function renderDownloadPolicyDocumentLinks({
  document,
  setActionMessage,
  disableActionButtons,
  buttonType,
  index,
  source
}: RenderDownloadPolicyDocumentsLinkProps): JSX.Element {
  const { policyNumber, riskPostCode, emailAddress, documentArchiveId } = document;
  const sid = cookies.get('connect.sid');
  const docQueryText = queryString.stringify({
    postcode: riskPostCode,
    policyNumber,
    emailAddress,
    documentArchiveId,
    sid: sid || ''
  });
  const setDownloadMessage = (e: BaseSyntheticEvent, msg: string) => setActionMessage(e, msg, 'info');

  const { buttonClass } = getButtonClasses(buttonType, disableActionButtons, index);

  const createLinkAndClickHandler: (name: 'policy' | 'booklet') => LinkAndClickProps = (name) => {
    const href = createFileUri(
      name === 'policy' ? `/api/document/download?${docQueryText}` : `/api/document/booklet/download?${docQueryText}`
    );

    const analyticsSender = () =>
      sendAnalytics({
        props: { emailAddress, postcode: riskPostCode, source },
        event: 'Download Documents Click',
        checkRepeats: false,
        detail: {
          policyNumber,
          documentArchiveId
        }
      });

    const onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void = (e) => {
      e.preventDefault();
      analyticsSender();
      setDownloadMessage(e, 'Your documents will download shortly.');
    };

    const btnClass = `${buttonClass} btn-download btn btn-primary`;
    const content = (
      <>
        <FontIcon faIcon="download" /> Download my policy {name === 'policy' ? 'document' : name}
      </>
    );
    return { id: `download-${name}`, href, onClick, className: btnClass, content };
  };

  const content: JSX.Element = (
    <>
      <ExternalLink {...createLinkAndClickHandler('policy')} />
      <ExternalLink {...createLinkAndClickHandler('booklet')} />
    </>
  );

  return content;
}

interface LinkAndClickProps {
  id: string;
  href: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className: string;
  content: JSX.Element;
}

export interface RenderEmailLinkProps extends CommonButtonProps {
  emailDocuments: EmailDocumentsFunc;
  policyNumber: string;
  documentArchiveId: number;
}

export function renderEmailLink({
  emailDocuments,
  disableActionButtons,
  policyNumber,
  documentArchiveId,
  buttonType,
  index
}: RenderEmailLinkProps): JSX.Element {
  const { buttonClass } = getButtonClasses(buttonType, disableActionButtons, index);
  return (
    <button
      disabled={disableActionButtons}
      onClick={(e) => emailDocuments(e, policyNumber, documentArchiveId)}
      className={`${buttonClass} btn-info`}>
      <FontIcon faIcon="envelope" /> Email my documents
    </button>
  );
}

export interface RenderOpenSavedQuoteLinkProps extends CommonButtonProps {
  savedQuote: SavedQuoteItem;
  disableActionButtons: boolean;
  props: AnalyticsPageProps;
  setActionMessage: SetActionMessageFunc;
}

export function renderOpenSavedQuoteLink({
  savedQuote,
  disableActionButtons,
  setActionMessage,
  index,
  props
}: RenderOpenSavedQuoteLinkProps): JSX.Element {
  const { buttonClass, buttonDivClass } = getButtonClasses(undefined, disableActionButtons, index);
  let url = savedQuote.quoteLink;
  const { source } = props;
  if (source) {
    const { query, uri } = parseUri(url);
    url = `${uri}/${source}`;
    url = createUri({ uri: url, query: query });
  }

  const buttonText = 'Open quote';
  const buttonIcon = <SvgIcon icon="quote" />;
  const key = 'openQuoteBtn';
  const { agreementId, quoteReference } = savedQuote;
  const onClick = (e) => {
    setActionMessage(e, 'Your quote will open shortly', 'success');
  };

  return createLinkButton({
    buttonClass: `${buttonClass} saved-quote`,
    buttonDivClass,
    buttonText,
    buttonIcon,
    key,
    disableActionButtons,
    openInNewTab: false,
    url,
    onClick,
    analyticsProps: {
      props,
      event: 'Open Quote Click',
      detail: { quoteLink: url, source, agreementId, quoteReference },
      checkRepeats: false
    }
  });
}

interface RenderOpenClaimLink extends CommonButtonProps {
  disableActionButtons: boolean;
  setActionMessage: SetActionMessageFunc;
  redirectToClaimsPortal: RedirectToClaimsPortalFunc;
}

export function renderOpenClaimLink({
  disableActionButtons,
  setActionMessage,
  index,
  redirectToClaimsPortal
}: RenderOpenClaimLink): JSX.Element {
  const { buttonClass, buttonDivClass } = getButtonClasses('start-claim', disableActionButtons, index);
  const buttonText = 'Submit a claim';
  const key = 'startClaimBtn';
  const onClick = (e) => {
    setActionMessage(e, 'Opening a new tab to start your claim', 'success');
    redirectToClaimsPortal(e);
  };

  return createLinkButton({
    buttonClass: `${buttonClass} btn start-claim`,
    buttonDivClass,
    buttonText,
    key,
    disableActionButtons,
    openInNewTab: true,
    onClick
  });
}

interface CreateButtonLinkProps {
  buttonDivClass: string;
  buttonClass: string;
  buttonText: string;
  buttonIcon?: JSX.Element | undefined;
  key: string;
  disableActionButtons: boolean;
  openInNewTab: boolean;
  url?: string;
  onClick: (e: React.MouseEvent) => void;
  analyticsProps?: SendAnalyticsProps;
}

function createLinkButton({
  buttonDivClass,
  buttonClass,
  buttonText,
  buttonIcon,
  key,
  url,
  onClick,
  disableActionButtons,
  openInNewTab,
  analyticsProps
}: CreateButtonLinkProps) {
  const onClickHandler = (e) => {
    e.preventDefault();
    if (disableActionButtons) {
      return;
    }

    onClick(e);
    if (url) {
      redirectToUrl({ openInNewTab, url, analyticsProps });
    }
  };

  return (
    <div className={`${buttonDivClass}`} key={key}>
      <a href={url} onClick={onClickHandler} className={`${buttonClass} w-sm-auto btn-primary`}>
        {buttonIcon}
        {buttonText}
      </a>
    </div>
  );
}

interface RedirectToUrlProps {
  openInNewTab: boolean;
  url: string;
  analyticsProps?: SendAnalyticsProps;
}

export function redirectToUrl({ openInNewTab, url, analyticsProps }: RedirectToUrlProps): void {
  setTimeout(() => {
    const link = document.createElement('a');
    link.href = url;
    if (openInNewTab) {
      link.target = '_blank';
    }

    if (analyticsProps) {
      const { props, event, detail } = analyticsProps;
      sendAnalytics({
        props,
        event,
        detail,
        checkRepeats: false
      });
    }

    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
    }, 100);
  }, 1000);
}

export interface AutoRenewalToggleProps {
  show?: boolean;
}

interface AutoRenewalRowProps {
  document: DocumentSummaryItem;
  disableActionButtons: boolean;
  showToggle: boolean;
  updateAutoRenewalStatus: UpdateAutoRenewalStatusFunc;
  DocumentContentRow: (props: DocumentContentRowProps) => JSX.Element;
  setActiveTab: SetActiveTabFunc;
}

export function createAutoRenewalRow({
  document,
  disableActionButtons,
  showToggle,
  updateAutoRenewalStatus,
  DocumentContentRow,
  setActiveTab
}: AutoRenewalRowProps): JSX.Element | undefined {
  const { allowPolicyMaintenanceBool, endDate } = document;
  if (!allowPolicyMaintenanceBool) {
    return;
  }

  const { stopRenewal } = document;
  const isAutoRenew = !stopRenewal;
  let content: JSX.Element;
  let label: JSX.Element | string = isAutoRenew ? 'Automatically renew this policy' : '';
  if (showToggle) {
    content = createAutoRenewalToggle({
      isAutoRenew,
      disableActionButtons,
      document,
      updateAutoRenewalStatus,
      label: label || 'Do not renew this policy'
    });
  } else {
    const linkId = 'auto-renewal-edit-link';
    const onClickHandler = () => setActiveTab('administration', linkId);
    const shortEndDate = fmt.date(endDate, fmtDateOpts);
    label = label || (
      <>
        Cover will end on {shortEndDate}
        <a id={linkId} onClick={onClickHandler}>
          Edit
          <FontIcon faIcon="pencil" />
        </a>
      </>
    );
    content = (
      <label htmlFor="turn-on" className="toggle-label">
        {label}
      </label>
    );
  }

  return DocumentContentRow({
    labelKey: 'policyAutoRenewal',
    content
  });
}

export interface PolicyDetailsRows {
  policyNumberRow: JSX.Element;
  policyTermRow: JSX.Element;
  postcodeRow: JSX.Element;
  warningCard: JSX.Element | null;
}

interface CreatePolicyDetailsRowsProps {
  document: DocumentSummaryItem;
  DocumentContentRow: (props: DocumentContentRowProps) => JSX.Element;
}

function createPolicyDetailsRows({ document, DocumentContentRow }: CreatePolicyDetailsRowsProps): PolicyDetailsRows {
  const { policyNumber, riskPostCode, effectiveDate, endDate, renewalDisplayWarning } = document;
  const ndash = String.fromCharCode(8211);
  const policyTermRow = DocumentContentRow({
    labelKey: 'policyTerm',
    content: `${fmt.date(effectiveDate, fmtDateOpts)} ${ndash} ${fmt.date(endDate, fmtDateOpts)}`
  });
  const policyNumberRow = DocumentContentRow({
    labelKey: 'policyNumber',
    content: policyNumber,
    contentClass: 'document policy-reference'
  });
  const postcodeRow = DocumentContentRow({
    labelKey: 'postcode',
    content: riskPostCode,
    isMaskingEnabled: true
  });
  const warningCard = document.renewalDisplayWarning ? createRenewPolicyWarningCard(renewalDisplayWarning) : null;
  return { policyNumberRow, policyTermRow, postcodeRow, warningCard };
}

enum RenewalDisplayWarningEnum {
  msgacustomertocallin = 'We need to speak with you before renewing this policy. Please call us on 0330 660 1000.',
  msgbcustomeroptedout = 'This policy is not set to renew. Call us on 0330 660 1000 or email renew@homeprotect.co.uk to renew this policy.',
  msgcrenewalbeingprocessed = 'Your renewal is being processed. Call us on 0330 660 1000 or email renew@homeprotect.co.uk to amend this policy.',
  msgdexpiredpolicy = 'This policy has expired. Call us on 0330 660 1000 or email renew@homeprotect.co.uk to renew this policy.'
}

function createRenewPolicyWarningCard(renewalDisplayWarning: string): JSX.Element {
  const warningMessage: string = RenewalDisplayWarningEnum[renewalDisplayWarning];
  return (
    <div className="col-12">
      <div id="renew-policy-warning-card" className="container card">
        <div className="row">
          <div className="col col-12">
            <FontIcon faIcon="exclamation-triangle" />
            <p style={{ textAlign: 'center' }}>{warningMessage}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function createPricePromiseCard(viewType: ViewTypes): JSX.Element | undefined {
  return (
    <div className={`col-12 ${viewType}-view`}>
      <div className="price-promise-card">
        <SvgIcon icon="price-promise" className="price-promise-icon" />
        <div className="bubble">
          <h2 className="heading">Homeprotect Price Promise</h2>
          <div className="content">
            When we offer you a renewal price, it will always be the same as, or better than, the price offered to new
            customers.
          </div>
        </div>
      </div>
    </div>
  );
}

interface CreateAutoRenewalToggleProps {
  isAutoRenew: boolean;
  document: DocumentSummaryItem;
  disableActionButtons: boolean;
  updateAutoRenewalStatus: UpdateAutoRenewalStatusFunc;
  label: string;
}

function createAutoRenewalToggle({
  isAutoRenew,
  disableActionButtons,
  document,
  updateAutoRenewalStatus,
  label
}: CreateAutoRenewalToggleProps): JSX.Element {
  const modalBody = createAutoRenewalModalBody(isAutoRenew);
  const modalCopyText = {
    body: modalBody
  };
  const handleClick = () => {
    if (disableActionButtons) {
      return;
    }

    return showModalControl({
      modalCopyText,
      id: 'test-modal',
      onConfirmHandler: (e: BaseSyntheticEvent) =>
        updateAutoRenewalStatus({
          event: e,
          isAutoRenewal: !isAutoRenew,
          document
        })
    });
  };

  return (
    <ToggleButtonControl
      name="auto-renew"
      active={isAutoRenew}
      label={label}
      onClick={handleClick}
      disabled={disableActionButtons}
    />
  );
}

function createAutoRenewalModalBody(isAutoRenew: boolean): JSX.Element | string {
  let modalBody: string | JSX.Element =
    'Your policy is not set to renew automatically. Click confirm to opt in to auto-renewal for this policy. You can opt out again at any time.';
  if (isAutoRenew) {
    modalBody = (
      <div id="opt-in-modal-content">
        Your policy is set to renew automatically, ensuring that you always remain protected. Click confirm to opt out
        of auto-renewal for this policy.
        <div>
          <FontIcon faIcon="exclamation-circle" />
          <span style={{ fontWeight: 'bold' }}>
            Insurance cover will end at the policy renewal date unless you tell us otherwise
          </span>
        </div>
      </div>
    );
  }

  return modalBody;
}

export function createMainHeader(): JSX.Element {
  return (
    <div id="header" className="container col-xs-12 col-sm-12 col-md-10 col-lg-8">
      <HeaderBrowser />
      <header>
        <section className="header row">
          <div className="pull-left col-xs-4 col-sm-4">
            <a href="https://www.homeprotect.co.uk/">
              <img aria-label="Homeprotect" src={logoSrc} alt="Homeprotect" width="200" />
            </a>
          </div>
        </section>
      </header>
    </div>
  );
}

export function AuthLinkWillBeEmailedScreen(
  linkActiveDuration: string,
  linkMaximumClicksText: string,
  feature: FeatureTypes
): JSX.Element {
  let paragraph1: JSX.Element | string,
    paragraph2: JSX.Element | string | null = null;
  if (feature === 'claims') {
    paragraph1 = (
      <>
        We’ve emailed you a link to access your policy information and to submit a claim. This link can{' '}
        {linkMaximumClicksText} and is valid for {linkActiveDuration}. If it has expired, you’ll need to log in again.
      </>
    );
  } else {
    paragraph1 = `Thank you. An email will be sent to you shortly containing a secure link, which enables you access to your
          latest policy documentation.`;
    paragraph2 = (
      <>
        Please note &ndash; this link can {linkMaximumClicksText} and is valid for {linkActiveDuration}. If it has
        expired for either reason, you will need to resubmit your documentation request.
      </>
    );
  }

  const style: CSSProperties = { width: '100%' };
  return (
    <div key="authLinkSent" className="row w-100 ml-0 mr-0 pl-3 pr-3">
      <div className="col-12 table-width">
        <p style={style}>{paragraph1}</p>
        {paragraph2 && <p style={style}>{paragraph2}</p>}
        <p style={{ ...style, textAlign: 'center' }}>
          <a href="https://www.homeprotect.co.uk/">Return to the Homeprotect homepage</a>
        </p>
      </div>
    </div>
  );
}

export interface ViewportProps {
  policyDocuments: DocumentSummaryItem[];
  savedQuotes: SavedQuoteItem[];
  historyVisible: HistoryVisibleType;
  policyHasIncidentMap: PolicyHasIncidentMap;
  disableActionButtons: boolean;
  toggleHistory: ToggleHistoryFunc;
  redirectToClaimsPortal: RedirectToClaimsPortalFunc;
  showClaims: boolean;
  setActionMessage: SetActionMessageFunc;
  setActiveTab: SetActiveTabFunc;
  emailDocuments: EmailDocumentsFunc;
  updateAutoRenewalStatus: UpdateAutoRenewalStatusFunc;
  DocumentContentRow: (props: DocumentContentRowProps) => JSX.Element;
  DocumentCard: (props: DocumentCardProps) => JSX.Element;
  HistoryRow: (props: RenderHistoryRowProps) => JSX.Element;
  viewType: ViewTypes;
  analyticsProps: AnalyticsPageProps;
  source: SourceTypes;
}

interface ViewportResult {
  summaryTab: JSX.Element[];
  quoteTab: JSX.Element[];
  notificationsTab: JSX.Element;
  administrationTab: JSX.Element[];
  claimsTab: JSX.Element[];
}

export function Viewport({
  policyDocuments,
  savedQuotes,
  historyVisible,
  disableActionButtons,
  toggleHistory,
  redirectToClaimsPortal,
  showClaims,
  DocumentContentRow,
  DocumentCard,
  HistoryRow,
  setActionMessage,
  setActiveTab,
  emailDocuments,
  updateAutoRenewalStatus,
  viewType,
  analyticsProps,
  source
}: ViewportProps): ViewportResult {
  let summaryTab: JSX.Element[] = [];
  let administrationTab: JSX.Element[] = [];
  let claimsTab: JSX.Element[] = [];

  for (let index = 0; index < policyDocuments.length; index++) {
    const document = policyDocuments[index];
    const policyDetailsCardProps: PolicyDetailsCardCommonProps = {
      document,
      index: index + 1,
      historyVisible,
      disableActionButtons,
      toggleHistory,
      setActionMessage,
      setActiveTab,
      updateAutoRenewalStatus,
      DocumentContentRow,
      DocumentCard,
      HistoryRow,
      viewType,
      featureType: 'documents',
      source
    };

    const policyDetailsRows = createPolicyDetailsRows({
      document,
      DocumentContentRow
    });

    const summaryCardProps: DocumentSummaryCardProps = {
      ...policyDetailsCardProps,
      policyDetailsRows,
      emailDocuments
    };
    summaryTab.push(DocumentSummaryCard(summaryCardProps));
    administrationTab.push(AdministrationCard({ ...policyDetailsCardProps, policyDetailsRows, setActiveTab }));
  }

  const quoteTab = savedQuotes.map((savedQuote, index) =>
    SavedQuoteCard({
      savedQuote,
      index: index + 1,
      disableActionButtons,
      DocumentCard,
      DocumentContentRow,
      setActionMessage,
      viewType,
      featureType: 'quotes',
      analyticsProps,
      source
    })
  );

  const notificationsTab = NotificationsCard({
    DocumentCard,
    DocumentContentRow,
    viewType,
    featureType: 'quotes',
    analyticsProps,
    source
  });

  const pricePromiseCard = createPricePromiseCard(viewType);
  if (pricePromiseCard) {
    administrationTab = [pricePromiseCard, ...administrationTab];
    summaryTab = [pricePromiseCard, ...summaryTab];
  }

  const pdfLink = howToOpenPdfFiles(viewType);
  if (pdfLink) {
    summaryTab = [pdfLink, ...summaryTab];
  }

  if (showClaims) {
    claimsTab.push(
      ClaimsCard({
        featureType: 'claims',
        viewType,
        analyticsProps,
        redirectToClaimsPortal,
        setActionMessage,
        disableActionButtons,
        source
      })
    );

    const claimsTabTitle = getClaimsTabTitle(viewType);
    if (claimsTabTitle) {
      claimsTab = [claimsTabTitle, ...claimsTab];
    }
  }

  return { summaryTab, quoteTab, notificationsTab, administrationTab, claimsTab };
}

export function typeCodeToDescription(code: DocumentTypeCodes): string {
  switch (code) {
    case 'MTA':
      return 'Policy adjusted';
    case 'NB':
      return 'Policy purchased';
    case 'RNW':
      return 'Policy renewed';
    case 'CN':
      return 'Policy cancelled';
    default:
      return '';
  }
}

function getButtonClasses(buttonType: CommonButtonTypes | undefined, disableActionButtons: boolean, index: number) {
  const isStartClaim = buttonType === 'start-claim';
  const isHistory = !!buttonType && !isStartClaim;
  let buttonClass: string, buttonDivClass: string;

  if (!isHistory) {
    const textAlignClass = isStartClaim ? 'text-center' : 'text-left';
    buttonClass = `${mainButtonElementClass} ${textAlignClass}${disableActionButtons ? ' disabled' : ''}`;
    buttonDivClass = `${mainButtonDivClass} ${index === 0 ? 'pr-sm-2' : ''}`;
  } else {
    buttonClass = historyButtonElementClass;
    buttonDivClass = '';
  }

  return { buttonClass, buttonDivClass, isHistory };
}

function howToOpenPdfFiles(viewType: ViewTypes): JSX.Element | null {
  if (viewType === 'desktop') {
    return (
      <p key={`${viewType}-pdf-link`} className="desktop-only">
        If you can’t open these files, you may need to download a{' '}
        <ExternalLink id="pdf-viewer" href="https://get.adobe.com/uk/reader/" content="PDF viewer" />.
      </p>
    );
  }

  return null;
}

function getClaimsTabTitle(viewType: ViewTypes): JSX.Element | null {
  if (viewType === 'desktop') {
    return (
      <h4 className="claim-card-title" key="claims-tab-title">
        Make a home insurance claim
      </h4>
    );
  }

  return null;
}

export interface MaybeAuthLink {
  pathPrefix: string;
  authToken: string;
  authType: AuthTypes;
  tab: FeatureTypes;
  source: SourceTypes;
}

export type AuthTypes = 'auth' | 'pass-back' | 'none';

export function processMaybeAuthLink(pathname: string): MaybeAuthLink {
  const determineTabAndSource = (tab: string, source: string | undefined) => {
    if (!(tab === 'quotes' || tab === 'documents' || tab === 'claims')) {
      source = tab;
      tab = 'documents';
    }

    return { tab: tab as FeatureTypes, source: source as SourceTypes };
  };

  const authComp = '/auth/';
  const passBackComp = '/passback/';
  const indexOfAuth = pathname.indexOf(authComp);
  const indexOfPassBack = pathname.indexOf(passBackComp);
  const isAuth = indexOfAuth >= 0;
  const isPassBack = indexOfPassBack >= 0;
  if (isAuth || isPassBack) {
    let authType: AuthTypes, authOrPassBackIndex: number, authOrPassBackLength: number;
    if (isAuth) {
      authType = 'auth';
      authOrPassBackIndex = indexOfAuth;
      authOrPassBackLength = authComp.length;
    } else {
      authType = 'pass-back';
      authOrPassBackIndex = indexOfPassBack;
      authOrPassBackLength = passBackComp.length;
    }

    const pathPrefix = pathname.substr(0, authOrPassBackIndex);
    const authParts = pathname.substr(authOrPassBackIndex + authOrPassBackLength).split('/');
    const authToken = authParts[0];
    const { tab, source } = determineTabAndSource(authParts[1], authParts[2]);
    return { pathPrefix, authToken, authType, tab, source };
  } else {
    const parts = pathname.split('/');
    const index = parts.indexOf('documents') + 1;
    if (index > 0) {
      const { tab, source } = determineTabAndSource(parts[index], parts[index + 1]);
      const pathPrefix = parts.slice(0, index).join('/');
      return { authType: 'none', pathPrefix, authToken: '', tab, source };
    }
  }

  return { authType: 'none', pathPrefix: pathname, authToken: '', tab: 'documents', source: undefined };
}

export interface NoQuotesScreenProps {
  feature: FeatureTypes;
  showSalutation: boolean;
}

export function NoQuotesScreen({ feature, showSalutation }: NoQuotesScreenProps): JSX.Element {
  return (
    <div key="no-data">
      {showSalutation && (
        <h1 key="salutation" className="salutation-header">
          Hello {/* {title} {surname} */}
        </h1>
      )}
      {!showSalutation && <div>&nbsp;</div>}
      <div className="row">
        <div className="col-12 ml-3 mr-3">
          {feature === 'quotes' && (
            <p>
              You currently have no saved quotes with us, but we are always here to help you{' '}
              <ExternalLink
                id="get-quote-link"
                href="https://www.homeprotect.co.uk"
                content="get a quote for your insurance needs"
              />
              .
            </p>
          )}
          {feature === 'documents' && <p>You currently have no policy documents saved with us.</p>}
          <p>
            If you need any further assistance, please contact our customer service team on{' '}
            <strong>0330 660 1000</strong>.
          </p>
        </div>
      </div>
    </div>
  );
}

function createFileUri(fileUri: string): string {
  if (environment === 'developer') {
    fileUri = 'http://localhost:3001' + fileUri;
  }

  return fileUri;
}
