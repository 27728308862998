import { lodash } from '@avantia/lodash';
import { ReactElement } from 'react';
import { getControlStyle } from '../styleLibrary';

export interface ErrorSectionProps {
  errors: string[];
  processingRequest: boolean;
}

export interface CreateErrorDetailProps extends ErrorSectionProps {
  modifyMessages: ModifyMessagesFunction;
}

export function ErrorSection({ errors, processingRequest }: ErrorSectionProps): JSX.Element | null {
  const errorResult = createErrorDetail({ errors, processingRequest, modifyMessages: modifyMessagesDefault });
  if (!errorResult) {
    return null;
  }

  const { errors: errorItems, isNegated } = errorResult;
  const errorList = errorItems.map((errorElement, key) => <p key={`error-${key}`}>{errorElement}</p>);
  const style = getControlStyle('Standard', 'ErrorSection', false);
  return (
    <div id="error-section" className="row" style={style}>
      <div className={`alert alert-${isNegated ? 'success' : 'danger'}`} style={{ textAlign: 'center' }}>
        {errorList}
      </div>
    </div>
  );
}

export interface ErrorDetailResult {
  errors: (JSX.Element | string)[];
  isNegated?: boolean;
}

export function createErrorDetail({
  errors,
  processingRequest,
  modifyMessages
}: CreateErrorDetailProps): ErrorDetailResult | null {
  if (errors && errors.length > 0) {
    errors = lodash.filter(errors, (e) => !!e);
  }

  if (!errors || !errors.length || processingRequest === true) {
    return null;
  }

  const state: ModifyMessageState = { isNegated: undefined, key: 0 };
  errors = lodash.filter(errors, (e) => !!e);
  const errorList = errors.map((error) => {
    const parts = error.split(':');
    const code = parts[0];
    const message = lodash.trim(parts[1] || parts[0]);
    let errorElement = modifyMessages(code, message, state);
    if (!errorElement) {
      errorElement = <span key={`item-${state.key++}`}>{message}</span>;
      state.isNegated = false;
    }

    return errorElement;
  });

  return { errors: errorList, isNegated: state.isNegated };
}

export type ModifyMessagesFunction = (
  code: string,
  message: string,
  state: ModifyMessageState
) => ModifyMessagesResponse;
export type ModifyMessagesResponse = ReactElement | string | undefined;

export interface ModifyMessageState {
  isNegated?: boolean;
  key: number;
}

export function modifyMessagesDefault(code: string, _: string, state: ModifyMessageState): ModifyMessagesResponse {
  let errorElement: ModifyMessagesResponse;
  const key = `item-${state.key++}`;
  switch (code) {
    case 'CPS003':
      errorElement = (
        <span key={key}>
          <strong>Good news!</strong> Your account is no longer in arrears. No need to make a payment!
        </span>
      );
      state.isNegated = state.isNegated === undefined ? true : state.isNegated;
      break;
    case 'CPS012':
    case 'CPS013':
      errorElement = (
        <span key={key}>
          <strong>Good news!</strong> No need to make a payment right now. We&apos;ll be in touch if we do need you to
          make one.
        </span>
      );
      state.isNegated = state.isNegated === undefined ? true : state.isNegated;
      break;
    case 'CPS016':
      errorElement = <span key={key}>Sorry, your details could not be found. Please try again.</span>;
      break;
    default:
      errorElement = undefined;
      break;
  }

  return errorElement;
}
