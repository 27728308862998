import { DocumentCardProps } from '../documentsPageCommon';

export function DocumentCard({
  id,
  mainContent,
  buttonContent,
  detailsContent,
  index,
  viewType,
  featureType,
  messageContent: renewalDetailsCard
}: DocumentCardProps): JSX.Element {
  return (
    <div key={`doc-card-${viewType}-${index}`} className={`card container ${featureType}-${viewType} ${viewType}-view`}>
      <div className="row" key="detail-labels" id={id}>
        {mainContent}
      </div>
      <div className="row btns" key="detail-buttons">
        {buttonContent}
      </div>
      {renewalDetailsCard && <div className="warning-card-container">{renewalDetailsCard}</div>}
      {detailsContent}
    </div>
  );
}
