import { Footer } from './Footer';

export function wrapInMain(element: JSX.Element): JSX.Element {
  return (
    <main className="container col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
      {element}
      <Footer />
    </main>
  );
}
