import { CSSProperties } from 'react';
import { Form } from 'react-bootstrap';

export interface DayMonthYearEditorParams {
  label: string;
  name: string;
  fields: any;
  editorfields: any;
  onChange: any;
  onFocus?: any;
  onBlur?: any;
  prefixLabel?: string;
  modelErrors: any;
  fieldWithFocus?: any;
}

const DayMonthYearEditor = ({
  label,
  name,
  fields,
  editorfields,
  onChange,
  onFocus,
  onBlur,
  prefixLabel,
  modelErrors,
  fieldWithFocus
}: DayMonthYearEditorParams): JSX.Element => {
  let modelError = '';
  fields.forEach((element) => {
    modelError = modelErrors.getError(element, element);
  });

  const autoFocus = name === fieldWithFocus;
  const inputBoxClasses = 'col-sm-2 col-md-3 col-lg-2';
  const inputBoxStyle: CSSProperties = { paddingRight: 0, width: 'auto' };

  return (
    <div>
      <div>
        <h2 className="input-label">{label}</h2>
        <div className="input-group-prepend">
          {prefixLabel && <span className="input-group-text">{prefixLabel}</span>}
          <div className="inline">
            <Form className="form-horizontal" role="form">
              <div className="form-group">
                <div className="col-md-12">
                  <div className="form-group row">
                    <div className={inputBoxClasses} style={inputBoxStyle}>
                      <label
                        htmlFor="inputValue"
                        className="control-label"
                        style={{ fontSize: '.85em', fontFamily: "'Quicksand', sans-serif" }}>
                        Day
                      </label>
                      <input
                        onChange={onChange}
                        onBlur={(e) => {
                          onChange(e);
                          if (onBlur && onBlur !== onChange) {
                            onBlur(e);
                          }
                        }}
                        onFocus={onFocus}
                        autoComplete="off"
                        autoFocus={autoFocus}
                        style={{
                          borderColor: modelError ? 'red' : undefined,
                          height: '50px',
                          fontSize: '1em',
                          width: '100px'
                        }}
                        type="text"
                        className="form-control"
                        id="dobDay"
                        placeholder="e.g. 1"
                        name={'dobDay'}
                        value={editorfields['dobDay']}
                      />
                    </div>

                    <div className={inputBoxClasses} style={inputBoxStyle}>
                      <label
                        htmlFor="inputValue"
                        className="control-label"
                        style={{ fontSize: '.85em', fontFamily: "'Quicksand', sans-serif" }}>
                        Month
                      </label>
                      <input
                        onChange={onChange}
                        onBlur={(e) => {
                          onChange(e);
                          if (onBlur && onBlur !== onChange) {
                            onBlur(e);
                          }
                        }}
                        onFocus={onFocus}
                        autoComplete="off"
                        autoFocus={autoFocus}
                        style={{
                          borderColor: modelError ? 'red' : undefined,
                          height: '50px',
                          fontSize: '1em',
                          width: '100px'
                        }}
                        type="text"
                        className="form-control"
                        id="dobMonth"
                        placeholder="e.g. 12"
                        name={'dobMonth'}
                        value={editorfields['dobMonth']}
                      />
                    </div>

                    <div className={inputBoxClasses} style={inputBoxStyle}>
                      <label
                        htmlFor="inputValue"
                        className="control-label"
                        style={{ fontSize: '.85em', fontFamily: "'Quicksand', sans-serif" }}>
                        Year
                      </label>
                      <input
                        onChange={onChange}
                        onBlur={(e) => {
                          onChange(e);
                          if (onBlur && onBlur !== onChange) {
                            onBlur(e);
                          }
                        }}
                        onFocus={onFocus}
                        autoComplete="off"
                        autoFocus={autoFocus}
                        style={{
                          borderColor: modelError ? 'red' : undefined,
                          height: '50px',
                          fontSize: '1em',
                          width: '175px'
                        }}
                        type="text"
                        className="form-control"
                        id="dobYear"
                        placeholder="e.g. 1990"
                        name={'dobYear'}
                        value={editorfields['dobYear']}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div
        style={{
          paddingTop: '.5em',
          boxSizing: 'content-box'
        }}></div>
    </div>
  );
};

export { DayMonthYearEditor };
