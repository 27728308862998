import { lodash } from '@avantia/lodash';
import cookies from 'js-cookie';
import { StandardAction } from '../actions/actionTypes';
import { EmailDocumentsResponsePayload } from '../actions/documents/emailDocumentsAction';
import {
  DocumentSummaryItem,
  GetDocumentSummaryResponsePayload
} from '../actions/documents/getDocumentSummaryUsingAuthLinkAction';
import { SelectFeatureTabPayload } from '../actions/documents/selectFeatureTabAction';
import { SetActionMessagePayload } from '../actions/documents/setActionMessageAction';
import { ToggleClaimsPayload } from '../actions/documents/toggleClaimsAction';
import { ToggleHistoryPayload } from '../actions/documents/toggleHistoryAction';
import { ModifyFieldPayload } from '../actions/modifyFieldAction';
import { FeatureTypes } from '../components/documents/documentsPageCommon';
import { getInitialState } from './initialState';
import {
  applyChanges,
  getCookieBoolean,
  postcodeFieldValueFormatter,
  traverseObject,
  trimFieldValue
} from './reducerLibrary';
import { DocumentsState } from './stateTypes';

export type HistoryVisibleType = { [key: string]: boolean };
export type PolicyHasIncidentMap = { [key: string]: boolean };
export type AuthEmailLinkStatusTypes = 'in-progress' | 'sent' | 'failed' | undefined;
export type GetSumaryUsingAuthLinkStatusTypes = 'in-progress' | 'success' | 'failed' | undefined;

const initialState = getInitialState().documents;

export function documentsReducer(state: DocumentsState = initialState, action: StandardAction): DocumentsState {
  if (action.formType !== 'DocumentLogin') {
    return state;
  }

  switch (action.type) {
    case 'FIELD_CHANGED':
      return (() => {
        // eslint-disable-next-line prefer-const
        let { fieldName, value, eventType } = action.payload as ModifyFieldPayload;
        value = trimFieldValue(value, eventType);
        if (lodash.has(state, fieldName)) {
          if (fieldName === 'postcode' && value) {
            value = postcodeFieldValueFormatter(value);
          }

          const updated = {};
          updated[fieldName] = value;
          return applyChanges(state, updated);
        }

        return state;
      })();

    case 'REQUEST_EMAIL_DOCUMENTS_SUCCESS':
      return (() => {
        const { policyNumber, fromClick } = action.payload as EmailDocumentsResponsePayload;
        return applyChanges(state, {
          documentDetails: { policyNumber, fromClick },
          actionMessage: 'Your documents have been emailed to you.',
          actionMessageType: 'success',
          disableActionButtons: false
        });
      })();

    case 'REQUEST_DOCUMENT_SUMMARY_SUCCESS':
      return (() => {
        const payload = action.payload as GetDocumentSummaryResponsePayload;
        // eslint-disable-next-line prefer-const
        let { documents, savedQuotes, emailAddress, postcode, tab, source, showClaims } = payload;
        documents = documents || [];
        savedQuotes = savedQuotes || [];

        // Change dates from strings to Dates.
        const fixDates = (doc: DocumentSummaryItem) => {
          doc.documentSentDate = new Date(doc.documentSentDate);
          doc.policyInceptionDate = new Date(doc.policyInceptionDate);
          doc.effectiveDate = new Date(doc.effectiveDate);
          doc.endDate = new Date(doc.endDate);
          doc.dateOfBirth = new Date(doc.dateOfBirth);
        };

        documents.forEach((doc) => {
          fixDates(doc);
          if (doc.childItems) {
            doc.childItems.forEach(fixDates);
          }
        });

        savedQuotes.forEach((quote) => {
          quote.dateSaved = new Date(quote.dateSaved);
        });

        const { showTabs, tab: activeTab } = determineTabsToShow({
          tab,
          hasDocuments: documents.length > 0,
          hasSavedQuotes: savedQuotes.length > 0,
          showClaims,
          activeTab: state.activeTab
        });

        let showNotificationsBadge = getCookieBoolean('showNotificationsBadge');
        if (showNotificationsBadge === undefined) {
          cookies.set('showNotificationsBadge', 'true', { expires: 9999 });
          showNotificationsBadge = true;
        }

        return applyChanges(state, {
          hasDataLoaded: true,
          disableActionButtons: false,
          documentSummary: documents,
          savedQuotes,
          getSumaryUsingAuthLinkStatus: undefined,
          emailAddress,
          postcode,
          activeTab,
          showTabs,
          showNotificationsBadge,
          source
        });
      })();

    case 'REQUEST_DOCUMENT_SUMMARY_FAILED':
    case 'REQUEST_DOCUMENT_SUMMARY_FROM_SESSION_FAILED':
      return applyChanges(state, {
        getSumaryUsingAuthLinkStatus:
          action.type !== 'REQUEST_DOCUMENT_SUMMARY_FROM_SESSION_FAILED' ? 'failed' : undefined,
        documentSummary: undefined,
        savedQuotes: undefined,
        documentDetails: undefined,
        emailAddress: '',
        postcode: ''
      });

    case 'GET_DOCUMENTS_USING_AUTH_LINK_IN_PROGRESS':
      return applyChanges(state, { getSumaryUsingAuthLinkStatus: 'in-progress' });

    case 'DOCUMENTS_DISABLE_ACTION_BUTTONS':
      return applyChanges(state, {
        disableActionButtons: true,
        actionMessage: 'Please wait while we process your documents and send them to you.'
      });

    case 'REQUEST_EMAIL_DOCUMENTS_FAILED':
      return applyChanges(state, { documentDetails: undefined, disableActionButtons: false, actionMessage: '' });

    case 'EMAIL_AUTH_LINK_SUCCESS':
      return applyChanges(state, {
        authEmailLinkStatus: 'sent',
        linkActiveDuration: action.payload.linkActiveDuration,
        linkMaximumClicksText: action.payload.linkMaximumClicksText
      });

    case 'EMAIL_AUTH_LINK_FAILED':
      return (() => {
        const error: string = traverseObject<string>(action.payload, 'errors.errors.""') || '';
        const outOfAuthAttempts = error.indexOf('CPW001') === 0;
        return applyChanges(state, { authEmailLinkStatus: 'failed', outOfAuthAttempts });
      })();

    case 'EMAIL_AUTH_LINK_IN_PROGRESS':
      return applyChanges(state, { authEmailLinkStatus: 'in-progress', getSumaryUsingAuthLinkStatus: undefined });

    case 'UPDATE_RENEWAL_STATUS_IN_PROGRESS':
      return applyChanges(state, { disableActionButtons: true, actionMessage: '' });

    case 'DOCUMENTS_SET_ACTION_MESSAGE':
      return (() => {
        const { message, type } = (action.payload || {}) as SetActionMessagePayload;
        return applyChanges(state, { actionMessage: message, actionMessageType: type });
      })();

    case 'TOGGLE_CLAIMS':
      return (() => {
        const { policyNumber, hasIncidentOccurred } = (action.payload || {}) as ToggleClaimsPayload;
        const policyHasIncidentMap = { ...state.policyHasIncidentMap };
        policyHasIncidentMap[policyNumber] = hasIncidentOccurred;
        return applyChanges(state, { policyHasIncidentMap });
      })();

    case 'TOGGLE_POLICY_HISTORY':
      return (() => {
        const { policyNumber, makeVisible } = (action.payload || {}) as ToggleHistoryPayload;
        const historyVisible = { ...state.historyVisible };
        historyVisible[policyNumber] = makeVisible;
        return applyChanges(state, { historyVisible });
      })();

    case 'TAB_SELECTED':
      return (() => {
        const { tab } = (action.payload || {}) as SelectFeatureTabPayload;
        if (tab === 'notifications') {
          cookies.set('showNotificationsBadge', 'false', { expires: 9999 });
        }

        return applyChanges(state, {
          activeTab: tab,
          actionMessage: '',
          showNotificationsBadge: getCookieBoolean('showNotificationsBadge')
        });
      })();

    case 'CLEAR_ALL_FORM_DATA':
    case 'LOG_OFF_SUCCESS':
      return applyChanges(
        initialState,
        action.type === 'LOG_OFF_SUCCESS'
          ? { actionMessage: 'You have been logged off', actionMessageType: 'info' }
          : {}
      );
  }

  return state;
}

export interface DetermineTabsToShowResult {
  tab: FeatureTypes;
  showTabs: FeatureTypes[];
}

export interface DetermineTabsToShowProps {
  tab: FeatureTypes;
  hasDocuments: boolean;
  hasSavedQuotes: boolean;
  showClaims: boolean;
  activeTab: FeatureTypes;
}

export function determineTabsToShow({
  tab,
  hasDocuments,
  hasSavedQuotes,
  showClaims,
  activeTab
}: DetermineTabsToShowProps): DetermineTabsToShowResult {
  let showTabs: FeatureTypes[] = ['quotes', 'documents'];
  if (tab === 'quotes') {
    if (!hasDocuments) {
      showTabs = ['quotes'];
    } else if (!hasSavedQuotes) {
      tab = 'documents';
    }
  } else {
    if (!hasSavedQuotes) {
      showTabs = ['documents'];
    } else if (!hasDocuments) {
      tab = 'quotes';
    }
  }

  if (hasDocuments) {
    if (showClaims) {
      showTabs.push('claims');
    } else if (tab === 'claims') {
      tab = 'documents';
    }
  }

  if (activeTab !== 'documents') {
    tab = activeTab;
  }

  return { showTabs, tab };
}
