import queryString from 'query-string';
import { FeatureTypes, SourceTypes } from '../../components/documents/documentsPageCommon';
import { documentsLoginValidator } from '../../validators/documentsLoginValidator';
import { createErrorModel, ErrorModel, ServerValidationModel } from '../../validators/modelValidator';
import { DispatchFunction, FormTypes } from '../actionTypes';
import { fetchResolver2 } from '../fetchResolver';
import { ModelValidationErrorPayload } from '../modifyFieldAction';

export function sendAuthLinkEmailAction(payload: AuthLinkEmailPayload) {
  return (dispatch: DispatchFunction<AuthLinkEmailDispatchTypes>): void => {
    const { emailAddress, postcode, formType, tab, source } = payload;
    const query: AuthLinkEmailProps = {
      emailAddress,
      postcode,
      tab,
      source
    };

    const errors = documentsLoginValidator(query);
    if (errors.count > 0) {
      dispatch({
        type: 'DOCUMENTS_LOGIN_MODEL_VALIDATION_ERROR',
        payload: { errors, mergeErrors: true }
      });
    } else {
      dispatch({ type: 'DOCUMENTS_LOGIN_MODEL_VALIDATION_OKAY', payload: { errors, mergeErrors: false } }); // clear the errors
    }

    if (emailAddress === '' && postcode === '') {
      // This should not clear form data - it clears the errors.
      // dispatch({ type: 'CLEAR_ALL_FORM_DATA', formType });
      return;
    }

    dispatch({ type: 'EMAIL_AUTH_LINK_IN_PROGRESS', formType });
    sendEmail(query, dispatch, formType);
  };
}

function sendEmail(
  query: AuthLinkEmailProps,
  dispatch: DispatchFunction<AuthLinkEmailDispatchTypes>,
  formType: FormTypes
) {
  const queryText = queryString.stringify(query);
  const url = `/api/sendAuthEmail?${queryText}`;
  return fetchResolver2<EmailDocumentsServerResponse, AuthLinkEmailDispatchTypes>(
    { url, method: 'POST' },
    dispatch,
    (response) => {
      const { errors } = response;
      if ((!errors || errors.isValid) && response.success) {
        dispatch({
          type: 'EMAIL_AUTH_LINK_SUCCESS',
          formType,
          payload: {
            linkActiveDuration: response.linkActiveDuration,
            linkMaximumClicksText: response.linkMaximumClicksText
          }
        });
      } else {
        const payload: FailedToEmailAuthLinkResponsePayload = {
          errors: createErrorModel(response.errors),
          mergeErrors: false
        };
        dispatch({ type: 'EMAIL_AUTH_LINK_FAILED', formType, payload });
      }
    }
  );
}

export interface AuthLinkEmailPayload extends AuthLinkEmailProps {
  formType: FormTypes;
  modelErrors: ErrorModel;
}

export interface EmailDocumentsServerResponse extends EmailedAuthLinkPayload {
  success: boolean;
  errors: ServerValidationModel;
}

interface AuthLinkEmailProps {
  emailAddress: string;
  postcode: string;
  tab: FeatureTypes;
  source: SourceTypes;
}

export interface EmailedAuthLinkPayload {
  linkActiveDuration: string;
  linkMaximumClicksText: string;
}

export type FailedToEmailAuthLinkResponsePayload = ModelValidationErrorPayload;

export type AuthLinkEmailDispatchTypes = FailedToEmailAuthLinkResponsePayload | EmailedAuthLinkPayload;
