import { CSSProperties } from 'react';

export function ExternalLink({ id, text, href, content, className, style }: ExternalLinkProps): JSX.Element {
  return (
    <a id={id} className={className} href={href} target="_blank" rel="noreferrer noopener" style={style}>
      {content || text || href}
    </a>
  );
}

export interface ExternalLinkProps {
  id: string;
  text?: string;
  href: string;
  content?: JSX.Element | string;
  className?: string;
  style?: CSSProperties;
}
