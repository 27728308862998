import { FontIcon } from '../common/fontIcon';
import { IconTypes } from '../iconTypes';

export interface WelcomeMessageProps {
  title: string | JSX.Element;
  icon?: IconTypes;
  subtitle?: string | JSX.Element;
}

export function WelcomeMessagePolicyDocumentation(showLoginText: boolean): JSX.Element {
  return (
    <WelcomeMessage
      key="welcome-message"
      title="Get your policy documentation"
      subtitle={
        showLoginText ? (
          <span>
            To receive your latest policy documentation, please enter the email address and postcode associated with
            your Homeprotect policy and click &quot;Send&quot;.
          </span>
        ) : (
          <></>
        )
      }
    />
  );
}

export function WelcomeMessageClaims(authLinkHasBeenSentMessage: boolean): JSX.Element {
  const title = authLinkHasBeenSentMessage ? 'We’ve sent you a secure access link' : 'Log in to make a claim';
  return <WelcomeMessage key="welcome-message" title={title} />;
}

export function WelcomeMessage({ title, subtitle, icon }: WelcomeMessageProps): JSX.Element {
  title = title || 'Customer portal';
  return (
    <div id="welcome-message" className="row">
      <div className="welcome-card">
        <h1>
          {icon && <FontIcon icon={icon} className="icon-middle" />}
          {title}
        </h1>
        {subtitle && <p className="subtitle">{subtitle}</p>}
      </div>
    </div>
  );
}
