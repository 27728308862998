import React, { CSSProperties } from 'react';
import { ReactComponent as ImportantIcon } from '../../assets/svg/important-icon.svg';
import { ReactComponent as QuoteIcon } from '../../assets/svg/quote-icon.svg';
import { ReactComponent as PricePromiseIcon } from '../../assets/svg/price-promise-icon.svg';

export type SvgIconTypes = 'quote' | 'important' | 'price-promise';

export interface SvgIconProps {
  icon: SvgIconTypes;
  width?: number | undefined;
  style?: CSSProperties;
  className?: string;
}

export function SvgIcon({ icon, width, style, className }: SvgIconProps): JSX.Element {
  style = style || {};
  if (width) {
    style.width = `${width}px`;
  }

  let Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  switch (icon) {
    case 'quote':
      Icon = QuoteIcon;
      break;
    case 'important':
      Icon = ImportantIcon;
      break;
    case 'price-promise':
      Icon = PricePromiseIcon;
      break;
  }

  return (
    <i className={`svg-icon ${className || ''}`} style={style}>
      <Icon />
    </i>
  );
}
