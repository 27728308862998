import queryString from 'query-string';
import { BaseSyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as Actions from '../../actions/index';
import { PageComponent } from '../common/PageComponent';
import { WelcomeMessage } from '../common/WelcomeMessage';
import { wrapInMain } from '../common/wrapInMain';
import { IconTypes } from '../iconTypes';
import { EsigLoginDetails, EsigLoginDetailsProps } from './esigLoginDetails';
import { EsigSignCreditAgreement } from './esigSignCreditAgreement';

export interface EsigDetailsPageState {
  exitVisible: boolean;
}

class EsigDetailsPage extends PageComponent<any, EsigDetailsPageState> {
  constructor(props, context) {
    super(props, context);
    this.state = { exitVisible: true };
    this.modifyFieldChanged = this.modifyFieldChanged.bind(this);
    this.fieldFocussedOrBlurred = this.fieldFocussedOrBlurred.bind(this);
    this.login = this.login.bind(this);
    this.esigChecked = this.esigChecked.bind(this);
    this.esigConfirm = this.esigConfirm.bind(this);
    this.esigSetAgreementRefId = this.esigSetAgreementRefId.bind(this);
  }

  componentDidMount() {
    this.setState({ exitVisible: true });
  }

  getAgremmentFromQuery() {
    let url = document.URL;
    const index = url.indexOf('?');
    url = index >= 0 ? url.substring(index + 1) : url;
    const query = queryString.parse(url);
    return query.Agreement || '';
  }

  modifyFieldChanged(event: BaseSyntheticEvent, validatorName: string): void {
    event.preventDefault();
    const {
      esigSigned,
      surname,
      dateOfBirth,
      dobDay,
      dobMonth,
      dobYear,
      postcode,
      agreementReferenceId,
      userInterface
    } = this.props;
    const { name, value } = event.target;
    const { modelErrors } = userInterface;
    this.props.modifyField({
      fieldName: name,
      value,
      eventType: event.type,
      validatorName,
      esigSigned,
      surname,
      dateOfBirth,
      dobDay,
      dobMonth,
      dobYear,
      postcode,
      agreementReferenceId,
      modelErrors
    });
  }

  login(event: BaseSyntheticEvent): void {
    event.preventDefault();
    const form = this.props.esigDetailLoginForm;
    // eslint-disable-next-line prefer-const
    let { surname, dateOfBirth, dobDay, dobMonth, dobYear, postcode, agreementReferenceId } = form;
    agreementReferenceId = this.getAgremmentFromQuery();
    this.props.esigDetailLogin({
      surname,
      dateOfBirth,
      dobDay,
      dobMonth,
      dobYear,
      postcode,
      agreementReferenceId
    });
    this.props.esgiSetAgreementRefId({ agreementReferenceId });
  }

  esigChecked(event: BaseSyntheticEvent): void {
    const esigAgreed: boolean = event.target.checked;
    this.props.esigAgreed({ esigAgreed });
  }

  esigConfirm(event: BaseSyntheticEvent): void {
    event.preventDefault();
    // eslint-disable-next-line prefer-const
    let { esigSigned, premfinaEsigID } = this.props.esigDetailsForm;
    esigSigned = true;
    this.props.esigConfirmed({ esigSigned, premfinaEsigID });
  }

  esigSetAgreementRefId() {
    let agreementReferenceId = this.props.agreementReferenceId;
    agreementReferenceId = this.getAgremmentFromQuery();
    this.props.esgiSetAgreementRefId({ agreementReferenceId });
  }

  fieldFocussedOrBlurred(event: BaseSyntheticEvent): void {
    event.preventDefault();
    const { name } = event.target;
    this.props.fieldFocussedOrBlurred({
      fieldName: name,
      eventType: event.type
    });
  }

  render() {
    const {
      surname,
      postcode,
      esigSigned,
      premfinaEsigID,
      validationErrors,
      esigDetailLoginForm,
      esigDetailsForm,
      userInterface
    } = this.props;
    const { fetchError, modelErrors, fieldWithFocus, processingRequest } = userInterface;
    const welcomeProps: { title: string; icon: IconTypes; iconWidth: number } = {
      title: 'Credit Agreement e-sign',
      icon: 'sign-dotted',
      iconWidth: 1.5
    };
    const welcomeMessages = {
      notLoggedIn: 'Need to sign your credit agreement? If so, please log in to use our secure signing facility.',
      esigNotComplete: 'e-sign your Homeprotect Credit Agreement below.',
      esigComplete:
        'Thank you. You have succesfully signed your Homeprotect Credit Agreement and we have updated your details.'
    };
    const notLoggedIn = !premfinaEsigID;
    const esigNotComplete = !esigSigned && premfinaEsigID;
    const esigComplete = surname && postcode && esigSigned;

    return wrapInMain(
      <div>
        {notLoggedIn && (
          <div className="col-lg-12">
            <WelcomeMessage subtitle={welcomeMessages.notLoggedIn} {...welcomeProps} />
            <EsigLoginDetails
              {...({
                surname: esigDetailLoginForm.surname,
                dateOfBirth: esigDetailLoginForm.dateOfBirth,
                dobDay: esigDetailLoginForm.dobDay,
                dobMonth: esigDetailLoginForm.dobMonth,
                dobYear: esigDetailLoginForm.dobYear,
                postcode: esigDetailLoginForm.postcode,
                agreementReferenceId: esigDetailLoginForm.esgiSetAgreementRefId,
                fieldWithFocus,
                modifyFieldChanged: (e) => this.modifyFieldChanged(e, 'esigDetailsValidator'),
                login: this.login,
                processingRequest: processingRequest,
                fetchError,
                validationErrors,
                modelErrors
              } as EsigLoginDetailsProps)}
            />
          </div>
        )}
        {esigNotComplete && (
          <div className="col-lg-12">
            <WelcomeMessage subtitle={welcomeMessages.esigNotComplete} {...welcomeProps} />
            <EsigSignCreditAgreement
              {...{
                esigSigned: esigDetailsForm.esigSigned,
                esigAgreed: esigDetailsForm.esigAgreed,
                esigChecked: this.esigChecked,
                confirmEsig: this.esigConfirm,
                processingRequest: processingRequest,
                fetchError,
                validationErrors,
                modelErrors
              }}
            />
          </div>
        )}
        {esigComplete && (
          <div className="col-lg-12" style={{ marginBottom: '20px' }}>
            <WelcomeMessage subtitle={welcomeMessages.esigComplete} {...welcomeProps} />
            <div style={{ textAlign: 'center' }}>
              <a href="/">Log out and return to the homepage</a>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return Object.assign({}, state.esigDetails, {
    esigDetailLoginForm: state.esigDetailLoginForm,
    esigDetailsForm: state.esigDetails,
    userInterface: state.userInterface
  });
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchErrorOccurred: (err) => dispatch(Actions.fetchErrorOccurredAction(err)),
    modifyField: (req) => dispatch(Actions.modifyEsigFieldAction(req)),
    fieldFocussedOrBlurred: (req) => dispatch(Actions.fieldFocussedOrBlurredAction(req)),
    esigDetailLogin: (req) => dispatch(Actions.esigLoginAction(req)),
    esigAgreed: (req) => dispatch(Actions.esigCheckedAction(req)),
    esigConfirmed: (req) => dispatch(Actions.esigConfirmedAction(req)),
    esgiSetAgreementRefId: (req) => dispatch(Actions.esigSetAgreementRefIdAction(req))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EsigDetailsPage as any));
