/* eslint-disable @typescript-eslint/ban-types */
import { ErrorModel } from '../../validators/modelValidator';
import { ButtonControl } from '../controls/ButtonControl';
import { IconFieldRowControl } from '../controls/IconFieldRowControl';
import { TextInputControl } from '../controls/TextInputControl';
import './StripeControl.css';

export interface StripeControlProps {
  cardholderName: string;
  isPaymentButtonDisabled: boolean;
  errorMessage: string;
  onChange: Function;
  onCardholderNameBlur: Function;
  modelErrors: ErrorModel;
  paymentSentForProcessing: Function;
  fieldWithFocus?: string;
}

export function StripeControl({
  cardholderName,
  onChange,
  onCardholderNameBlur,
  isPaymentButtonDisabled,
  errorMessage,
  modelErrors,
  paymentSentForProcessing,
  fieldWithFocus
}: StripeControlProps): JSX.Element {
  const cardholderNameId = 'cardholderName',
    cardholderNameLabel = 'Cardholder name',
    cardholderNameError = modelErrors.getError(cardholderNameId, cardholderNameLabel);
  return (
    <form action="/api/chargePayment" method="post" id="payment-form" onSubmit={(e) => e.preventDefault()}>
      <IconFieldRowControl
        icon="about-you"
        width={2}
        content={
          <TextInputControl
            name={cardholderNameId}
            value={cardholderName}
            label={cardholderNameLabel}
            placeholder="e.g. Mr John Smith"
            fieldWithFocus={fieldWithFocus}
            onChange={onChange as any}
            onBlur={onCardholderNameBlur}
            modelErrors={modelErrors}
          />
        }
      />

      <IconFieldRowControl
        icon="card"
        width={2}
        content={
          <div>
            <h2 className="input-label">Card number</h2>
            <div id="card-number-element"></div>
          </div>
        }
      />

      <IconFieldRowControl
        width={2}
        content={
          <div>
            <h2 className="input-label">Card expiry</h2>
            <div id="card-expiry-element"></div>
          </div>
        }
      />

      <IconFieldRowControl
        width={2}
        content={
          <div>
            <h2 className="input-label">Card security code (CVC)</h2>
            <div id="card-cvc-element"></div>
          </div>
        }
      />

      <div id="stripe-errors" className="alert alert-danger" style={{ textAlign: 'center', marginTop: '1rem' }}>
        <p>{cardholderNameError}</p>
        <div id="card-errors">{errorMessage}</div>
      </div>

      <ButtonControl
        name="card-button"
        label="Make payment"
        disabled={isPaymentButtonDisabled || modelErrors.count > 0}
        onClick={paymentSentForProcessing as any}
        style={{ marginTop: '1rem' }}
      />
    </form>
  );
}
