import { lodash } from '@avantia/lodash';
import { StandardAction } from '../actions/actionTypes';
import { SortCodeFieldModel } from '../models/SortCodeFieldModel';
import { getInitialState } from './initialState';
import { applyChanges, trimFieldValue } from './reducerLibrary';
import { BankInfoFormState } from './stateTypes';

const initialState = getInitialState().bankInfoForm;

export function bankInfoFormReducer(state = initialState, action: StandardAction): BankInfoFormState {
  switch (action.type) {
    case 'FIELD_CHANGED':
      return (() => {
        // eslint-disable-next-line prefer-const
        let { fieldName, value, eventType } = action.payload;
        if (lodash.has(state, fieldName)) {
          value = trimFieldValue(value, eventType);
          const updated: Partial<BankInfoFormState> = {};
          if (fieldName === 'sortCode') {
            updated[fieldName] = new SortCodeFieldModel({ name: 'sortCode', value });
          } else {
            updated[fieldName] = value;
          }

          return applyChanges(state, updated);
        }

        return state;
      })();

    case 'BANK_INFO_UPDATED':
      return (() => {
        const { accountNumber, sortCode } = action.payload;
        if (accountNumber && sortCode) {
          return initialState;
        }

        return state;
      })();

    case 'CLEAR_ALL_FORM_DATA':
    case 'LOG_OFF_SUCCESS':
      return initialState;

    default:
      break;
  }

  return state;
}
