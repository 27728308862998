import { CSSProperties } from 'react';
import { FormControl } from 'react-bootstrap';
import { ErrorModel } from '../../validators/modelValidator';
import { FormStyleTypes, getControlStyle } from '../styleLibrary';

export interface TextInputControlParams {
  label: string;
  name: string;
  value: string;
  type?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onFocus?: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onBlur?: Function;
  prefixLabel?: any;
  placeholder?: string;
  button?: any;
  ariaLabel?: string;
  modelErrors: ErrorModel;
  className?: string;
  fieldWithFocus?: string;
  width?: number;
  style?: CSSProperties;
  errorStyle?: CSSProperties;
  formStyle?: FormStyleTypes;
  autoComplete?: boolean;
}

export function TextInputControl({
  label,
  name,
  value,
  type,
  onChange,
  onFocus,
  onBlur,
  prefixLabel,
  placeholder,
  button,
  ariaLabel,
  modelErrors,
  className,
  fieldWithFocus,
  formStyle,
  autoComplete
}: TextInputControlParams): JSX.Element {
  const hasError = !!modelErrors.getError(name, label);
  const autoFocus = name === fieldWithFocus;
  const style = getControlStyle(formStyle, 'TextInputControl', hasError);
  return (
    <div>
      <div>
        <h2 className="input-label">{label}</h2>
        <div className="input-group-prepend">
          {prefixLabel && <span className="input-group-text">{prefixLabel}</span>}
          <FormControl
            id={name}
            name={name}
            type={type || 'text'}
            value={value}
            onChange={onChange as any}
            onBlur={
              ((e) => {
                onChange(e);
                if (onBlur && onBlur !== onChange) {
                  onBlur(e);
                }
              }) as any
            }
            onFocus={onFocus as any}
            autoComplete={autoComplete === false ? 'off' : 'on'}
            placeholder={placeholder}
            aria-label={ariaLabel}
            style={style}
            className={className}
            autoFocus={autoFocus}
          />
          {button}
        </div>
      </div>
      <div
        style={{
          paddingTop: '.5em',
          boxSizing: 'content-box'
        }}></div>
    </div>
  );
}
