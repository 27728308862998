import { FetchError } from '../../actions/actionTypes';
import { ErrorModel, ServerValidationModel } from '../../validators/modelValidator';
import { ErrorSection } from '../common/ErrorSection';
import { getErrorsAsArray } from '../componentLibrary';
import { ButtonControl } from '../controls/ButtonControl';
import { IconFieldRowControl } from '../controls/IconFieldRowControl';
import { PanelControl } from '../controls/PanelControl';
import { SpinnerControl } from '../controls/SpinnerControl';
import { TextInputControl } from '../controls/TextInputControl';
import './CustomerPayDetails.css';

export interface LoginDetailsProps {
  policyNumber: string;
  postcode: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  modifyFieldChanged: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  getPaymentToken: Function;
  processingRequest: boolean;
  validationErrors: ErrorModel | ServerValidationModel;
  fetchError: FetchError;
  modelErrors: ErrorModel;
}

export function LoginDetails({
  policyNumber,
  postcode,
  modifyFieldChanged,
  getPaymentToken,
  processingRequest,
  validationErrors,
  fetchError,
  modelErrors
}: LoginDetailsProps): JSX.Element {
  const policyNumberError = modelErrors.getError('policyNumber', 'Policy number');
  const postcodeReferenceError = modelErrors.getError('postcode', 'Postcode');
  const validationErrorMessage = getErrorsAsArray(validationErrors, undefined)[0] || '';
  const fetchErrorMessage = fetchError && fetchError.message ? fetchError.message : '';
  const loginDisabled = processingRequest; // || modelErrors.count > 0 || postcode.length === 0 || policyNumber === '';
  const loadingText = 'Please wait while we log you in';
  const bodyElement = (
    <div>
      <PanelControl
        content={
          <div>
            <IconFieldRowControl
              width={5}
              content={
                <div>
                  <div>
                    <TextInputControl
                      name="policyNumber"
                      label="Homeprotect policy number"
                      placeholder="e.g. PO/L123678"
                      ariaLabel="Policy number"
                      value={policyNumber}
                      modelErrors={modelErrors}
                      onChange={modifyFieldChanged}
                    />
                  </div>
                  <div>
                    <TextInputControl
                      className="radiusRound-form-control"
                      name="postcode"
                      label="Postcode (of insured property)"
                      placeholder="e.g. KT1 1PH"
                      value={postcode}
                      ariaLabel="Postcode"
                      modelErrors={modelErrors}
                      onChange={modifyFieldChanged}
                    />
                  </div>
                </div>
              }
            />

            <div
              className="row div-block"
              style={{
                marginBottom: '10px',
                paddingLeft: '20px',
                paddingRight: '20px'
              }}>
              <ButtonControl name="Login" label="Log in" onClick={getPaymentToken} disabled={loginDisabled} />
            </div>
            <SpinnerControl
              visible={processingRequest}
              positionLeftInt={47}
              spinnerText={loadingText}
              typeOfSpinner="Ring"
            />
            <ErrorSection
              processingRequest={processingRequest}
              errors={[
                policyNumberError || '',
                postcodeReferenceError || '',
                fetchErrorMessage,
                validationErrorMessage
              ]}
            />
          </div>
        }
      />
    </div>
  );
  return <div>{bodyElement}</div>;
}
