import { FetchError } from '../../actions/actionTypes';
import { ErrorModel, getFirstError, ServerValidationModel } from '../../validators/modelValidator';
import { ErrorSection } from '../common/ErrorSection';
import { ButtonControl } from '../controls/ButtonControl';
import { IconFieldRowControl } from '../controls/IconFieldRowControl';
import { PanelControl } from '../controls/PanelControl';
import { SpinnerControl } from '../controls/SpinnerControl';
import { TextInputControl } from '../controls/TextInputControl';
import '../defaulters/CustomerPayDetails.css';

export interface LoginDetailsBankProps {
  policyNumber: string;
  postcode: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  modifyFieldChanged: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  login: Function;
  validationErrors: ErrorModel | ServerValidationModel;
  processingRequest: boolean;
  fetchError: FetchError;
  modelErrors: ErrorModel;
}

const LoginDetailsBank = ({
  policyNumber,
  postcode,
  modifyFieldChanged,
  login,
  processingRequest,
  validationErrors,
  fetchError,
  modelErrors
}: LoginDetailsBankProps): JSX.Element => {
  const policyNumberError = modelErrors.getError('policyNumber', 'Policy number') || '';
  const postcodeReferenceError = modelErrors.getError('postcode', 'Postcode') || '';
  const validationErrorMessage = validationErrors ? getFirstError(validationErrors) : '';
  const fetchErrorMessage = fetchError && fetchError.message ? fetchError.message : '';
  const loadingText = 'Please wait while we log you in';
  const disableLogin = processingRequest; // || modelErrors.count > 0 || postcode.length === 0 || !policyNumber;
  const bodyElement = (
    <div>
      <PanelControl
        content={
          <div>
            <IconFieldRowControl
              width={5}
              content={
                <div>
                  <div>
                    <TextInputControl
                      name="policyNumber"
                      label="Homeprotect policy number"
                      placeholder="e.g. PO/L123678"
                      ariaLabel="Policy number"
                      value={policyNumber}
                      modelErrors={modelErrors}
                      onChange={modifyFieldChanged}
                    />
                  </div>
                  <div>
                    <TextInputControl
                      className="radiusRound-form-control"
                      name="postcode"
                      label="Postcode (of insured property)"
                      placeholder="e.g. KT1 1PH"
                      value={postcode}
                      ariaLabel="Postcode"
                      modelErrors={modelErrors}
                      onChange={modifyFieldChanged}
                    />
                  </div>
                </div>
              }
            />

            <div
              className="row div-block"
              style={{
                marginBottom: '10px',
                paddingLeft: '20px',
                paddingRight: '20px'
              }}>
              <ButtonControl name="Login" label="Log in" onClick={login} disabled={disableLogin} />
            </div>
            <SpinnerControl
              visible={processingRequest}
              positionLeftInt={47}
              spinnerText={loadingText}
              typeOfSpinner="Ring"
            />
            <ErrorSection
              processingRequest={processingRequest}
              errors={[policyNumberError, postcodeReferenceError, fetchErrorMessage, validationErrorMessage]}
            />
          </div>
        }
      />
    </div>
  );
  return <div>{bodyElement}</div>;
};

export { LoginDetailsBank };
