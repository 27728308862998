import { StandardDispatchFunction } from '../actionTypes';

export function voidUpdateAmountAction() {
  return (dispatch: StandardDispatchFunction): void => {
    dispatch({ type: 'AJAX_CALL_STARTED' });
    setTimeout(() => {
      dispatch({
        type: 'FIELD_FOCUSSED_ACTION',
        payload: { fieldName: 'cardholderName', eventType: 'focus' }
      });
      dispatch({ type: 'AJAX_CALL_COMPLETED' });
    }, 500);
  };
}
