import { IconFieldRowControl } from '../controls/IconFieldRowControl';
import { PanelControl } from '../controls/PanelControl';

export function CustomerDetailsBankUpdated(): JSX.Element {
  return (
    <div>
      <PanelControl
        content={
          <div>
            <IconFieldRowControl
              width={5}
              content={
                <div>
                  <div className="alert alert-success" style={{ textAlign: 'center' }}>
                    Your bank details have been updated
                  </div>
                </div>
              }
            />
          </div>
        }
      />
    </div>
  );
}
