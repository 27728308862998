/* eslint-disable prefer-const */
import jQuery from 'jquery';
import { BaseSyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchErrorOccurredAction } from '../../actions';
import { FormTypes, MessageTypes } from '../../actions/actionTypes';
import { emailDocumentsAction, EmailDocumentsPayload } from '../../actions/documents/emailDocumentsAction';
import { FormTypePayload } from '../../actions/documents/FormTypePayload';
import {
  DocumentSummaryItem,
  getDocumentSummaryUsingAuthLinkAction,
  GetDocumentSummaryUsingAuthLinkPayload,
  SavedQuoteItem
} from '../../actions/documents/getDocumentSummaryUsingAuthLinkAction';
import {
  redirectToClaimsPortalAction,
  RedirectToClaimsPortalPayload
} from '../../actions/documents/redirectToClaimsPortalAction';
import { selectFeatureTabAction, SelectFeatureTabPayload } from '../../actions/documents/selectFeatureTabAction';
import { AuthLinkEmailPayload, sendAuthLinkEmailAction } from '../../actions/documents/sendAuthLinkEmailAction';
import { setActionMessageAction, SetActionMessagePayload } from '../../actions/documents/setActionMessageAction';
import { toggleClaimsAction, ToggleClaimsPayload } from '../../actions/documents/toggleClaimsAction';
import { toggleHistoryAction, ToggleHistoryPayload } from '../../actions/documents/toggleHistoryAction';
import {
  updateAutoRenewalStatusAction,
  UpdateAutoRenewalStatusPayload
} from '../../actions/documents/updateAutoRenewalStatusAction';
import { logOffAction } from '../../actions/logOffAction';
import { modifyFieldAction, ModifyFieldPayload } from '../../actions/modifyFieldAction';
import { getConfig } from '../../configuration';
import { HistoryVisibleType, PolicyHasIncidentMap } from '../../reducers/documentsReducer';
import { GlobalState } from '../../reducers/stateTypes';
import { FontIcon } from '../common/fontIcon';
import { PageComponent } from '../common/PageComponent';
import { getErrorsAsArray } from '../componentLibrary';
import { ModalControl } from '../controls/ModalControl';
import { SetActiveTabFunc, TabControl, TabControlMap } from '../controls/TabControl';
import { FormStyleTypes } from '../styleLibrary';
import { AlertMessageWrapper } from './AlertMessageWrapper';
import { AnalyticsPageProps, sendAnalytics } from './analytics';
import { BootstrapScreenSize } from './BootstrapScreenSize';
import * as desktop from './desktop';
import { determineScreens } from './determineScreens';
import { DocumentFooter } from './DocumentFooter';
import {
  AuthLinkWillBeEmailedScreen,
  DispatchProps,
  DocumentsPageProps,
  DocumentsPageStateProps,
  EmailDocumentsFunc,
  FeatureTypes,
  LogOffFunc,
  NoQuotesScreen,
  processMaybeAuthLink,
  RedirectToClaimsPortalFunc,
  SetActionMessageFunc,
  SourceTypes,
  ToggleHistoryFunc,
  UpdateAutoRenewalStatusFunc,
  UpdateAutoRenewalStatusProps,
  Viewport,
  ViewportProps
} from './documentsPageCommon';
import { ErrorSection } from './ErrorSection';
import { Header } from './Header';
import { LoginScreen } from './LoginScreen';
import * as mobile from './mobile';
import { SpinnerControl } from './SpinnerControl';
import './style/documents.scss';
import { WelcomeMessageClaims, WelcomeMessagePolicyDocumentation } from './WelcomeMessage';

const formType: FormTypes = 'DocumentLogin';
const { environment, claimsPortalUrl: claimsUrl } = getConfig();

class DocumentsPageComponent extends PageComponent<DocumentsPageProps, GlobalState> {
  constructor(props, context) {
    super(props, context);
    this.modifyFieldChanged = this.modifyFieldChanged.bind(this);
    this.sendAuthLinkEmail = this.sendAuthLinkEmail.bind(this);
    this.emailDocuments = this.emailDocuments.bind(this);
    this.updateAutoRenewalStatus = this.updateAutoRenewalStatus.bind(this);
    this.setActionMessage = this.setActionMessage.bind(this);
    this.toggleHistory = this.toggleHistory.bind(this);
    this.toggleClaims = this.toggleClaims.bind(this);
    this.redirectToClaimsPortal = this.redirectToClaimsPortal.bind(this);
    this.logOff = this.logOff.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
  }

  componentDidUpdate(prevProps: DocumentsPageProps, prevState: GlobalState) {
    this.scrollAlertIntoView();
    if (prevProps && prevState && prevState.documents !== this.state.documents) {
      this.componentUpdated();
    }
  }

  componentDidMount() {
    this.componentUpdated();
    this.scrollAlertIntoView();
  }

  componentUpdated() {
    const { history, homelink } = this.props;
    const parts = homelink.split('#');
    const documentsUrl = parts[0];
    const hashValue = '#' + parts[1];
    const location = history.location;
    const { hash, pathname } = location;
    const { authType, pathPrefix, authToken, tab, source } = processMaybeAuthLink(pathname);
    if (authType && authType !== 'none') {
      history.push({ pathname: pathPrefix });
      sendAnalytics({
        props: this.props,
        event: 'Auth Link Landing Screen',
        detail: { authToken, tab, source, authType }
      });
      this.props.getDocumentSummaryUsingAuthLink({ formType, authToken, tab, authType, source });
    } else {
      // try to load from session values (if they exist).
      this.props.getDocumentSummaryUsingAuthLink({ formType, authToken: '', authType, tab, source });
    }

    if (hash === hashValue) {
      history.push({
        pathname: documentsUrl,
        search: ''
      });
    }
  }

  scrollAlertIntoView() {
    const alertContainer = jQuery('.alert');
    if (alertContainer && alertContainer.length > 0 && !alertContainer.data('seen')) {
      alertContainer.data('seen', true);
      const offset = alertContainer.offset() as JQuery.Coordinates;
      const alertContainerHeight = alertContainer.height() as number;
      const containerBottom = offset.top + alertContainerHeight;
      const jqwindow = jQuery(window);
      const windowHeight = jqwindow.height() as number;
      const viewBottom = (jqwindow.scrollTop() as number) + windowHeight;
      if (containerBottom > viewBottom) {
        jQuery([document.documentElement, document.body]).animate(
          { scrollTop: containerBottom + 25 - windowHeight },
          2000
        );
      }
    }
  }

  modifyFieldChanged(event: BaseSyntheticEvent) {
    event.preventDefault();
    const { userInterface } = this.props;
    const { name, value } = event.target;
    const { modelErrors } = userInterface;
    this.props.modifyField({
      fieldName: name,
      formType,
      value,
      eventType: event.type,
      validatorName: 'documentsLogin',
      modelErrors
    });
  }

  sendAuthLinkEmail(event: BaseSyntheticEvent): void {
    event.preventDefault();
    const { userInterface, emailAddress, postcode } = this.props;
    const { modelErrors } = userInterface;
    const { history } = this.props;
    const { pathname } = history.location;
    const { tab, source } = processMaybeAuthLink(pathname);
    this.props.sendAuthLinkEmail({
      formType,
      emailAddress,
      postcode,
      modelErrors,
      tab,
      source
    });
  }

  setActiveTab(tab: string): void {
    this.props.selectFeatureTab({ tab: tab as FeatureTypes, formType });
  }

  logOff(event: BaseSyntheticEvent): void {
    event.preventDefault();
    sendAnalytics({ props: this.props, event: 'Log Off Click', checkRepeats: false });
    this.props.logOff({ formType });
  }

  emailDocuments(
    event: BaseSyntheticEvent,
    policyNumber: string | undefined,
    documentArchiveId: number | undefined
  ): void {
    event.preventDefault();
    const { userInterface, emailAddress, postcode } = this.props;
    const { modelErrors } = userInterface;
    sendAnalytics({
      props: this.props,
      event: 'Email Documents Click',
      checkRepeats: false,
      detail: {
        policyNumber,
        documentArchiveId
      }
    });
    this.props.emailDocuments({
      formType,
      emailAddress,
      postcode,
      policyNumber,
      documentArchiveId,
      modelErrors
    });
  }

  updateAutoRenewalStatus({ event, isAutoRenewal, document }: UpdateAutoRenewalStatusProps): void {
    event.preventDefault();
    const { userInterface } = this.props;
    const { modelErrors } = userInterface;
    const { policyDetailsId } = document;
    sendAnalytics({
      props: this.props,
      event: 'Auto Renewal Click',
      checkRepeats: false,
      detail: {
        policyNumber: policyDetailsId, // TODO - Check with Gabriel if this is correct.
        isAutoRenewal
      }
    });
    this.props.updateAutoRenewalStatus({
      formType,
      modelErrors,
      isAutoRenewal,
      document
    });
  }

  setActionMessage(event: BaseSyntheticEvent, message: string, type: MessageTypes) {
    this.props.setActionMessage({ formType, message, type });
  }

  toggleHistory(event: BaseSyntheticEvent, policyNumber: string, makeVisible: boolean) {
    this.props.toggleHistory({ formType, policyNumber, makeVisible });
  }

  toggleClaims(event: BaseSyntheticEvent, policyNumber: string, hasIncidentOccurred: boolean) {
    this.props.toggleClaims({ formType, policyNumber, hasIncidentOccurred });
  }

  redirectToClaimsPortal() {
    const { emailAddress, postcode } = this.props;
    this.props.redirectToClaimsPortal({ formType, emailAddress, postcode, claimsUrl, analyticsProps: this.props });
  }

  render() {
    const formStyle: FormStyleTypes = 'Documents';
    let {
      hasDataLoaded,
      emailAddress,
      postcode,
      documentDetails,
      documentSummary: policyDocuments,
      savedQuotes,
      actionMessage,
      actionMessageType,
      userInterface,
      getSumaryUsingAuthLinkStatus: sendAuthEmailStatus,
      disableActionButtons,
      authEmailLinkStatus,
      linkActiveDuration,
      linkMaximumClicksText,
      outOfAuthAttempts,
      historyVisible,
      policyHasIncidentMap,
      activeTab,
      showTabs,
      showNotificationsBadge,
      source,
      history
    } = this.props;
    const { processingRequest, fetchError, modelErrors } = userInterface;
    const { pathname } = history.location;
    const { tab: feature } = processMaybeAuthLink(pathname);
    const errors = getErrorsAsArray(modelErrors, fetchError);
    const onChange = this.modifyFieldChanged;
    policyDocuments = policyDocuments || [];
    savedQuotes = savedQuotes || [];

    // actionMessage = actionMessage || 'This is a test message';
    const showAllScreens = false;
    const show = determineScreens({
      hasDataLoaded,
      showAllScreens,
      documentSummary: policyDocuments,
      savedQuotes,
      authEmailLinkStatus,
      disableActionButtons,
      actionMessage,
      documentDetails,
      sendAuthEmailStatus,
      outOfAuthAttempts,
      errors
    });

    let actionMessageWrapper: JSX.Element | undefined;
    const items: (JSX.Element | JSX.Element[])[] = [];
    const hasErrors = errors && errors.length > 0;

    const {
      summaryScreen,
      noDataScreen,
      unableToLogin,
      loginScreen,
      authInProgressMessage,
      showLoadingSpinner,
      authLinkHasBeenSentMessage
    } = show;

    if (show.actionMessage && !showLoadingSpinner) {
      actionMessageWrapper = (
        <div className="row">
          {AlertMessageWrapper(
            'action-message',
            <div className={`alert alert-${actionMessageType || 'secondary'}`}>{actionMessage}</div>
          )}
        </div>
      );
    }

    if (summaryScreen) {
      sendAnalytics({
        props: this.props,
        event: 'Summary Screen',
        delay: 750,
        detail: {
          documentCount: policyDocuments.length,
          savedQuotes: savedQuotes.length,
          activeTab
        }
      });
      items.push(
        renderDocsSummaryScreen({
          policyDocuments,
          savedQuotes,
          historyVisible,
          policyHasIncidentMap,
          disableActionButtons,
          activeTab,
          showTabs,
          source,
          actionMessageWrapper,
          analyticsProps: this.props,
          toggleHistory: this.toggleHistory,
          redirectToClaimsPortal: this.redirectToClaimsPortal,
          setActionMessage: this.setActionMessage,
          emailDocuments: this.emailDocuments,
          updateAutoRenewalStatus: this.updateAutoRenewalStatus,
          logOff: this.logOff,
          setActiveTab: this.setActiveTab,
          showNotificationsBadge
        })
      );
    }

    if (noDataScreen) {
      sendAnalytics({ props: this.props, event: 'No Data Screen' });
      items.push([Header(undefined), NoQuotesScreen({ showSalutation: true, feature: 'quotes' })]);
    }

    if (loginScreen) {
      sendAnalytics({
        props: this.props,
        event: 'Login Screen',
        detail: hasErrors ? { errors } : undefined,
        delay: 500,
        onlyWhen: (p) => !!p.pageTitle
      });
      items.push(
        LoginScreen({
          emailAddress,
          modelErrors,
          formStyle,
          postcode,
          processingRequest,
          actionMessageWrapper,
          onChange,
          getAuthLinkEmail: this.sendAuthLinkEmail,
          feature
        })
      );
    }

    if (unableToLogin) {
      let welcomeMessage: JSX.Element;
      if (feature === 'claims') {
        welcomeMessage = WelcomeMessageClaims(authLinkHasBeenSentMessage);
      } else {
        welcomeMessage = WelcomeMessagePolicyDocumentation(false);
      }

      items.push([Header(undefined), welcomeMessage]);
    }

    if (authInProgressMessage) {
      items.push(
        AlertMessageWrapper(
          'spinner-1',
          <SpinnerControl message="Please wait while we authenticate you." widthClass="table-width" />
        )
      );
    }

    if (authLinkHasBeenSentMessage) {
      sendAnalytics({ props: this.props, event: 'Auth Link Emailed Screen' });
      items.push(AuthLinkWillBeEmailedScreen(linkActiveDuration, linkMaximumClicksText, feature));
    }

    if (showLoadingSpinner) {
      items.push(
        AlertMessageWrapper(
          'spinner-2',
          <SpinnerControl
            message={actionMessage || `Please wait while we process your request.`}
            widthClass={'table-width'}
          />
        )
      );
    }

    if (hasErrors) {
      // sendAnalytics(this.props, 'errors-displayed', errors);
      items.push(
        AlertMessageWrapper(
          'error-section',
          <ErrorSection errors={errors} processingRequest={processingRequest} className="row" feature={feature} />
        )
      );
    }

    return (
      <main className="container">
        {<BootstrapScreenSize environment={environment} />}
        {items}
        <DocumentFooter />
      </main>
    );
  }
}

interface DocsSummaryScreenProps {
  policyDocuments: DocumentSummaryItem[];
  savedQuotes: SavedQuoteItem[];
  historyVisible: HistoryVisibleType;
  policyHasIncidentMap: PolicyHasIncidentMap;
  disableActionButtons: boolean;
  activeTab: FeatureTypes;
  showTabs: FeatureTypes[];
  source: SourceTypes;
  actionMessageWrapper: JSX.Element | undefined;
  analyticsProps: AnalyticsPageProps;
  toggleHistory: ToggleHistoryFunc;
  redirectToClaimsPortal: RedirectToClaimsPortalFunc;
  setActionMessage: SetActionMessageFunc;
  emailDocuments: EmailDocumentsFunc;
  updateAutoRenewalStatus: UpdateAutoRenewalStatusFunc;
  logOff: LogOffFunc;
  setActiveTab: SetActiveTabFunc;
  showNotificationsBadge: boolean;
}

function renderDocsSummaryScreen({
  policyDocuments,
  savedQuotes,
  historyVisible,
  policyHasIncidentMap,
  disableActionButtons,
  activeTab,
  showTabs,
  source,
  actionMessageWrapper,
  analyticsProps,
  toggleHistory,
  redirectToClaimsPortal,
  setActionMessage,
  emailDocuments,
  updateAutoRenewalStatus,
  logOff,
  setActiveTab,
  showNotificationsBadge
}: DocsSummaryScreenProps) {
  const showClaims = showTabs.indexOf('claims') >= 0;
  const desktopViewportProps: ViewportProps = {
    policyDocuments,
    savedQuotes,
    historyVisible,
    policyHasIncidentMap,
    disableActionButtons,
    toggleHistory,
    redirectToClaimsPortal,
    showClaims,
    setActionMessage,
    setActiveTab,
    emailDocuments,
    updateAutoRenewalStatus,
    DocumentContentRow: desktop.DocumentContentRow,
    DocumentCard: desktop.DocumentCard,
    HistoryRow: desktop.HistoryRow,
    viewType: 'desktop',
    analyticsProps,
    source
  };

  const {
    quoteTab: desktopQuoteTab,
    summaryTab: desktopSummaryTab,
    // notificationsTab: desktopNotificationsTab,
    administrationTab: desktopAdministrationTab,
    claimsTab: desktopClaimsTab
  } = Viewport(desktopViewportProps);

  const mobileViewPortProps: ViewportProps = {
    policyDocuments,
    savedQuotes,
    historyVisible,
    policyHasIncidentMap,
    disableActionButtons,
    toggleHistory,
    redirectToClaimsPortal,
    showClaims,
    setActionMessage,
    setActiveTab,
    emailDocuments,
    updateAutoRenewalStatus,
    DocumentContentRow: mobile.DocumentContentRow,
    DocumentCard: mobile.DocumentCard,
    HistoryRow: mobile.HistoryRow,
    viewType: 'mobile',
    analyticsProps,
    source
  };

  const mobileViewport = Viewport(mobileViewPortProps);
  const {
    quoteTab: mobileQuoteTab,
    summaryTab: mobileSummaryTab,
    // notificationsTab: mobileNotificationsTab,
    administrationTab: mobileAdministrationTab,
    claimsTab: mobileClaimsTab
  } = mobileViewport;
  const { title, surname } = policyDocuments[0] || savedQuotes[0] || {};
  const tabs: TabControlMap = {};
  if (showTabs.indexOf('quotes') >= 0) {
    if (savedQuotes.length > 0) {
      tabs.quotes = { name: 'Quotes', element: [...desktopQuoteTab, ...mobileQuoteTab] };
    } else {
      tabs.quotes = { name: 'Quotes', element: [NoQuotesScreen({ showSalutation: false, feature: 'quotes' })] };
    }
  }

  if (showTabs.indexOf('documents') >= 0) {
    if (policyDocuments.length > 0) {
      tabs.documents = { name: 'Policy Documents', element: [...desktopSummaryTab, ...mobileSummaryTab] };
      // tabs.notifications = { name: 'Notifications', element: [desktopNotificationsTab, mobileNotificationsTab] };
      if (showClaims) {
        tabs.claims = { name: 'Claims', element: [...desktopClaimsTab, ...mobileClaimsTab] };
      }

      if (desktopAdministrationTab.length) {
        const administrationTabTitle = <FontIcon faIcon="cog" />;
        tabs.administration = {
          name: administrationTabTitle,
          element: [...desktopAdministrationTab, ...mobileAdministrationTab]
        };
      }
    } else {
      tabs.documents = {
        name: 'Policy Documents',
        element: [NoQuotesScreen({ showSalutation: false, feature: 'documents' })]
      };
    }
  }

  return (
    <div key="summary">
      {Header(logOff)}
      {actionMessageWrapper}
      <div className={`cards policy-documents`}>
        <h1 key="salutation" className="salutation-header">
          Hello {title} {surname}
        </h1>
        <TabControl
          id="docsTabs"
          activeTab={activeTab}
          tabs={tabs}
          setActiveTab={setActiveTab}
          showNotificationsBadge={showNotificationsBadge}
        />
      </div>
      <ModalControl />
    </div>
  );
}

function mapStateToProps(state: GlobalState): DocumentsPageStateProps {
  const { userInterface, documents } = state;
  return {
    ...documents,
    userInterface
  };
}

// eslint-disable-next-line @typescript-eslint/ban-types
function mapDispatchToProps(dispatch: Function): DispatchProps {
  return {
    fetchErrorOccurred: (err: any) => dispatch(fetchErrorOccurredAction(err)),
    modifyField: (req: ModifyFieldPayload) => dispatch(modifyFieldAction(req)),
    emailDocuments: (req: EmailDocumentsPayload) => dispatch(emailDocumentsAction(req)),
    sendAuthLinkEmail: (req: AuthLinkEmailPayload) => dispatch(sendAuthLinkEmailAction(req)),
    getDocumentSummaryUsingAuthLink: (req: GetDocumentSummaryUsingAuthLinkPayload) =>
      dispatch(getDocumentSummaryUsingAuthLinkAction(req)),
    setActionMessage: (req: SetActionMessagePayload & FormTypePayload) => dispatch(setActionMessageAction(req)),
    updateAutoRenewalStatus: (req: UpdateAutoRenewalStatusPayload) => dispatch(updateAutoRenewalStatusAction(req)),
    toggleHistory: (req: ToggleHistoryPayload & FormTypePayload) => dispatch(toggleHistoryAction(req)),
    toggleClaims: (req: ToggleClaimsPayload & FormTypePayload) => dispatch(toggleClaimsAction(req)),
    redirectToClaimsPortal: (req: RedirectToClaimsPortalPayload & FormTypePayload) =>
      dispatch(redirectToClaimsPortalAction(req)),
    logOff: (req: FormTypePayload) => dispatch(logOffAction(req)),
    selectFeatureTab: (req: SelectFeatureTabPayload & FormTypePayload) => dispatch(selectFeatureTabAction(req))
  };
}

const DocumentsPage = withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentsPageComponent as any));
export { DocumentsPage };
