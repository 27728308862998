import { ErrorModel, ModelToValidate, modelValidator, ProcessRulesOptions } from './modelValidator';
import * as Rules from './validatorRules';

//surname, dateOfBirth, postcode,
//agreementRef masy need to add a rule eventually
export function esigDetailsValidator(model: ModelToValidate, options?: ProcessRulesOptions | undefined): ErrorModel {
  options = options || {};
  const warnings = {};
  const errors = {
    postcode: [Rules.RequiredRule, Rules.createPostcodeRule()],
    dateOfBirth: [
      Rules.createRegexRule(
        /^((((([1-9])|(0[1-9])|(1\d)|(2[0-8]))(\/)(([1-9])|(0[1-9])|(1[0-2])))|((31\/(((0[13578])|([13578]))|(1[02])))|((29|30)(\/)(((0[1,3-9])|([1,3-9]))|(1[0-2])))))(\/)((20[0-9][0-9]))|(((([1-9])|(0[1-9])|(1\d)|(2[0-8]))\/(([1-9])|(0[1-9])|(1[0-2])))|((31\/(((0[13578])|([13578]))|(1[02])))|((29|30)\/(((0[1,3-9])|([1,3-9]))|(1[0-2])))))\/((19[0-9][0-9]))|(29\/(02|2)\/20(([02468][048])|([13579][26])))|(29\/(02|2)\/19(([02468][048])|([13579][26]))))$/,
        'The {name} is not valid, e.g. 23/12/1999.'
      ),
      Rules.RequiredRule
    ],
    dobDay: [Rules.NumericRule, Rules.RequiredRule, Rules.createRangeRule(1, 31)],
    dobMonth: [Rules.NumericRule, Rules.RequiredRule, Rules.NumericRule, Rules.createRangeRule(1, 12)],
    dobYear: [
      Rules.NumericRule,
      Rules.RequiredRule,
      Rules.createLengthRule(4, 4),
      Rules.NumericRule,
      Rules.createRangeRule(1905, new Date().getFullYear())
    ],

    surname: [
      Rules.RequiredRule,
      Rules.createLengthRule(2, 255),
      Rules.createRegexRule(
        /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/,
        'The {name} is not valid, e.g. Brown.'
      )
    ]
  };

  return modelValidator(model, { errors, warnings }, options);
}
