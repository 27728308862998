import { lodash } from '@avantia/lodash';
import queryString from 'query-string';
import * as parser from '../../parsers/index';
import { loginAmountPolicyPostcodeValidator } from '../../validators/loginAmountPolicyPostcodeValidator';
import { loginPolicyPostcodeValidator } from '../../validators/loginPolicyPostcodeValidator';
import { StandardDispatchFunction } from '../actionTypes';
import { fetchResolver } from '../fetchResolver';
export interface GetPaymentTokenActionParams {
  policyNumber: string;
  postcode: string;
  amount: Record<string, unknown> | number;
  paymentAmount: number;
  overdueAmount: number;
  validateAmount: number;
  feeAmount: number;
  totalAmountPayable: number;
  outstandingBalance: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getPaymentTokenAction({
  policyNumber,
  postcode,
  amount,
  paymentAmount,
  overdueAmount,
  validateAmount,
  feeAmount,
  totalAmountPayable,
  outstandingBalance
}: GetPaymentTokenActionParams) {
  const amountValue = lodash.isObject(amount) ? (amount as any).value : amount;
  return (dispatch: StandardDispatchFunction): Promise<void> => {
    const validator = amountValue === undefined ? loginPolicyPostcodeValidator : loginAmountPolicyPostcodeValidator;
    const errors = validator(
      { policyNumber, postcode, amount: amountValue },
      { overdueAmount, validateAmount, feeAmount, totalAmountPayable, outstandingBalance, totalPaid: 0 }
    );
    if (errors.count > 0) {
      /*return*/ dispatch({ type: 'PAYMENT_MODEL_VALIDATION_ERROR', payload: { errors } });
    } else {
      dispatch({ type: 'PAYMENT_MODEL_VALIDATION_ERROR', payload: { errors } }); // clear the errors
    }

    const isCustomAmount =
      parser.isCurrency(amountValue) &&
      parser.isCurrency(paymentAmount) &&
      parser.currency(amountValue) !== parser.currency(paymentAmount);
    const query: any = {};
    if (isCustomAmount) {
      query.customAmount = amountValue;
    }

    let queryText = queryString.stringify(query);
    queryText = queryText ? `?${queryText}` : '';
    const url = `/api/paymentIntent/${encodeURIComponent(policyNumber)}/${encodeURIComponent(postcode)}${queryText}`;
    return fetchResolver<any>({ url }, dispatch, (payload) => {
      dispatch({
        type: 'RETRIEVED_PAYMENT_TOKEN',
        payload: Object.assign(payload, { postcode })
      });
    });
  };
}
