import { ensureDefined } from '@avantia/client-and-server-utilities';
import jQuery from 'jquery';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as Actions from '../../actions/index';
import { logWarning } from '../../clientLogging';
import * as formatter from '../../formatters/index';
import * as parser from '../../parsers/index';
import { PageComponent } from '../common/PageComponent';
import { WelcomeMessage } from '../common/WelcomeMessage';
import { wrapInMain } from '../common/wrapInMain';
import { IconTypes } from '../iconTypes';
import { ConfirmedPayDetail } from './ConfirmedPayDetails';
import CustomerPayDetails from './CustomerPayDetails';
import { LoginDetails } from './LoginDetails';

interface WindowProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  setupStripe: Function;
}

class CustomerPaymentPage extends PageComponent<any, any> {
  constructor(props, context) {
    super(props, context);
    this.modifyFieldChanged = this.modifyFieldChanged.bind(this);
    this.getPaymentToken = this.getPaymentToken.bind(this);
    this.setupStripeWhenReady = this.setupStripeWhenReady.bind(this);
    this.retryWithDifferentCard = this.retryWithDifferentCard.bind(this);
    this.updateAmount = this.updateAmount.bind(this);
    this.stripeControlConfigured = this.stripeControlConfigured.bind(this);
    this.fieldFocussedOrBlurred = this.fieldFocussedOrBlurred.bind(this);
    this.paymentSentForProcessing = this.paymentSentForProcessing.bind(this);
    this.paymentIsDirty = this.paymentIsDirty.bind(this);
    this.onCardholderNameBlur = this.onCardholderNameBlur.bind(this);
  }

  modifyFieldChanged(event, validatorName) {
    event.preventDefault();
    const { overdueAmount, feeAmount, totalPaid, totalAmountPayable, outstandingBalance, userInterface } = this.props;
    const { name, value } = event.target;
    const { modelErrors } = userInterface;
    this.props.modifyField({
      fieldName: name,
      value,
      eventType: event.type,
      validatorName,
      overdueAmount,
      modelErrors,
      feeAmount,
      totalPaid,
      totalAmountPayable,
      outstandingBalance
    });
  }

  updateAmount(event) {
    event.preventDefault();
    if (this.paymentIsDirty()) {
      const {
        postcode,
        policyNumber,
        paymentAmount,
        overdueAmount,
        feeAmount,
        totalAmountPayable,
        outstandingBalance,
        paymentForm
      } = this.props;
      const amount = paymentForm.amount.value;
      this.props.getPaymentToken({
        postcode,
        policyNumber,
        amount,
        paymentAmount,
        overdueAmount,
        feeAmount,
        totalAmountPayable,
        outstandingBalance,
        validateAmount: true
      });
    } else {
      this.props.voidUpdateAmount();
    }
  }

  getPaymentToken(event) {
    event.preventDefault();
    const { postcode, policyNumber } = this.props.paymentForm;
    this.props.getPaymentToken({ postcode, policyNumber });
  }

  paymentSentForProcessing(event) {
    event.preventDefault();
    const { paymentId, postcode, policyNumber } = this.props;
    this.props.paymentSentForProcessing({ postcode, policyNumber, paymentId });
  }

  setupStripeWhenReady() {
    const { apiKey, paymentToken, amount, postcode, agreementReference, policyNumber, isStripeControlConfigured } =
      this.props;
    const extraData = { amount, postcode, agreementReference, policyNumber };
    const window = this.getWindow();
    if (!isStripeControlConfigured) {
      setTimeout(() => {
        if (document.getElementById('payment-form') !== null) {
          this.stripeControlConfigured();
          window.setupStripe(
            (response) =>
              this.props.paymentCompletion(
                Object.assign({ stripeResponse: response.paymentIntent, success: true }, extraData)
              ),
            (response) =>
              this.props.paymentCompletion(
                Object.assign({ stripeResponse: response.error, success: false }, extraData)
              ),
            apiKey,
            paymentToken
          );
        } else {
          this.props.fetchErrorOccurred({ message: 'Stripe is not ready.' });
        }
      }, 25);
    }
  }

  retryWithDifferentCard(event) {
    event.preventDefault();
    this.props.retryWithDifferentCard();
  }

  stripeControlConfigured() {
    this.props.stripeControlConfigured();
  }

  fieldFocussedOrBlurred(event) {
    event.preventDefault();
    const { name } = event.target;
    this.props.fieldFocussedOrBlurred({ fieldName: name, eventType: event.type });
  }

  paymentIsDirty() {
    const { paymentAmount, paymentForm } = this.props;
    return (
      parser.isCurrency(paymentForm.amount.value) &&
      parser.isCurrency(paymentAmount) &&
      parser.currency(paymentForm.amount.value) !== parser.currency(paymentAmount)
    );
  }

  getWindow() {
    const w = window as any;
    return w as WindowProps;
  }

  componentDidUpdate() {
    const { fieldWithFocus } = this.props.userInterface;
    const window = this.getWindow();
    if (fieldWithFocus) {
      setTimeout(() => {
        const $ = jQuery;
        $(`#${fieldWithFocus}`).focus();
        if (fieldWithFocus === 'cardholderName') {
          // if the pay button is not in focus, scroll down to make it visible.
          const payButton = $('#card-button');
          if (payButton.length > 0) {
            const botOfButton =
              (ensureDefined(payButton.offset(), 'offset').top as number) + (payButton.outerHeight() as number);
            const windowHeight = $(window).innerHeight() as number;
            const botOfScreen = ($(window).scrollTop() as number) + windowHeight;
            if (botOfScreen < botOfButton) {
              const scrollTop = Math.min(
                ensureDefined($('#payment-form').offset(), 'offset').top,
                botOfButton - windowHeight + 65
              );
              $([document.documentElement, document.body]).animate({ scrollTop }, 500);
            }
          } else {
            logWarning('No card-button is visible');
          }
        }
      }, 750);
    }
  }

  onCardholderNameBlur(e) {
    e.preventDefault();
    this.props.clearFieldFocus();
  }

  render() {
    const {
      paymentToken,
      address,
      paymentResultToken,
      paymentMessage,
      paymentSuccess,
      paymentAmount,
      outstandingBalance,
      totalAmountPayable,
      feeAmount,
      policyNumber,
      validationErrors,
      userInterface,
      paymentForm
    } = this.props;
    const { fetchError, processingRequest, modelErrors, fieldWithFocus, user } = userInterface;
    const paymentIsDirty = this.paymentIsDirty();
    const updateButtonEnabled = !processingRequest;
    const welcomeProps: { title: string; icon: IconTypes; iconWidth: number } = {
      title: 'Make a Payment',
      icon: 'card',
      iconWidth: 1.5
    };
    const welcomeMessages = {
      notLoggedIn:
        'Is your policy in arrears? Have we asked you to make a payment? If so, please log in to use our secure payment facility.',
      notYetPaid:
        'Your account is currently in arrears. Please pay the amount due to ensure continuous cover for your home.',
      paymentMade:
        'Thank you! We have collected payment and your account is now up to date. We have emailed you a receipt.'
    };
    return wrapInMain(
      <div className="col-lg-12">
        {!paymentToken && <WelcomeMessage subtitle={welcomeMessages.notLoggedIn} {...welcomeProps} />}

        {!paymentToken && (
          <LoginDetails
            {...{
              policyNumber: paymentForm.policyNumber,
              postcode: paymentForm.postcode,
              modifyFieldChanged: (e) => this.modifyFieldChanged(e, 'loginPolicyPostcodeValidator'),
              getPaymentToken: this.getPaymentToken,
              processingRequest,
              fetchError,
              validationErrors,
              modelErrors
            }}
          />
        )}

        {paymentToken && !paymentResultToken && (
          <WelcomeMessage subtitle={welcomeMessages.notYetPaid} {...welcomeProps} />
        )}

        {paymentToken && !paymentResultToken && (
          <CustomerPayDetails
            {...{
              amount: paymentForm.amount,
              amountIsEditable: user && user.name,
              paymentToken,
              paymentResultToken,
              feeAmount,
              policyNumber,
              outstandingBalance,
              totalAmountPayable,
              updateAmount: this.updateAmount,
              updateButtonEnabled,
              modifyFieldChanged: (e) => this.modifyFieldChanged(e, 'paymentDetailsValidator'),
              onCardholderNameBlur: this.onCardholderNameBlur,
              fieldBlurred: this.fieldFocussedOrBlurred,
              fieldFocussed: this.fieldFocussedOrBlurred,
              paymentSentForProcessing: this.paymentSentForProcessing,
              fieldWithFocus,
              cardholderName: paymentForm.cardholderName,
              address,
              paymentIsDirty,
              processingRequest,
              fetchError,
              validationErrors,
              modelErrors
            }}
          />
        )}

        {paymentToken && !paymentResultToken && this.setupStripeWhenReady()}

        {paymentResultToken && paymentSuccess && (
          <WelcomeMessage subtitle={welcomeMessages.paymentMade} {...welcomeProps} />
        )}

        {paymentResultToken && !paymentSuccess && (
          <WelcomeMessage subtitle={welcomeMessages.notYetPaid} {...welcomeProps} />
        )}

        {paymentResultToken && (
          <ConfirmedPayDetail
            {...{
              paymentSuccess,
              formattedAmount: formatter.currency(paymentAmount),
              outstandingBalance,
              paymentResultToken,
              paymentMessage,
              retryWithDifferentCard: this.retryWithDifferentCard,
              fetchError,
              validationErrors,
              modelErrors
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return Object.assign({}, state.payment, { paymentForm: state.paymentForm, userInterface: state.userInterface });
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchErrorOccurred: (err) => dispatch(Actions.fetchErrorOccurredAction(err)),
    modifyField: (req) => dispatch(Actions.modifyPaymentFieldAction(req)),
    getPaymentToken: (req) => dispatch(Actions.getPaymentTokenAction(req)),
    paymentCompletion: (req) => dispatch(Actions.paymentCompletionAction(req)),
    retryWithDifferentCard: (req) => dispatch(Actions.retryWithDifferentCardAction(req)),
    stripeControlConfigured: () => dispatch(Actions.stripeControlConfiguredAction()),
    fieldFocussedOrBlurred: (req) => dispatch(Actions.fieldFocussedOrBlurredAction(req)),
    paymentSentForProcessing: (req) => dispatch(Actions.paymentSentForProcessingAction(req)),
    voidUpdateAmount: () => dispatch(Actions.voidUpdateAmountAction()),
    clearFieldFocus: (req) => dispatch(Actions.clearFieldFocusAction(req))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerPaymentPage as any));
