import { hasOwnProperty } from '@avantia/logging-and-errors';
import queryString, { ParsedQuery } from 'query-string';
import { BaseSyntheticEvent, Component, CSSProperties } from 'react';
import { Button, Checkbox } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as Actions from '../../actions/index';
import { FontIcon } from '../common/fontIcon';
import { PanelCard } from '../common/PanelCard';
import { WelcomeMessage } from '../common/WelcomeMessage';
import { wrapInMain } from '../common/wrapInMain';
import TextFieldEditor from '../InlineEditor/TextFieldEditor';
import './style.css';

class ContactPreference extends Component<
  ContactPreferenceProps & ContactPreferenceDispatchProps,
  ContactPreferenceState
> {
  constructor(props: any) {
    super(props);
    this.state = getInitialState();
    this.onChangeFunc = this.onChangeFunc.bind(this);
    this.updateEmailAddress = this.updateEmailAddress.bind(this);
    this.clicksavePreference = this.clicksavePreference.bind(this);
  }

  componentDidMount() {
    const parsed = this.getQuery();
    let emailAddress = '';
    let callCentreUser = '';
    if (typeof parsed !== 'undefined') {
      for (const contactDetail in parsed) {
        if (hasOwnProperty(parsed, 'email') || hasOwnProperty(parsed, 'calluser')) {
          if (contactDetail === 'email') {
            emailAddress = parsed[contactDetail] as string;
          }

          if (contactDetail === 'calluser') {
            callCentreUser = parsed[contactDetail] as string;
          }
        }
      }
    }

    const state = Object.assign({}, getInitialState(), { emailAddress, callCentreUser });
    this.setState(state);
  }

  getQuery(): ParsedQuery<string> {
    let url = document.URL;
    const index = url.indexOf('?');
    url = index >= 0 ? url.substring(index + 1) : url;
    const query = queryString.parse(url);
    return query;
  }

  private clicksavePreference() {
    let params: ContactPreferenceState;
    if (this.state.emailAddress !== undefined && this.state.emailAddress !== '') {
      params = {
        ...this.state,
        updateData: true
      };
      this.setState(params);
      this.postContactPreference(params);
    } else {
      params = {
        ...this.state,
        emailerror: true
      };
      this.setState(params);
    }
  }

  updateEmailAddress({ emailAddress }) {
    this.setState({
      emailAddress: emailAddress,
      emailerror: false
    });
  }

  postContactPreference(params) {
    const { emailAddress, updateData } = params;
    let gdprMediaTypeId = 0;
    if (updateData === true) {
      for (const contactPreference in params['marketingOptions']) {
        if (contactPreference !== 'optout') {
          if (contactPreference === 'email') {
            gdprMediaTypeId = 1;
          }

          if (contactPreference === 'sms') {
            gdprMediaTypeId = 2;
          }

          if (contactPreference === 'post') {
            gdprMediaTypeId = 3;
          }

          if (contactPreference === 'telephone') {
            gdprMediaTypeId = 4;
          }

          const formData = {
            EmailAddress: emailAddress,
            GdprMediaTypeId: gdprMediaTypeId,
            OptInBool: params['marketingOptions'][contactPreference]
          };
          this.props.updateContactPreference(formData);
        }
      }
    }
  }

  onChangeFunc(componentName: string, e: BaseSyntheticEvent) {
    let params: ContactPreferenceState;
    if (componentName === 'optout' && e.target.checked) {
      params = {
        ...this.state,
        marketingOptions: {
          ...getInitialState().marketingOptions,
          optout: e.target.checked
        },
        optoutPrefix:
          componentName === 'optout' && e.target.checked
            ? 'imageBorderOutline selected-preference'
            : componentName !== 'optout'
            ? this.state.optoutPrefix
            : 'imageBorderOutline',
        emailPrefix: 'imageBorderOutline',
        smsPrefix: 'imageBorderOutline',
        postPrefix: 'imageBorderOutline',
        telephonePrefix: 'imageBorderOutline'
      };
    } else {
      params = {
        ...this.state,
        marketingOptions: {
          ...this.state.marketingOptions,
          [componentName]: e.target.checked,
          optout: false
        },
        emailPrefix:
          componentName === 'email' && e.target.checked
            ? 'imageBorderOutline selected-preference'
            : componentName !== 'email'
            ? this.state.emailPrefix
            : 'imageBorderOutline',
        smsPrefix:
          componentName === 'sms' && e.target.checked
            ? 'imageBorderOutline selected-preference'
            : componentName !== 'sms'
            ? this.state.smsPrefix
            : 'imageBorderOutline',
        postPrefix:
          componentName === 'post' && e.target.checked
            ? 'imageBorderOutline selected-preference'
            : componentName !== 'post'
            ? this.state.postPrefix
            : 'imageBorderOutline',
        telephonePrefix:
          componentName === 'telephone' && e.target.checked
            ? 'imageBorderOutline selected-preference'
            : componentName !== 'telephone'
            ? this.state.telephonePrefix
            : 'imageBorderOutline',
        optoutPrefix: 'imageBorderOutline'
      };
    }

    this.setState(params);
    this.postContactPreference(params);
  }

  render() {
    const {
      updateData,
      emailAddress,
      emailerror,
      emailPrefix,
      smsPrefix,
      postPrefix,
      telephonePrefix,
      optoutPrefix,
      marketingOptions
    } = this.state;

    const optionClasses = 'divcolbottomspace';
    const optionStyle: CSSProperties = { minWidth: '90px' };

    return wrapInMain(
      updateData ? (
        <div>{getCompleted()}</div>
      ) : (
        <div>
          {getIntro()}
          <PanelCard
            header="Step 1: Enter your email address"
            leftIcon="email"
            content={
              <div className="sessioncamhidetext sessioncamexclude">
                <TextFieldEditor
                  title="Enter a valid email address"
                  dataType="email"
                  value={emailAddress}
                  onSuccess={(v) => {
                    this.updateEmailAddress({
                      emailAddress: v
                    });
                  }}
                />
                {emailerror !== undefined && !emailerror ? (
                  ''
                ) : (
                  <p className="error-email-address">Please enter your email-address</p>
                )}
              </div>
            }></PanelCard>
          <PanelCard
            header="Step 2: Choose your preferences (all that apply)"
            data-locator="finalCheckCard"
            leftIcon="sign-dotted"
            content={
              <div>
                <div className="checkbox finalcheck ">
                  <p>
                    We’d like to send you quote reminders when your home insurance typically renews, promotions that
                    could save you money and occasional research surveys.
                  </p>
                  <div className="row divrowtopspace">
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                      <div className={optionClasses}>
                        <Checkbox
                          inline
                          id="canEmail"
                          checked={marketingOptions.email}
                          onChange={(e) => {
                            this.onChangeFunc('email', e);
                          }}>
                          <div style={optionStyle}>
                            <div className="div-text-align-center">
                              <div className={emailPrefix !== undefined ? emailPrefix : ' imageBorderOutline'}>
                                <span className="">
                                  <FontIcon faIcon="envelope" width={3} />
                                </span>
                              </div>
                              <div className="info-dvfont">
                                Email<sup>‡</sup>
                              </div>
                            </div>
                          </div>
                        </Checkbox>
                      </div>
                      <div className={optionClasses}>
                        <Checkbox
                          inline
                          id="canSMS"
                          checked={marketingOptions.sms}
                          value=""
                          onChange={(e) => {
                            this.onChangeFunc('sms', e);
                          }}>
                          <div style={optionStyle}>
                            <div className="div-text-align-center">
                              <div className={smsPrefix !== undefined ? smsPrefix : ' imageBorderOutline'}>
                                <span className="">
                                  <FontIcon icon="mobile" width={3} />
                                </span>
                              </div>
                              <div className="info-dvfont">SMS</div>
                            </div>
                          </div>
                        </Checkbox>
                      </div>
                      <div className={optionClasses}>
                        <Checkbox
                          inline
                          id="canPost"
                          checked={marketingOptions.post}
                          value=""
                          onChange={(e) => {
                            this.onChangeFunc('post', e);
                          }}>
                          <div style={optionStyle}>
                            <div className="div-text-align-center">
                              <div className={postPrefix !== undefined ? postPrefix : ' imageBorderOutline'}>
                                <span className="">
                                  <FontIcon faIcon="paper-plane" width={3} />
                                </span>
                              </div>
                              <div className="info-dvfont">Post</div>
                            </div>
                          </div>
                        </Checkbox>
                      </div>
                      <div className={optionClasses}>
                        <Checkbox
                          inline
                          id="canTelephone"
                          checked={marketingOptions.telephone}
                          value=""
                          onChange={(e) => {
                            this.onChangeFunc('telephone', e);
                          }}>
                          <div style={optionStyle}>
                            <div className="div-text-align-center">
                              <div className={telephonePrefix !== undefined ? telephonePrefix : ' imageBorderOutline'}>
                                <span className="">
                                  <FontIcon faIcon="phone" width={3} />
                                </span>
                              </div>
                              <div className="info-dvfont">Phone</div>
                            </div>
                          </div>
                        </Checkbox>
                      </div>
                      <div className={optionClasses}>
                        <Checkbox
                          inline
                          id="canOptout"
                          checked={marketingOptions.optout}
                          value=""
                          onChange={(e) => {
                            this.onChangeFunc('optout', e);
                          }}>
                          <div style={optionStyle}>
                            <div className="div-text-align-center">
                              <div className={optoutPrefix !== undefined ? optoutPrefix : ' imageBorderOutline'}>
                                <span className="">
                                  <FontIcon faIcon="ban" width={3} />
                                </span>
                              </div>
                              <div className="info-dvfont">None</div>
                            </div>
                          </div>
                        </Checkbox>
                      </div>
                    </div>
                  </div>
                  <div className="text-center supplement-dvfont">
                    <p>
                      <sup>‡</sup>Email also includes social, digital media and other electronic channels.
                    </p>
                  </div>
                </div>
              </div>
            }
          />
          <div style={{ marginBottom: '120px' }}>
            <div style={{ width: '75%', margin: '0 auto' }}>
              <Button
                bsSize="lg"
                style={{ height: '80px!important' }}
                onClick={this.clicksavePreference}
                bsStyle="primary"
                className="btn-block btnBigger hp-btn-continue">
                Save preferences
              </Button>
              {emailerror !== undefined && !emailerror ? (
                <></>
              ) : (
                <p className="error-email-address">Please enter your email-address</p>
              )}
            </div>
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  const { userInterface } = state;
  return { userInterface };
};

const mapDispatchToProps = (dispatch): ContactPreferenceDispatchProps => {
  return {
    updateContactPreference: (req) => dispatch(Actions.contactPreferenceAction(req))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactPreference);

function getInitialState(): ContactPreferenceState {
  return {
    emailAddress: '',
    callCentreUser: '',
    updateData: false,
    marketingOptions: {
      email: false,
      sms: false,
      telephone: false,
      post: false,
      optout: false
    },
    classPrefix: '',
    emailerror: false
  };
}

function getCompleted() {
  const completed = (
    <PanelCard
      header="You&rsquo;re all set!"
      leftIcon="correspondence"
      additionalHeaderClass="removeMargin"
      data-locator="completedCard"
      content={
        <div id="results" className="search-results">
          <p id="resultsThanks">Your marketing preferences have been updated. Thank you!</p>
          <p id="resultMarketingpreference">
            <a href="/">Log out</a>
          </p>
          <p id="resultHomeprotectHomepage">
            Take me to <a href="https://www.homeprotect.co.uk/">Homeprotect homepage</a>
          </p>
        </div>
      }
    />
  );
  return completed;
}

function getIntro() {
  const intro = (
    <WelcomeMessage
      title="Marketing preferences"
      icon="correspondence"
      iconWidth={1.5}
      subtitle={
        <span>
          Choose how you want to receive marketing communications from Homeprotect. Your information will be used in
          accordance with our{' '}
          <a href="https://www.homeprotect.co.uk/security-privacy" target="_New">
            privacy policy
          </a>
          . We never sell your details to third parties and you can unsubscribe at any time.
        </span>
      }
    />
  );
  return intro;
}

interface ContactPreferenceProps {
  emailAddress: string;
  callCentreUser: string;
  marketingOptions: MarketingOptions;
}

interface ContactPreferenceDispatchProps {
  updateContactPreference: (req: any) => any;
}

interface ContactPreferenceState {
  emailAddress: string;
  callCentreUser: string;
  updateData: boolean;
  marketingOptions: MarketingOptions;
  classPrefix: string;
  emailerror: boolean;
  emailPrefix?: string;
  postPrefix?: string;
  telephonePrefix?: string;
  smsPrefix?: string;
  optoutPrefix?: string;
}

interface MarketingOptions {
  email: boolean;
  sms: boolean;
  telephone: boolean;
  post: boolean;
  optout: boolean;
}
