import { DispatchFunction } from '../actionTypes';
import { FormTypePayload } from './FormTypePayload';

export interface ToggleClaimsPayload {
  policyNumber: string;
  hasIncidentOccurred: boolean;
}

export function toggleClaimsAction(payload: ToggleClaimsPayload & FormTypePayload) {
  return (dispatch: DispatchFunction<ToggleClaimsPayload>): Promise<void> => {
    const { formType, policyNumber, hasIncidentOccurred } = payload;
    return dispatch({ type: 'TOGGLE_CLAIMS', formType, payload: { policyNumber, hasIncidentOccurred } });
  };
}
