import { uniqueId } from 'lodash';
import { Component } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

class InlineEditor extends Component<InlineEditorProps, any> {
  public overlay: OverlayTrigger | null = null;

  render(): JSX.Element {
    const { value, children, title, onEntered } = this.props;
    const linkClass = value === '' ? 'editable-click editable-empty' : 'editable-click';
    const linkComponent = (
      <div className="editor-container">
        <input className={`editor ${linkClass}`} readOnly={true} value={value} style={{ width: '100%' }} />
      </div>
    );
    const popOverContent = (
      <Popover title={title} id={uniqueId()}>
        <div className="form-inline editableform">{children}</div>
      </Popover>
    );
    return (
      <OverlayTrigger
        ref={(ref) => {
          this.overlay = ref;
        }}
        onEntered={onEntered}
        trigger="click"
        placement="bottom"
        overlay={popOverContent}
        // rootClose - this attribute causes the PopOver to disappear as soon as it appears.
      >
        {linkComponent}
      </OverlayTrigger>
    );
  }
}

interface InlineEditorProps {
  children: JSX.Element | JSX.Element[];
  value: string;
  dataType: 'text' | 'email';
  title: string;
  onEntered: VoidFunction;
}

export default InlineEditor;
