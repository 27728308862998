import { detect } from 'detect-browser';
import { Component } from 'react';
import { ExternalLink } from './ExternalLink';
import { FontIcon } from './fontIcon';

class HeaderBrowser extends Component<any, any> {
  constructor(props: unknown) {
    super(props);
    this.state = { isBrowserAllowed: true };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount(): void {
    const browser = detect();
    if (browser) {
      if (browser.name === 'ie') {
        if (parseInt(browser.version) < 10) {
          this.setState({ isBrowserAllowed: false });
        }
      }
    }
  }

  handleClick(): void {
    this.setState({ isBrowserAllowed: false });
  }

  render(): JSX.Element | null {
    if (this.state.isBrowserAllowed) {
      return null;
    }

    return (
      <div id="browser-not-supported">
        <div className="notify-card">
          <article className="media">
            <div className="media-left">
              <FontIcon icon="triangle" />
            </div>
            <div className="media-body">
              <h3>Unsupported browser</h3>
              <div className="media-content">
                <p>
                  You&apos;re using a web browser that we don&apos;t support anymore. Please upgrade to a later version
                  (IE10 or higher) to improve your experience.
                  <ExternalLink
                    id="update-browswer-link"
                    href="https://browser-update.org/update-browser.html"
                    content="Why do I need an up-to-date browser?"
                  />
                  .
                </p>
              </div>
            </div>
            <div className="media-right">
              <button
                className="btn-x"
                onClick={this.handleClick}
                style={{ backgroundColor: '#FFBE0B', marginTop: '10px', marginRight: '10px' }}>
                <i style={{ color: '#337ab7' }}></i>
                <span></span>
              </button>
            </div>
          </article>
        </div>
      </div>
    );
  }
}

export default HeaderBrowser;
