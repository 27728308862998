/* eslint-disable @typescript-eslint/ban-types */
import * as formatter from '../../formatters/index';
import { FieldModel } from '../../models/FieldModel';
import { AddressModel } from '../../models/models';
import { FontIcon } from '../common/fontIcon';
import { ButtonControl } from '../controls/ButtonControl';
import { IconFieldRowControl } from '../controls/IconFieldRowControl';
import { ReadOnlyTextControl } from '../controls/ReadOnlyTextControl';
import { SpinnerControl } from '../controls/SpinnerControl';
import SystemErrorControl from '../controls/SystemErrorControl';
import { TextInputControl } from '../controls/TextInputControl';
import ValidationErrorControl from '../controls/ValidationErrorControl';
import './CustomerPayDetails.css';
import { StripeControl } from './StripeControl';

const CustomerPayDetails = ({
  amount,
  amountIsEditable,
  // paymentToken,
  policyNumber,
  outstandingBalance,
  totalAmountPayable,
  feeAmount,
  updateAmount,
  updateButtonEnabled,
  modifyFieldChanged,
  onCardholderNameBlur,
  paymentSentForProcessing,
  fieldFocussed,
  fieldBlurred,
  fieldWithFocus,
  cardholderName,
  address,
  paymentIsDirty,
  processingRequest,
  validationErrors,
  fetchError,
  modelErrors
}: CustomerPayDetailsProps): JSX.Element => {
  const pound = String.fromCharCode(163);
  const addressText = formatter.address(address);
  const amountName = 'amount';
  const amountField = amount;
  const amountLabel = 'Amount to pay';
  const amountError = modelErrors.getError(amountName, amountLabel);
  const amountWarning = modelErrors.getWarning(amountName, amountLabel);
  const loadingText = 'Updating amount';
  const sendingPaymentText = 'Please wait while we process your payment';
  const divAmount = (
    <div className="row">
      <div className="col-xs-6">
        <p>Missed payment fee</p>
      </div>
      <div className="col-xs-4">
        <p id="feeAmount">
          {pound}
          {feeAmount}
        </p>
      </div>
      <div className="col-xs-10" style={{ marginTop: '-1px' }}>
        <hr className="HRAmountVariables" />
      </div>
      <div className="col-xs-6">
        <p>Total payable</p>
      </div>
      <div className="col-xs-4">
        <p id="totalAmountPayable">
          {pound}
          {totalAmountPayable}
        </p>
      </div>
      <div className="col-xs-10" style={{ marginTop: '-1px' }}>
        <hr className="HRAmountVariables" />
      </div>
      <div className="col-xs-6">
        <p>
          <b>Amount outstanding</b>
        </p>
      </div>
      <div className="col-xs-4">
        <p>
          <b id="outstandingBalance">
            {pound}
            {outstandingBalance}
          </b>
        </p>
      </div>
    </div>
  );
  const amountControl = amountIsEditable ? (
    <div>
      <IconFieldRowControl
        icon="card"
        width={5}
        content={
          <TextInputControl
            className="currency-form-control"
            name={amountName}
            label={amountLabel}
            value={(fieldWithFocus === amountName ? amountField.rawValue : amountField.formattedValue) || ''}
            onChange={modifyFieldChanged}
            onFocus={fieldFocussed}
            onBlur={fieldBlurred}
            prefixLabel={pound}
            fieldWithFocus={fieldWithFocus}
            modelErrors={modelErrors}
          />
        }
      />
      <div className="row div-block" style={{ marginBottom: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
        <ButtonControl
          name="update-amount"
          label="Update amount to pay"
          onClick={updateAmount}
          disabled={!updateButtonEnabled || !!amountError}
        />
      </div>
      {!updateButtonEnabled && processingRequest && (
        <SpinnerControl
          visible={processingRequest}
          positionLeftInt={48}
          spinnerText={loadingText}
          typeOfSpinner="Ring"
        />
      )}
    </div>
  ) : (
    ''
  );

  return (
    <div className="panel panel-aj active-card">
      <div className="panel-heading" role="tab" id="Activeheading">
        <ReadOnlyTextControl id="policyNumber" label="Policy number" value={policyNumber} icon="service-docs" />
        <ReadOnlyTextControl
          id="addressText"
          label="Insured property"
          value={addressText}
          icon="home"
          isMaskingEnabled={true}
        />
        <ReadOnlyTextControl id="amountDue" label="Amount due" value={divAmount} icon="lamp" />
        {amountControl}
      </div>

      {(amountError || amountWarning || fetchError) && (
        <div className="row " style={{ textAlign: 'center', marginTop: '5px', marginLeft: '5px', marginRight: '5px' }}>
          {amountWarning && (
            <div className="alert alert-warning" style={{ textAlign: 'center' }}>
              <p>{amountWarning}</p>
            </div>
          )}
          {(amountError || fetchError) && (
            <div className="alert alert-danger" style={{ textAlign: 'center' }}>
              {amountError && <p>{amountError}</p>}
              {fetchError && <p>{fetchError.message || fetchError}</p>}
            </div>
          )}
        </div>
      )}
      <div className="row div-block" style={{ marginBottom: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
        <StripeControl
          cardholderName={cardholderName}
          onChange={modifyFieldChanged}
          onCardholderNameBlur={onCardholderNameBlur}
          isPaymentButtonDisabled={paymentIsDirty || processingRequest}
          paymentSentForProcessing={paymentSentForProcessing}
          errorMessage={fetchError ? fetchError.message : ''}
          modelErrors={modelErrors}
          fieldWithFocus={fieldWithFocus}
        />
        <SystemErrorControl fetchError={fetchError} />
        <ValidationErrorControl validationErrors={validationErrors} />
      </div>
      {processingRequest && !paymentIsDirty && !updateButtonEnabled && (
        <SpinnerControl
          visible={processingRequest}
          positionLeftInt={48}
          spinnerText={sendingPaymentText}
          typeOfSpinner="Ring"
        />
      )}
      <div className="row text-center div-block">
        <FontIcon icon="mastercard-logo" width={3} />
        <FontIcon icon="visa-logo" width={3} />
      </div>
    </div>
  );
};

interface CustomerPayDetailsProps {
  amount: FieldModel;
  amountIsEditable: boolean;
  paymentToken: string;
  policyNumber: string;
  outstandingBalance: string | number;
  totalAmountPayable: string | number;
  feeAmount: string | number;
  updateAmount: Function;
  updateButtonEnabled: boolean;
  modifyFieldChanged: Function;
  onCardholderNameBlur: Function;
  paymentSentForProcessing: Function;
  fieldFocussed: Function;
  fieldBlurred: Function;
  fieldWithFocus: string | undefined;
  cardholderName: string;
  address: AddressModel;
  paymentIsDirty: boolean;
  processingRequest: boolean;
  validationErrors: any;
  fetchError: any;
  modelErrors: any;
}

export default CustomerPayDetails;
