import { MicroTimer } from '@avantia/client-and-server-utilities';
import { createIsolatedPackageLogger } from '@avantia/logging-and-errors';

const log = createIsolatedPackageLogger();

const {
  debug: logDebug,
  info: logInfo,
  notice: logNotice,
  warn: logWarning,
  error: logError,
  critical: logCritical,
  setLogger: setLoggerForCustomerPortalWebsite,
  getLogger: getLoggerForCustomerPortalWebsite
} = log;

export {
  logDebug,
  logInfo,
  logNotice,
  logWarning,
  logError,
  logCritical,
  setLoggerForCustomerPortalWebsite,
  getLoggerForCustomerPortalWebsite
};

export function displayTimeTaken(description: string, timer: MicroTimer): void {
  logDebug(`${description}: took ${timer.elapsedTimeAsString()}`);
}

export function recordElapsedTime<ResultT>(description: string, action: () => ResultT): ResultT {
  const timer = new MicroTimer();
  const res = action();
  displayTimeTaken(description, timer);
  return res;
}
